import React, { useEffect, useState } from "react";
import "../SignUp/style.scss";
import { marked } from "marked";
import logo from "../../images/Headerlogo.png";

export default function privacypolicy() {
  const readmePath = require("../SignUp/PrivacyPolicy.md");
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(readmePath)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setMarkdown(marked.parse(text));
      });
  });
  return (
    <div class="hero-anime">
      {/* Header */}
      <div class="navigation-wrap bg-light start-header start-style">
        <div class="nav-container">
          <div class="row">
            <div class="col-12">
              <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand">
                  <img class="logo-img" src={logo} alt="Logo" />
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* md file loading */}
      <div class="privacypolicy">
        <article
          class="policy-article"
          dangerouslySetInnerHTML={{ __html: markdown }}
        ></article>
      </div>
      {/* Footer */}
      <div class="row footer">
        <div class="comapany-info col-12 col-sm-12 col-md-4">
          Cotrinity
          <br />
          Company number: 12909153
        </div>
        <div class="copyright col-12 col-sm-12 col-md-4">
          Copyright © Cotrinity 2021
          <br />
          Fifth Floor 11 Leadenhall Street, London EC3V 1LP.
        </div>
      </div>
    </div>
  );
}
