import React from "react";
import ViewContent from "./views";
import MapContent from "./mapExtension";
import MapBackground from "./geoThreeExt/mapContent";
import ClusterPanelContent from "./clusterExtension/index";
import FilterPanelContent from "./filterExtension/index";
import Template from "./customParameter/templates/Template";
import CustomProperties from "./customParameter/properties/CustomProperties";
import FloorPlan from "./floorPlanSplit/FloorPlan";
import { Provider } from "react-redux";
import { configureStore } from "../../store";
import { SnackbarProvider } from "notistack";
import { store } from "../..";
import Table from "./DashBoard/table";
//import GridView from "./FormView/gridView";

// Manage all redux store for viewer

const appWrapper = (props) => {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        {props.id === 1 && <ViewContent viewer={props.viewer} />}
        {props.id === 2 && <MapContent viewer={props.viewer} />}
        {props.id === 3 && <MapBackground viewer={props.viewer} />}
        {props.id === 4 && (
          <ClusterPanelContent viewer={props.viewer} panel={props.panel} />
        )}
        {props.id === 5 && (
          <FilterPanelContent viewer={props.viewer} panel={props.panel} />
        )}
        {props.id === 6 && (
          <Template viewer={props.viewer} panel={props.panel} />
        )}
        {props.id === 7 && (
          <CustomProperties viewer={props.viewer} panel={props.panel} />
        )}
        {props.id === 9 && (
          <FloorPlan
            viewer={props.viewer}
            listDisplayed={props.listDisplayed}
            levelButton={props.levelButton}
          />
        )}
        {props.id === 11 && <Table viewer={props.viewer} />}
      </SnackbarProvider>
    </Provider>
  );
};

export default appWrapper;
