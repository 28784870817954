import React from "react";
import { GithubPicker } from "react-color";
import reactCSS from "reactcss";
import NoColorImage from "../../../images/NoColorSmall.png";

class ColorPickerComponent extends React.Component {
  state = {
    index: 0,

    viewPicker: false,
    color: {
      rgb: {
        r: 0,
        b: 0,
        g: 0,
        a: 1,
      },
      hex: "#000000",
      hsl: {
        a: 1,
        h: 0,
        l: 0,
        s: 100,
      },
    },
  };

  componentDidMount() {
    if (
      this.props.selectedColor !== "" &&
      this.props.selectedColor &&
      this.props.selectedColor.rgb &&
      this.props.selectedColor.hex &&
      this.props.selectedColor.hsl
    ) {
      this.setState({
        color: {
          rgb: this.props.selectedColor.rgb,
          hex: this.props.selectedColor.hex,
          hsl: this.props.selectedColor.hsl,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedColor !== "" &&
      this.props.selectedColor &&
      this.props.selectedColor.rgb &&
      this.props.selectedColor.hex &&
      this.props.selectedColor.hsl &&
      (prevProps.selectedColor === undefined ||
        prevProps.selectedColor.hex === undefined ||
        this.props.selectedColor.hex !== prevProps.selectedColor.hex)
    ) {
      this.setState({
        color: {
          rgb: this.props.selectedColor.rgb,
          hex: this.props.selectedColor.hex,
          hsl: this.props.selectedColor.hsl,
        },
      });
    }
  }

  handleOnClick = () => {
    this.setState({
      viewPicker: !this.state.viewPicker,
    });
  };

  handleOnClose = () => {
    this.setState({
      viewPicker: false,
    });
  };

  handleOnChange = (color) => {
    this.setState({
      color: {
        rgb: color.rgb,
        hex: color.hex,
        hsl: color.hsl,
      },
    });
    // Passing the selected color to parent component
    setTimeout(() => {
      // SetTimeout added to update color correctly
      this.props.onColorSelect(this.state.color);
    });
    this.setState({
      viewPicker: false,
    });
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "25px",
          height: "16px",
          borderRadius: "200px",
          background: `rgba(${this.state.color.rgb.r}, ${this.state.color.rgb.g}, ${this.state.color.rgb.b}, ${this.state.color.rgb.a})`,
        },
        swatch: {
          padding: "1px",
          background: "transparent",
          width: "25px",
          borderRadius: "0px",
          // boxShadow: "0 0 0 1px rgba(0,0,0,.2)",
          cursor: "pointer",
          display: "inline-block",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          width: "1000px",
        },
        popover: {
          position: "absolute",
          zIndex: "1",
          right: "0px",
        },
      },
    });

    return (
      <div>
        {this.state.color.hex === "#000000" ? (
          <img
            class="nocolor-img"
            src={NoColorImage}
            alt="NoColor"
            onClick={this.handleOnClick}
          />
        ) : (
          <div style={styles.swatch} onClick={this.handleOnClick}>
            <div style={styles.color} />
          </div>
        )}

        {this.state.viewPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleOnClose} />
            <GithubPicker
              triangle="top-right"
              width="250px"
              colors={[
                "#0000FF",
                "#2F4F4F",
                "#708090",
                "#A9A9A9",
                "#C0C0C0",
                "#800000",
                "#A52A2A",
                "#A0522D",
                "#D2691E",
                "#F4A460",
                "#191970",
                "#0000CD",
                "#4169E1",
                "#7B68EE",
                "#00BFFF",
                "#5F9EA0",
                "#00CED1",
                "#7FFFD4",
                "#AFEEEE",
                "#E0FFFF",
                "#006400",
                "#008000",
                "#2E8B57",
                "#00FF7F",
                "#98FB98",
                "#663399",
                "#9370DB",
                "#BA55D3",
                "#FF00FF",
                "#DA70D6",
                "#FFD700",
                "#FFFF00",
                "#F0E68C",
                "#EEE8AA",
                "#FFFFE0",
                "#8B0000",
                "#FF0000",
                "#FF4500",
                "#FF8C00",
                "#FF7F50",
              ]}
              onChange={this.handleOnChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPickerComponent;
