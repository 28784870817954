import ExtensionStoreProvider from "../provider";
import "./panel.scss";
import ReactDOM from "react-dom";
import React from "react";
const Autodesk = window.Autodesk;

export default class VisualClusterPanel extends Autodesk.Viewing.UI
  .DockingPanel {
  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  constructor(viewer, options) {
    super(viewer.container, options.id, options.title, {
      addFooter: false,
      viewer,
    });

    this.container.classList.add("cluster-docking-panel");

    this.DOMContent = document.createElement("div");

    this.DOMContent.className = "content";

    this.container.appendChild(this.DOMContent);
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  initialize() {
    super.initialize();

    this.viewer = this.options.viewer;

    this.footer = this.createFooter();

    this.container.appendChild(this.footer);
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  setVisible(show) {
    super.setVisible(show);

    if (show) {
      this.reactNode = ReactDOM.render(
        <ExtensionStoreProvider id={4} viewer={this.viewer} panel={this} />,
        this.DOMContent
      );
    } else if (this.reactNode) {
      ReactDOM.unmountComponentAtNode(this.DOMContent);

      this.reactNode = null;
    }
  }
}
