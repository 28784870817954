import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/Headerlogo.png';
import { appConstants } from '../../constants/appConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  fullWidth: {
      width: '100%'
  },
  modalBody: {
      padding: '2.5rem 1rem',
      wordBreak: 'break-all',
      textAlign: 'center',
      backgroundColor: '#9DB4B4',
  } 
}));

const year = () => {
    const date = new Date();
    const thisYear = date.getFullYear();
    return thisYear
}

const aboutModal = (props) => {
    const classes = useStyles();
    return(
        <React.Fragment>
            <Modal
            {...props}
            size="md"
            aria-labelledby="about-modal"
            centered
            >
            <Modal.Body className={classes.modalBody}>
                <div className="about-img">
                    <img src={logo} alt="Logo"/>
                </div>
                <div className="about-version">
                    Version { appConstants.APP_VERSION }
                </div>
                <div className="about-copyright">
                    <p>Copyright &copy; {year()} Cotrinity</p>
                    <p>All Rights Reserved</p>
                </div>
            </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
  
export default aboutModal;