import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Link as Linked, useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { passwordRecovery, verificateNewPassword } from '../../actions/accountActions';
import { useSnackbar } from 'notistack';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{
        paddingBottom: "20px",
        fontFamily: "'DM Sans Medium', sans-serif"
    }}>
      {'Copyright © '}
      <Link color="inherit" href="https://cotrinity.com/" target="blank">
        Cotrinity
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    textAlign: "center",
    marginTop: ".5rem",
  },
}));

export default function Recovery() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [ changePasswordView, setChangePasswordView ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ verificationCode, setVerificationCode ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');

  const { sentEmail } = useSelector(state => state.account);
  const { changedPassword } = useSelector(state => state.account);
  const { validationsMessage } = useSelector(state => state.account);
  
  useEffect(() => {
    if (sentEmail) {
      enqueueSnackbar('Verification code sent to your email.', {
        variant: 'success'
      });
      setChangePasswordView(true);
    }
  }, [sentEmail]);

  useEffect(() => {
    if (changedPassword) {
      enqueueSnackbar('Password changed successfully.', {
        variant: 'success'
      });
      history.push('/login');
    }
  }, [changedPassword])

  const handleChangePassword = async (event) => {
    event.preventDefault();

    await dispatch(passwordRecovery(email));
  }

  const handleVerificatePassword = async (event) => {
    event.preventDefault();

    await dispatch(verificateNewPassword(email, verificationCode, password));
  }


  return (
    <div className="auth-only-wrapper">
    <Container component="main" maxWidth="xs" className="auth-container">
      <CssBaseline />
      {!changePasswordView && <div className={classes.paper}>
        <Avatar className={classes.avatar}
        style={{
          marginTop: "25px",
          backgroundColor: "#062D35"
      }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <span style={{ fontFamily: "'DM Sans Medium', sans-serif" }}>Recover your password</span>
        </Typography>
        <form className={classes.form} onSubmit={handleChangePassword}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          <Typography
            className={classes.errorMessage}
            variant="subtitle2"
            color="error"
          >
            {validationsMessage}
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{
              backgroundColor: "#062D35",
          }}
          >
            <span style={{ fontFamily: "'DM Sans Medium', sans-serif" , color: "#F19505"}}>Submit</span>
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={Linked} to="/" variant="body2" style={{ fontFamily: "'DM Sans Medium', sans-serif" }}>
                Register
              </Link>
            </Grid>
            <Grid item>
              <Link component={Linked} to="/login" variant="body2" style={{ fontFamily: "'DM Sans Medium', sans-serif" }}>
                {"Login"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>}
      {changePasswordView && <div className={classes.paper}>
        <Avatar className={classes.avatar}
        style={{
          marginTop: "25px"
      }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change your password
        </Typography>
        <form className={classes.form} onSubmit={handleVerificatePassword}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="verificationCode"
            label="Verification Code"
            name="verificationCode"
            autoComplete="verificationCode"
            autoFocus
            value={verificationCode}
            onChange={event => setVerificationCode(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            autoComplete="password"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            error={Boolean(password !== confirmPassword)}
            helperText={Boolean(password !== confirmPassword) ? `Password and Confirm Password don't match.` : null}
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            autoComplete="confirmPassword"
            value={confirmPassword}
            onChange={event => setConfirmPassword(event.target.value)}
          />
          <Typography
            className={classes.errorMessage}
            variant="subtitle2"
            color="error"
          >
            {validationsMessage}
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{
              backgroundColor: "#2b455f",
          }}
          >
            Submit
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={Linked} to="/" variant="body2">
                Register
              </Link>
            </Grid>
            <Grid item>
              <Link component={Linked} to="/login" variant="body2">
                {"Login"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </div>
  );
}