import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import viewerReducer from "./viewerReducer";
import modelsReducer from "./modelsReducer";
import savedViewerReducer from "./savedViewReducer";
import customPropertiesReducer from "./customPropertiesReducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import subscriptionReducer from "./subscriptionReducer";
import planReducer from "./planReducer";
import foldersReducer from "./foldersReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  viewer: viewerReducer,
  models: modelsReducer,
  toastr: toastrReducer,
  savedViews: savedViewerReducer,
  customProperties: customPropertiesReducer,
  subscription: subscriptionReducer,
  plan: planReducer,
  folders: foldersReducer,
});

export default (state, action) => {
  return rootReducer(
    action.type === "@account/sign-out" ? undefined : state,
    action
  );
};
