import React, { Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";
import * as clipboard from "clipboard-polyfill/text";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
  modalBody: {
    padding: "2.5rem 1rem",
    wordBreak: "break-all",
    textAlign: "center",
  },
}));

const shareModal = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { model } = props;

  const clear = () => {
    props.onHide();
  };

  const handleSharedLinkCopy = () => {
    const text = `${window.location.origin}/shared_viewer/${model.tempId}`;
    clipboard.writeText(text).then(
      function () {
        enqueueSnackbar("Shared link copied to your clipboard", {
          variant: "success",
        });
      },
      function (err) {}
    );
  };

  return (
    <Fragment>
      <Modal {...props} size="md" aria-labelledby="share-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title id="share-modal">Share Model</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={`${classes.modalBody} ${
            model.tempId ? "" : "text-center"
          }`}
        >
          {model.tempId && props.tempIdsFetched ? (
            `${window.location.origin}/shared_viewer/${model.tempId}`
          ) : (
            <Fragment>
              <CircularProgress color="secondary" />
              <p>Generating shared link...</p>
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color={"primary"}
            type="submit"
            variant="contained"
            autoFocus
            onClick={() => handleSharedLinkCopy()}
            disabled={
              model && (!model.modelURN || !model.tempId) ? true : false
            }
          >
            Copy Link
          </Button>
          <Button type="button" onClick={() => clear()} color="secondary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default shareModal;
