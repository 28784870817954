import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { entitiesPropertiesList } from "../Viewer-helpers";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
} from "@material-ui/core";

const FloorPlanSettings = ({
  open,
  setOpen,
  updateFilterProperty,
  filterProperty,
  ...props
}) => {
  const [propertiesList, setPropertiesList] = useState([]);
  const [updateValue, setUpdateValue] = useState([]);
  const [updateIndex, setUpdateIndex] = useState([]);
  useEffect(() => {
    let temp = [];
    props.selectedModel.forEach((model) => {
      let propertyNamesList = [];
      if (entitiesPropertiesList[model.modelURN]) {
        Object.keys(entitiesPropertiesList[model.modelURN]).forEach(
          (entity) => {
            Object.keys(entitiesPropertiesList[model.modelURN][entity]).forEach(
              (propertyName) => {
                if (!propertyNamesList.includes(propertyName))
                  propertyNamesList.push(propertyName);
              }
            );
          }
        );
      }
      propertyNamesList.sort();
      temp.push(propertyNamesList);
    });
    setPropertiesList(temp);
    setUpdateValue(filterProperty);
  }, []);

  const updateDropdown = (name, index) => {
    let tempVal = [...updateValue];
    tempVal[index] = name;
    setUpdateValue(tempVal);

    tempVal = [...updateIndex];
    tempVal.push(index);
    setUpdateIndex(tempVal);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        style={{ maxHeight: "500px", overflowY: "scroll" }}
      >
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          {props.selectedModel.map((model, index) => {
            return (
              <div style={{ marginBottom: "10%" }}>
                <Typography variant="overline">{model.displayName}</Typography>{" "}
                <br />
                <Select
                  label="Selected property"
                  value={updateValue[index]}
                  onChange={(event) =>
                    updateDropdown(event.target.value, index)
                  }
                  style={{ width: "70%" }}
                >
                  {propertiesList[index] &&
                    propertiesList[index].map((name) => {
                      return <MenuItem value={name}>{name}</MenuItem>;
                    })}
                </Select>
                <FormHelperText>Select model property</FormHelperText>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false);
              updateFilterProperty(updateValue, updateIndex);
              setUpdateIndex([]);
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { modelList, selectedModel, currentModels } = state.models;
  return { modelList, selectedModel, currentModels };
};

export default connect(mapStateToProps, {})(FloorPlanSettings);
