/* eslint-disable no-loop-func */
import React, { Fragment, useEffect, useState } from "react";
import { modelHierarchy, entitiesPropertiesList } from "../Viewer-helpers";
import { Grid, Typography, Switch } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { getModelList } from "../../../actions/modelsActions";
import {
  getCustomProperties,
  getTemplate,
} from "../../../actions/customParameterActions";
import { setCurrentModels } from "../../../actions/modelsActions";
import { getFolders, setCurrentFolder } from "../../../actions/folderActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import ColorPickerComponent from "../colourExtension/ColorPickerComponent";
import { splitURN } from "../../../utils/api";

const FilterPanelContent = (props) => {
  const dispatch = useDispatch();
  const [addedCategories, setAddedCategories] = useState([""]);
  const [addedProperties, setAddedProperties] = useState([
    { propertyName: "", propertyValue: "" },
  ]);
  const [addedCustomProperties, setAddedCustomProperties] = useState([
    { propertyName: "", propertyValue: "" },
  ]);
  const [filterType, setFilterType] = useState("Isolate");
  const [epl, setEpl] = useState({});
  const [noOfFilteredEntities, setNoOfFilteredEntities] = useState("");
  const [combineFilters, setCombineFilters] = useState(false);
  const [sharedView, setSharedView] = useState(false);
  const [modelSelectExpanded, setModelSelectExpanded] = useState(false);
  const [filterModels, setFilterModels] = useState([]);
  const [selectTemplateName, setSelectTemplateName] = useState(false);
  const [entityColor, setEntityColor] = useState([]);
  const [propertyToggle, setPropertyToggle] = useState([]);
  const [selectedDropdownProperty, setSelectedDropdownProperty] = useState([]);
  const [customValuesSuggestion, setCustomValuesSuggestion] = useState([]);

  //useEffect for component unmount
  useEffect(() => {
    if (props.selectedModel) {
      let tempEpl = {};
      let tempModels = [];
      for (let m in entitiesPropertiesList) {
        tempModels.push(m);
        tempEpl[m] = new Map();
        for (let dbIndex in entitiesPropertiesList[m]) {
          let temp = new Map();
          for (let pIndex in entitiesPropertiesList[m][dbIndex]) {
            temp.set(
              String(pIndex).toLowerCase().replace(/ /g, ""),
              String(entitiesPropertiesList[m][dbIndex][pIndex])
                .toLowerCase()
                .replace(/ /g, "")
            );
          }
          tempEpl[m].set(parseInt(dbIndex, 10), temp);
        }
      }
      setFilterModels(tempModels);
      setEpl(tempEpl);
    }
    if (!props.modelList) {
      if (window.location.href.includes("shared_viewer")) {
        setSharedView(true);
      } else {
        props.getModelList(localStorage.getItem("subId"));
      }
    }
    if (!props.folderId.length) {
      dispatch(getFolders(localStorage.getItem("subId")));
    }
    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);

  useEffect(() => {
    if (!sharedView) {
      const url = window.location.pathname;
      let urlParams = splitURN(url);
      let urlIds = urlParams.urlIds;

      urlIds.forEach((urn, index) => {
        let model = findModel(urn);
        if (model) {
          if (
            props.templateProperties.length === 0 &&
            props.propertyValuesFetched < 0
          ) {
            dispatch(getTemplate(model.uniqueId));
            dispatch(getCustomProperties(model.uniqueId));
          }
        }
      });
      if (props.fetchFoldersStatus) {
        let model = findModel(urlIds[0]);
        if (model) {
          dispatch(setCurrentFolder(findFolder(model)));
        }
        let selectFolder = parseInt(props.selectedFolder);
        if (selectFolder > -1) {
          dispatch(getTemplate(props.folderId[selectFolder], true));
        }
      }
    } else {
      let viewModelId = localStorage.getItem("viewModelId");
      if (
        props.templateProperties.length === 0 &&
        props.propertyValuesFetched < 0
      ) {
        dispatch(getTemplate(viewModelId));
        dispatch(getCustomProperties(viewModelId));
      }
    }
  }, [
    props.modelList,
    sharedView,
    props.fetchFoldersStatus,
    props.selectedFolder,
  ]);

  useEffect(() => {
    var hexColor = [...entityColor];
    for (let toggleIndex in propertyToggle) {
      if (propertyToggle[parseInt(toggleIndex)]) {
        //Multimodel Checking
        if (Object.keys(props.propertiesValuesList).length > 0) {
          // props.multipleTemplateId.map((template, tindex) => {
          let tempIndex = props.multipleTemplateName.findIndex((temp) =>
            temp.includes(selectedDropdownProperty[parseInt(toggleIndex)])
          );
          if (tempIndex > -1) {
            if (
              props.propertyValuesFetched &&
              props.propertiesValuesList[props.multipleTemplateId[tempIndex]]
            ) {
              props.propertiesValuesList[
                props.multipleTemplateId[tempIndex]
              ].map((element) => {
                let modelUrn = checkModel(element.modelId);
                let toggeledModel = null;
                if (modelHierarchy[modelUrn])
                  toggeledModel = props.viewer.impl
                    .modelQueue()
                    .findModel(modelHierarchy[modelUrn].modelId);
                if (toggeledModel) {
                  toggeledModel.getObjectTree(function (objectTree) {
                    if (
                      objectTree.getChildCount(parseInt(element.id)) === 0 &&
                      props.multipleTemplateProperties[tempIndex]
                    ) {
                      element.values.map((value, pindex) => {
                        if (
                          props.multipleTemplateProperties[tempIndex][pindex] &&
                          String(
                            props.multipleTemplateProperties[tempIndex][pindex]
                              .propertyName
                          ) ===
                            String(
                              addedCustomProperties[parseInt(toggleIndex)]
                                .propertyName
                            ) &&
                          String(value.value) ===
                            String(
                              addedCustomProperties[parseInt(toggleIndex)]
                                .propertyValue
                            )
                        ) {
                          if (value.color) {
                            var tempHexColor = value.color.hex;
                            hexColor[parseInt(toggleIndex)] = tempHexColor;

                            var themeColor = new Window.THREE72.Vector4(
                              value.color.rgb.r / 255,
                              value.color.rgb.g / 255,
                              value.color.rgb.b / 255,
                              1
                            );
                            if (toggeledModel)
                              toggeledModel.setThemingColor(
                                parseInt(element.id),
                                themeColor,
                                toggeledModel
                              );
                          }
                        }
                      });
                      props.viewer.impl.invalidate(true);
                    }
                  });
                }
              });
              // });
            }
          } else {
            tempIndex = props.templateName.findIndex((temp) =>
              temp.includes(selectedDropdownProperty[parseInt(toggleIndex)])
            );
            if (tempIndex > -1 && props.tempModelId[tempIndex]) {
              let curModelId = props.tempModelId[tempIndex];
              let modelUrn = checkModel(curModelId);
              let toggeledModel = null;
              if (modelHierarchy[modelUrn])
                toggeledModel = props.viewer.impl
                  .modelQueue()
                  .findModel(modelHierarchy[modelUrn].modelId);
              if (
                toggeledModel &&
                props.propertyValuesFetched &&
                props.propertiesValuesList[props.templateId[tempIndex]]
              ) {
                toggeledModel.getObjectTree(function (objectTree) {
                  props.propertiesValuesList[props.templateId[tempIndex]].map(
                    (element) => {
                      if (
                        props.templateProperties[tempIndex] &&
                        objectTree.getChildCount(parseInt(element.id)) === 0
                      ) {
                        element.values.map((value, pindex) => {
                          if (
                            props.templateProperties[tempIndex][pindex] &&
                            String(
                              props.templateProperties[tempIndex][pindex]
                                .propertyName
                            ) ===
                              String(
                                addedCustomProperties[parseInt(toggleIndex)]
                                  .propertyName
                              ) &&
                            String(value.value) ===
                              String(
                                addedCustomProperties[parseInt(toggleIndex)]
                                  .propertyValue
                              )
                          ) {
                            if (value.color) {
                              var tempHexColor = value.color.hex;
                              hexColor[parseInt(toggleIndex)] = tempHexColor;

                              var themeColor = new Window.THREE72.Vector4(
                                value.color.rgb.r / 255,
                                value.color.rgb.g / 255,
                                value.color.rgb.b / 255,
                                1
                              );
                              if (toggeledModel)
                                toggeledModel.setThemingColor(
                                  parseInt(element.id),
                                  themeColor,
                                  toggeledModel
                                );
                            }
                          }
                        });
                      }
                    }
                  );
                });
                props.viewer.impl.invalidate(true);
              }
            }
          }
        }
      } else {
        hexColor[parseInt(toggleIndex)] = "#00000000";
      }
    }
    setEntityColor(hexColor);
  }, [propertyToggle, selectedDropdownProperty]);

  const checkModel = (id) => {
    let model = null;
    if (props.selectedModel) {
      model = props.selectedModel.find((item) => item.uniqueId === id);
    }
    if (model) return model.modelURN;
    else return model;
  };

  const handleToggleSwitch = (index, checked) => {
    const url = window.location.pathname;
    let urlParams = splitURN(url);
    let urlIds = urlParams.urlIds;

    let hexColor = [...entityColor];
    urlIds.forEach((urn) => {
      hexColor[index] = "#00000000";
      if (modelHierarchy[urn]) {
        let toggeledModel = props.viewer.impl
          .modelQueue()
          .findModel(modelHierarchy[urn].modelId);
        if (toggeledModel) {
          props.viewer.clearThemingColors(toggeledModel);
        }
      }
    });
    setEntityColor(hexColor);
    let tempToggle = [...propertyToggle];
    tempToggle[index] = !tempToggle[index];
    setPropertyToggle(tempToggle);
  };

  const findModel = (urn) => {
    let model = null;
    if (props.modelList) {
      model = props.modelList.find((item) => item.modelURN === urn);
    }
    return model;
  };

  const findFolder = (model) => {
    let index = -1;
    if (model) {
      if (props.folderModelList) {
        index = props.folderModelList.findIndex((item) =>
          item.includes(model.uniqueId)
        );
      }
    }
    return index;
  };

  const resetFilters = () => {
    setAddedCategories([""]);
    setAddedProperties([{ propertyName: "", propertyValue: "" }]);
    setAddedCustomProperties([{ propertyName: "", propertyValue: "" }]);
    setNoOfFilteredEntities("");
    setFilterType("Isolate");
    setCombineFilters(false);
    setEntityColor([]);
    setPropertyToggle([]);
    setSelectedDropdownProperty(["Select Template"]);
  };

  const getFilteredDbIds = (ob) => {
    let recursiveChildren = (ob) => {
      for (let i in ob) {
        if (!dbIdsList.includes(ob[i].dbId)) dbIdsList.push(ob[i].dbId);
        if (ob[i].children) {
          recursiveChildren(ob[i].children);
        }
      }
    };
    let dbIdsList = [];
    recursiveChildren(ob);

    return dbIdsList;
  };

  const checkChildren = (ob, catName) => {
    let recursiveChildren = (ob) => {
      for (let i in ob) {
        if (
          String(ob[i].name).toLowerCase().replace(/ /g, "").includes(catName)
        ) {
          foundCategory.push(ob[i]);
        }
        if (ob[i].children) {
          recursiveChildren(ob[i].children);
        }
      }
    };
    let foundCategory = [];
    if (ob.children) recursiveChildren(ob.children);
    else if (Array.isArray(ob)) {
      for (let j in ob) {
        recursiveChildren(ob[j].children);
      }
    }
    return foundCategory;
  };

  const filterByCategory = (urn) => {
    let filteredByCategory = [];
    if (addedCategories.length) {
      for (let i = 0; i < addedCategories.length; ++i) {
        if (addedCategories[i] !== "") {
          if (!combineFilters) {
            if (i === 0) filteredByCategory = modelHierarchy[urn];
            filteredByCategory = checkChildren(
              filteredByCategory,
              String(addedCategories[i]).toLowerCase().replace(/ /g, "")
            );
          } else {
            filteredByCategory = filteredByCategory.concat(
              checkChildren(
                modelHierarchy[urn],
                String(addedCategories[i]).toLowerCase().replace(/ /g, "")
              )
            );
          }
        }
      }
    }
    return filteredByCategory;
  };

  const filterByProperty = (dbIds, urn) => {
    let filteredByProperties = dbIds;
    let fp = [];
    if (addedProperties.length) {
      for (let i in addedProperties) {
        for (let dbId in filteredByProperties) {
          if (
            epl[urn].has(filteredByProperties[dbId]) &&
            epl[urn]
              .get(filteredByProperties[dbId])
              .has(
                String(addedProperties[i].propertyName)
                  .toLowerCase()
                  .replace(/ /g, "")
              ) &&
            String(
              epl[urn]
                .get(filteredByProperties[dbId])
                .get(
                  String(addedProperties[i].propertyName)
                    .toLowerCase()
                    .replace(/ /g, "")
                )
            ).includes(
              String(addedProperties[i].propertyValue)
                .toLowerCase()
                .replace(/ /g, "")
            )
          ) {
            fp.push(filteredByProperties[dbId]);
          }
        }
        if (!combineFilters) {
          filteredByProperties = fp;
          fp = [];
        }
      }
    }
    if (combineFilters) {
      filteredByProperties = fp;
    }
    return filteredByProperties;
  };

  const filterByCustomProperties = (dbIds, modelId) => {
    let filteredEntities = dbIds;
    let fp = [];
    if (Object.keys(props.propertiesValuesList).length > 0) {
      for (let p in addedCustomProperties) {
        props.templateId.map((template, tindex) => {
          if (props.propertiesValuesList[template]) {
            props.propertiesValuesList[template].map((element) => {
              if (
                filteredEntities.includes(parseInt(element.id)) &&
                modelId &&
                element.modelId &&
                modelId === element.modelId
              ) {
                element.values.map((value, pindex) => {
                  if (
                    props.templateProperties[tindex][pindex] &&
                    String(
                      props.templateProperties[tindex][pindex].propertyName
                    ) === String(addedCustomProperties[p].propertyName) &&
                    String(value.value) ===
                      String(addedCustomProperties[p].propertyValue)
                  ) {
                    fp.push(parseInt(element.id));
                  }
                });
              }
            });
          }
        });
        props.multipleTemplateId.map((template, tindex) => {
          if (props.propertiesValuesList[template]) {
            props.propertiesValuesList[template].map((element) => {
              if (
                filteredEntities.includes(parseInt(element.id)) &&
                modelId &&
                element.modelId &&
                modelId === element.modelId
              ) {
                element.values.map((value, pindex) => {
                  if (
                    props.multipleTemplateProperties[tindex][pindex] &&
                    String(
                      props.multipleTemplateProperties[tindex][pindex]
                        .propertyName
                    ) === String(addedCustomProperties[p].propertyName) &&
                    String(value.value) ===
                      String(addedCustomProperties[p].propertyValue)
                  ) {
                    fp.push(parseInt(element.id));
                  }
                });
              }
            });
          }
        });
        if (!combineFilters) {
          filteredEntities = fp;
          fp = [];
        }
      }
    } else {
      //No custom properties present for model
      return [];
    }
    if (combineFilters) {
      return fp;
    }
    return filteredEntities;
  };

  const applyFiltersWithoutCombine = (urn) => {
    let filteredByCategory = [];
    let dbIdsFilteredOnCategory = [];
    let modelId = null;
    let model = findModel(urn);
    if (model) {
      modelId = model.uniqueId;
    }
    if (addedCategories[0] !== "") {
      filteredByCategory = filterByCategory(urn);
      dbIdsFilteredOnCategory = getFilteredDbIds(filteredByCategory);
    }

    if (dbIdsFilteredOnCategory.length === 0 && addedCategories[0] === "") {
      if (entitiesPropertiesList[urn]) {
        dbIdsFilteredOnCategory = Object.keys(entitiesPropertiesList[urn]);
        if (dbIdsFilteredOnCategory)
          dbIdsFilteredOnCategory = dbIdsFilteredOnCategory.map((num) => {
            return parseInt(num, 10);
          });
      }
    }

    let filteredByProperties = dbIdsFilteredOnCategory;
    if (
      addedProperties[0].propertyName !== "" &&
      addedProperties[0].propertyValue !== ""
    ) {
      filteredByProperties = filterByProperty(dbIdsFilteredOnCategory, urn);
    }

    if (
      addedCustomProperties[0].propertyName !== "" &&
      addedCustomProperties[0].propertyValue !== ""
    ) {
      filteredByProperties = filterByCustomProperties(
        filteredByProperties,
        modelId
      );
    }
    return filteredByProperties;
  };

  const applyFiltersWithCombine = (urn) => {
    let filteredByProperties = [];
    let modelId = null;
    let model = findModel(urn);
    if (model) {
      modelId = model.uniqueId;
    }
    if (entitiesPropertiesList[urn]) {
      let filteredByCategory = [];
      let dbIdsFilteredOnCategory = [];
      let allDbIds = Object.keys(entitiesPropertiesList[urn]);
      allDbIds = allDbIds.map((num) => {
        return parseInt(num, 10);
      });
      if (addedCategories[0] !== "") {
        filteredByCategory = filterByCategory(urn);
        dbIdsFilteredOnCategory = dbIdsFilteredOnCategory.concat(
          getFilteredDbIds(filteredByCategory)
        );
      }
      if (dbIdsFilteredOnCategory.length === 0 && addedCategories[0] === "") {
        dbIdsFilteredOnCategory = allDbIds;
      }
      filteredByProperties = dbIdsFilteredOnCategory;
      if (
        addedProperties[0].propertyName !== "" &&
        addedProperties[0].propertyValue !== ""
      ) {
        if (addedCategories[0] !== "")
          filteredByProperties = dbIdsFilteredOnCategory.concat(
            filterByProperty(allDbIds, urn)
          );
        else filteredByProperties = filterByProperty(allDbIds, urn);
      }
      if (
        addedCustomProperties[0].propertyName !== "" &&
        addedCustomProperties[0].propertyValue !== ""
      ) {
        if (
          addedCategories[0] !== "" ||
          (addedProperties[0].propertyName !== "" &&
            addedProperties[0].propertyValue !== "")
        )
          filteredByProperties = filteredByProperties.concat(
            filterByCustomProperties(allDbIds, modelId)
          );
        else filteredByProperties = filterByCustomProperties(allDbIds, modelId);
      }
    }
    return filteredByProperties;
  };

  const applyFilters = () => {
    let allModelFilteredEntities = 0;
    let urnList = Object.keys(entitiesPropertiesList);
    for (let urn in urnList) {
      let toggeledModel = props.viewer.impl
        .modelQueue()
        .findModel(modelHierarchy[urnList[urn]].modelId);

      if (filterModels.includes(urnList[urn])) {
        let filteredEntities;
        if (combineFilters) {
          filteredEntities = applyFiltersWithCombine(urnList[urn]);
        } else {
          filteredEntities = applyFiltersWithoutCombine(urnList[urn]);
        }
        filteredEntities = getUniqueDbIds(filteredEntities, toggeledModel);
        allModelFilteredEntities += filteredEntities.length;
        if (filteredEntities.length > 0) {
          if (filterType === "Isolate") {
            if (modelHierarchy[urnList[urn]]) {
              props.viewer.clearSelection();
              if (toggeledModel)
                toggeledModel.visibilityManager.isolate(
                  filteredEntities,
                  toggeledModel
                );
            }
          } else {
            if (modelHierarchy[urnList[urn]]) {
              let selections = [
                {
                  model: toggeledModel,
                  ids: filteredEntities,
                },
              ];
              props.viewer.impl.selector.setAggregateSelection(selections);
            }
          }
        } else {
          if (modelHierarchy[urnList[urn]]) {
            if (entitiesPropertiesList[urnList[urn]]) {
              Object.keys(entitiesPropertiesList[urnList[urn]]).forEach(
                (id) => {
                  props.viewer.hide(parseInt(id), toggeledModel);
                }
              );
            }
          }
        }
      } else {
        if (modelHierarchy[urnList[urn]]) {
          if (toggeledModel)
            toggeledModel.visibilityManager.hide(
              modelHierarchy[urnList[urn]].dbId,
              toggeledModel
            );
        }
      }
    }
    setNoOfFilteredEntities(String(allModelFilteredEntities));
  };

  const getUniqueDbIds = (ids, toggeledModel) => {
    let uniqueIds = [];
    if (toggeledModel) {
      toggeledModel.getObjectTree(function (objectTree) {
        if (ids && ids.length > 0) {
          for (let i in ids) {
            if (
              !uniqueIds.includes(ids[i]) &&
              objectTree.getChildCount(parseInt(ids[i])) === 0
            ) {
              uniqueIds.push(ids[i]);
            }
          }
        }
      });
    }
    return uniqueIds;
  };

  const handleModelChange = (urn, checked) => {
    if (!checked) {
      if (filterModels.includes(urn)) {
        let temp = [...filterModels];
        temp.splice(filterModels.indexOf(urn), 1);
        setFilterModels(temp);
        if (modelHierarchy[urn]) {
          let toggeledModel = props.viewer.impl
            .modelQueue()
            .findModel(modelHierarchy[urn].modelId);
          if (toggeledModel)
            toggeledModel.visibilityManager.hide(
              modelHierarchy[urn].dbId,
              toggeledModel
            );
        }
      }
    } else {
      let temp = [...filterModels];
      temp.push(urn);
      setFilterModels(temp);
      if (modelHierarchy[urn]) {
        let toggeledModel = props.viewer.impl
          .modelQueue()
          .findModel(modelHierarchy[urn].modelId);
        if (toggeledModel)
          toggeledModel.visibilityManager.show(
            modelHierarchy[urn].dbId,
            toggeledModel
          );
      }
    }
  };

  const handleCategoryChange = (value, index) => {
    let newCategories = [...addedCategories];
    newCategories[index] = value;
    setAddedCategories(newCategories);
  };

  const addSubCategory = () => {
    let newCategories = [...addedCategories];
    newCategories.push("");
    setAddedCategories(newCategories);
  };

  const removeSubCategory = (index) => {
    let newCategories = [...addedCategories];
    newCategories.splice(index, 1);
    setAddedCategories(newCategories);
  };

  const createSubCategoriesUI = () => {
    if (addedCategories.length) {
      return addedCategories.map((category, index) => {
        return (
          <React.Fragment key={index}>
            <Grid
              container
              spacing={3}
              id="categoryGridContainer"
              style={{ marginTop: "1px" }}
            >
              {index === 0 ? (
                <Grid item xs={5}>
                  <label htmlFor="subCategory">Categories:</label>
                </Grid>
              ) : (
                <Grid item xs={5}></Grid>
              )}
              <Grid item xs={5}>
                <input
                  type="text"
                  id="subCategory"
                  name="subCategory"
                  value={category}
                  onChange={(event) => {
                    handleCategoryChange(event.target.value, index);
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                {index === addedCategories.length - 1 && (
                  <button
                    className="button"
                    style={{ backgroundColor: "#ffffff" }}
                    onClick={addSubCategory}
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    {"  "}
                  </button>
                )}
              </Grid>
              <Grid item xs={1}>
                {(addedCategories.length > 1 || index !== 0) && (
                  <button
                    className="button"
                    style={{ backgroundColor: "#ffffff" }}
                    onClick={() => removeSubCategory(index)}
                  >
                    <i class="fa fa-minus" aria-hidden="true"></i>
                    {"  "}
                  </button>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        );
      });
    }
  };

  const handlePropertyChange = (name, value, index) => {
    let newProperty = [...addedProperties];
    newProperty[index] = {
      ...newProperty[index],
      [name]: value,
    };
    setAddedProperties(newProperty);
  };

  const onAddProperty = () => {
    let newProperty = [...addedProperties];
    newProperty.push({ propertyName: "", propertyValue: "" });
    setAddedProperties(newProperty);
  };

  const onRemoveProperty = (index) => {
    let newProperty = [...addedProperties];
    newProperty.splice(index, 1);
    setAddedProperties(newProperty);
  };

  const createExistingPropertiesUI = () => {
    if (addedProperties.length) {
      return addedProperties.map((property, index) => {
        return (
          <React.Fragment key={index}>
            <Grid container spacing={3} id="propertyInputContainer">
              <Grid item xs={5}>
                <input
                  type="text"
                  id="propertyName"
                  name="propertyName"
                  value={property.propertyName}
                  onChange={(event) =>
                    handlePropertyChange(
                      event.target.name,
                      event.target.value,
                      index
                    )
                  }
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <input
                  type="text"
                  id="propertyValue"
                  name="propertyValue"
                  value={property.propertyValue}
                  onChange={(event) =>
                    handlePropertyChange(
                      event.target.name,
                      event.target.value,
                      index
                    )
                  }
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                {index === addedProperties.length - 1 && (
                  <button
                    className="button"
                    style={{ backgroundColor: "#ffffff" }}
                    onClick={onAddProperty}
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    {"  "}
                  </button>
                )}
              </Grid>
              <Grid item xs={1}>
                {(addedProperties.length > 1 || index !== 0) && (
                  <button
                    className="button"
                    style={{ backgroundColor: "#ffffff" }}
                    onClick={() => onRemoveProperty(index)}
                  >
                    <i class="fa fa-minus" aria-hidden="true"></i>
                    {"  "}
                  </button>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        );
      });
    }
  };

  const handleCustomPropertyChange = (name, value, index) => {
    let newProperty = [...addedCustomProperties];
    newProperty[index] = { ...newProperty[index], [name]: value };
    setAddedCustomProperties(newProperty);

    let tempSuggestion = [...customValuesSuggestion];
    tempSuggestion[index] = [];
    props.templateProperties.forEach((temp, tindex) => {
      temp.forEach((item, pindex) => {
        if (
          item.propertyName.includes(newProperty[index].propertyName) &&
          props.propertiesValuesList[props.templateId[tindex]]
        ) {
          props.propertiesValuesList[props.templateId[tindex]].forEach(
            (element) => {
              if (
                element.values[pindex] &&
                element.values[pindex].value &&
                !tempSuggestion[index].includes(element.values[pindex].value)
              ) {
                tempSuggestion[index].push(element.values[pindex].value);
              }
            }
          );
        }
      });
    });
    props.multipleTemplateProperties.forEach((temp, tindex) => {
      temp.forEach((item, pindex) => {
        if (
          item.propertyName.includes(newProperty[index].propertyName) &&
          props.propertiesValuesList[props.multipleTemplateId[tindex]]
        ) {
          props.propertiesValuesList[props.multipleTemplateId[tindex]].forEach(
            (element) => {
              if (
                element.values[pindex] &&
                element.values[pindex].value &&
                !tempSuggestion[index].includes(element.values[pindex].value)
              ) {
                tempSuggestion[index].push(element.values[pindex].value);
              }
            }
          );
        }
      });
    });
    setCustomValuesSuggestion(tempSuggestion);
  };

  const addCustomProperty = () => {
    let newProperty = [...addedCustomProperties];
    newProperty.push({ propertyName: "", propertyValue: "" });
    setAddedCustomProperties(newProperty);
  };

  const removeCustomProperty = (index) => {
    let newProperty = [...addedCustomProperties];
    newProperty.splice(index, 1);
    setAddedCustomProperties(newProperty);
    let tempEntityColours = [...entityColor];
    tempEntityColours.splice(index, 1);
    setEntityColor(tempEntityColours);
    let tempPropertyToggle = [...propertyToggle];
    tempPropertyToggle.splice(index, 1);
    setPropertyToggle(tempPropertyToggle);
    let tempSelectedDropdownProperty = [...selectedDropdownProperty];
    tempSelectedDropdownProperty.splice(index, 1);
    setSelectedDropdownProperty(tempSelectedDropdownProperty);
  };

  const createCustomPropertiesUI = () => {
    if (addedCustomProperties.length) {
      return addedCustomProperties.map((property, index) => {
        return (
          <Grid container spacing={2} id="propertyInputContainer">
            <Grid item xs={3}>
              <form autoComplete="off">
                <input
                  type="text"
                  id="propertyName"
                  name="propertyName"
                  list="propertyNameList"
                  value={property.propertyName}
                  onChange={(event) =>
                    handleCustomPropertyChange(
                      event.target.name,
                      event.target.value,
                      index
                    )
                  }
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                  }}
                />
                <datalist id="propertyNameList">
                  {props.templateProperties.map((item, tindex) => {
                    return item.map((element) => {
                      return <option value={element.propertyName} />;
                    });
                  })}
                  {props.multipleTemplateProperties.map((item, tindex) => {
                    return item.map((element) => {
                      return <option value={element.propertyName} />;
                    });
                  })}
                </datalist>
              </form>
            </Grid>
            <Grid item xs={3}>
              <form autocomplete="off">
                <input
                  type="text"
                  id="propertyValue"
                  name="propertyValue"
                  list={"propertyValueList" + index}
                  value={property.propertyValue}
                  onChange={(event) =>
                    handleCustomPropertyChange(
                      event.target.name,
                      event.target.value,
                      index
                    )
                  }
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                  }}
                />
                <datalist id={"propertyValueList" + index}>
                  {customValuesSuggestion[index] &&
                    customValuesSuggestion[index].map((val) => {
                      return <option value={val} />;
                    })}
                </datalist>
              </form>
            </Grid>

            <Grid item xs={1}>
              {index === addedCustomProperties.length - 1 && (
                <button
                  className="button"
                  style={{ backgroundColor: "#ffffff" }}
                  onClick={addCustomProperty}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  {"  "}
                </button>
              )}
            </Grid>
            <Grid item xs={1}>
              {(addedCustomProperties.length > 1 || index !== 0) && (
                <button
                  className="button"
                  style={{ backgroundColor: "#ffffff" }}
                  onClick={() => removeCustomProperty(index)}
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                  {"  "}
                </button>
              )}
            </Grid>
            <Grid item xs={2}>
              <div id="multiselect">
                <div
                  className="selectBox"
                  onClick={() => setSelectTemplateName(!selectTemplateName)}
                >
                  <select
                    value={selectedDropdownProperty[index]}
                    onChange={(event) =>
                      handleDropdownChange(event.target.value, index)
                    }
                  >
                    <option key="unselected">Select Template</option>
                    {props.templateProperties &&
                      props.templateName.map((template, index) => (
                        <option key={index}>{template}</option>
                      ))}
                    {props.multipleTemplateProperties &&
                      props.multipleTemplateName.map((template, index) => (
                        <option key={index}>{template}</option>
                      ))}
                  </select>
                </div>
              </div>
            </Grid>
            <Grid item xs={1}>
              <div
                id="colorBox"
                style={{
                  border: "thin solid white",
                  borderWidth: "2px",
                  borderRadius: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: entityColor[index],
                }}
              ></div>
            </Grid>
            <Grid item xs={1} className="toggleAlignment">
              <Switch
                checked={propertyToggle[index] ? true : false}
                color="primary"
                onChange={(event) =>
                  handleToggleSwitch(index, event.target.checked)
                }
                style={{ position: "absolute" }}
              />
            </Grid>
          </Grid>
        );
      });
    }
  };

  const handleDropdownChange = (value, index) => {
    let newProperty = [...selectedDropdownProperty];
    newProperty[index] = value;
    setSelectedDropdownProperty(newProperty);
  };

  const createExistingPropertyLabels = () => {
    return (
      <Grid
        container
        spacing={2}
        id="propertyGridContainer"
        style={{ marginTop: "1px" }}
      >
        <Grid item xs={5}>
          <label>Properties Name</label>
        </Grid>
        <Grid item xs={5}>
          <label>Properties Value</label>
        </Grid>
      </Grid>
    );
  };

  const createCustomPropertyLabels = () => {
    return (
      <Grid
        container
        spacing={2}
        id="propertyGridContainer"
        style={{ marginTop: "1px" }}
      >
        <Grid item xs={3}>
          <label>Properties Name</label>
        </Grid>
        <Grid item xs={3}>
          <label>Properties Value</label>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="react-content">
      <div id="multiselect">
        <div
          className="selectBox"
          onClick={() => setModelSelectExpanded(!modelSelectExpanded)}
        >
          <div className="overSelect"></div>
          <select>
            <option value="">Select Models for filter</option>
          </select>
        </div>
        {modelSelectExpanded &&
        props.selectedModel &&
        props.selectedModel.length ? (
          <div id="modelCheckboxes">
            <ul>
              {props.selectedModel.map((model) => {
                return (
                  <li>
                    <label htmlFor={model.displayName}>
                      <input
                        type="checkbox"
                        id={model.modelURN}
                        checked={filterModels.includes(model.modelURN)}
                        onChange={(event) =>
                          handleModelChange(
                            event.target.id,
                            event.target.checked
                          )
                        }
                      />
                      {model.displayName}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Typography>Existing Properties: </Typography>
      {createSubCategoriesUI()}
      {createExistingPropertyLabels()}
      {createExistingPropertiesUI()}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <hr style={{ border: "1px solid white", margin: "15px 0px" }} />
        </Grid>
      </Grid>
      <Typography>Custom Properties: </Typography>
      {props.propertyValuesFetched && props.templatesFetched ? (
        <div>
          {createCustomPropertyLabels()}
          {createCustomPropertiesUI()}
        </div>
      ) : (
        <div>
          <CircularProgress color="secondary" className="spinner" />{" "}
        </div>
      )}

      <div
        style={{
          display: "flex",
          marginTop: "10px",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <input
            type="radio"
            name="filterType"
            id="isolate"
            value="Isolate"
            checked={filterType === "Isolate"}
            onChange={() => {
              setFilterType("Isolate");
            }}
          />
          <label htmlFor="isolate" style={{ marginLeft: "5px" }}>
            Isolate
          </label>
          <input
            type="radio"
            name="filterType"
            id="highlight"
            value="Highlight"
            checked={filterType === "Highlight"}
            onChange={() => {
              setFilterType("Highlight");
            }}
            style={{ marginLeft: "5px" }}
          />
          <label htmlFor="highlight" style={{ marginLeft: "5px" }}>
            Highlight
          </label>
        </div>
        <div style={{ justifyContent: "flex-end" }}>
          <input
            type="checkbox"
            id="combine"
            name="combine"
            value="Combine"
            checked={combineFilters}
            onChange={(event) =>
              event.target.checked
                ? setCombineFilters(true)
                : setCombineFilters(false)
            }
          />
          <label htmlFor="combine" style={{ marginLeft: "5px" }}>
            Combine Filters
          </label>
        </div>
      </div>
      <div style={{ float: "right" }}>
        <button
          type="submit"
          className="button applyButtonWrapper"
          style={{ backgroundColor: "#ffffff", marginRight: "3px" }}
          onClick={applyFilters}
        >
          Apply
        </button>
        <button
          type="button"
          className="button resetButton"
          style={{ backgroundColor: "#ffffff" }}
          onClick={resetFilters}
        >
          Reset
        </button>
      </div>
      <div
        id="numberofEntities"
        style={{ color: "white", paddingBottom: "50px" }}
      >
        {" "}
        Number of filtered entities : {noOfFilteredEntities}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { modelList, currentModels, selectedModel } = state.models;
  const {
    templateId,
    tempModelId,
    templateProperties,
    multipleTemplateId,
    multipleTemplateName,
    multipleTemplateProperties,
    propertiesValuesList,
    propertyValuesFetched,
    templateName,
    templatesFetched,
  } = state.customProperties;
  const { folderId, selectedFolder, fetchFoldersStatus, folderModelList } =
    state.folders;
  return {
    modelList,
    templateId,
    tempModelId,
    templateName,
    templateProperties,
    multipleTemplateId,
    multipleTemplateName,
    multipleTemplateProperties,
    propertiesValuesList,
    propertyValuesFetched,
    templatesFetched,
    currentModels,
    selectedModel,
    folderId,
    selectedFolder,
    fetchFoldersStatus,
    folderModelList,
  };
};

export default connect(mapStateToProps, {
  getModelList,
  getCustomProperties,
  setCurrentModels,
  getFolders,
  setCurrentFolder,
})(FilterPanelContent);
