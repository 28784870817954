import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Box, Grid } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  getFolders,
  addFolder,
  setCurrentFolder,
  updateFolder,
  deleteFolder,
} from "../../actions/folderActions";
import { getModelList, deleteModel } from "../../actions/modelsActions";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import "./style.scss";
import { width } from "@mui/system";
const useStyles = makeStyles((theme) => ({
  dashboardContainer: {
    marginLeft: "5rem",
    marginRight: "5rem",
    ["@media (max-width:600px)"]: {
      marginRight: "1rem",
      marginLeft: "1rem",
    },
    ["@media (max-width:960px)"]: {
      marginRight: "3rem",
      marginLeft: "3rem",
    },
  },
  dotsContainer: {
    color: theme.palette.dots.main,
    height: "2rem",
  },
  delThreeDots: {
    cursor: "pointer",
  },
  modelContainer: {
    background: theme.palette.common.white,
    width: "100%",
    height: "10rem",
    alignItems: "center",
    borderRadius: "1rem",
    boxShadow: "10px 10px 20px #dbdbdb, -10px -10px 20px #ffffff",
    "&:hover": {
      background: "linear-gradient(145deg, #e6e6e6, #ffffff) ",
      boxShadow: "10px 10px 20px #dbdbdb, -10px -10px 20px #ffffff",
    },
  },
  nameContainer: {
    height: `calc(100% - 4rem)`,
    cursor: "pointer",
  },
  nameText: {
    fontWeight: "bold",
    fontSize: "1.3rem",
  },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
  noContentText: {
    color: theme.palette.text.disabled,
    fontWeight: "bold",
    height: "20rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.1rem",
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#2b455f",
  },
  tooltip: {
    backgroundColor: "#2b455f",
  },
}));
const i = 0;

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const Folders = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const anchorRef = React.useRef([]);
  const [popperOpen, setPopperOpen] = useState([]);
  const [renameFolderOpen, setRenameFolderOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [renameFolderIndex, setRenameFolderIndex] = useState(-1);
  const [subscriptionAddedSnack, setSubscriptionAddedSnack] = useState(false);
  const [deleteFolderOpen, setDeleteFolderOpen] = useState(false);
  const [nameExist, setNameExistOpen] = useState(false);
  const [selectedDeleteOption, setSelectedDeleteOption] = useState(0);
  const [deleteFolderIndex, setDeleteFolderIndex] = useState(-1);

  useEffect(() => {
    dispatch(getFolders(localStorage.getItem("subId")));
    if (!props.modelList) {
      props.getModelList(localStorage.getItem("subId"));
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     props.modelList &&
  //     props.modelList.length > 0 &&
  //     props.fetchFoldersStatus === 1 &&
  //     props.folderName.length === 0
  //   ) {
  //     let modelIds = props.modelList.map((model) => {
  //       return model.uniqueId;
  //     });
  //     dispatch(
  //       addFolder(
  //         uuidv4(),
  //         localStorage.getItem("subId"),
  //         "Default Folder",
  //         modelIds
  //       )
  //     );
  //   }
  // }, [props.fetchFoldersStatus, props.modelList]);

  useEffect(() => {
    if (props.UserAddedSubscription === 1) {
      setSubscriptionAddedSnack(true);
    }
  }, [props.UserAddedSubscription]);

  useEffect(() => {
    if (
      deleteFolderIndex > -1 &&
      selectedDeleteOption > 0 &&
      props.folderModelList[deleteFolderIndex] &&
      props.folderId[deleteFolderIndex]
    ) {
      dispatch(
        deleteFolder(
          props.folderId[deleteFolderIndex],
          localStorage.getItem("subId"),
          deleteFolderIndex
        )
      );
      let mList = isJson(props.folderModelList[deleteFolderIndex])
        ? JSON.parse(props.folderModelList[deleteFolderIndex])
        : [];
      for (let i in mList) {
        const [model, mIndex] = findModel(mList[i]);
        if (model) {
          dispatch(deleteModel(model.uniqueId, model.deleteId, mIndex));
        }
      }
      setDeleteFolderIndex(-1);
      setSelectedDeleteOption(0);
    }
  }, [selectedDeleteOption, deleteFolderIndex]);

  const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleToggle = (index) => {
    let temp = [...popperOpen];
    temp[index] = !temp[index];
    setPopperOpen(temp);
  };

  const handleClose = (event, index) => {
    if (
      anchorRef.current[index] &&
      anchorRef.current[index].contains(event.target)
    ) {
      return;
    }

    let temp = [...popperOpen];
    temp[index] = false;
    setPopperOpen(temp);
  };

  const redirectToDashboard = (index) => {
    dispatch(setCurrentFolder(index));
    history.push("/dashboard/" + props.folderName[index]);
  };

  const findModel = (id) => {
    let model = null;
    let mIndex = -1;
    if (props.modelList) {
      model = props.modelList.find((item) => item.uniqueId === id);
      mIndex = props.modelList.findIndex((item) => item.uniqueId === id);
    }
    return [model, mIndex];
  };

  const renameFolder = () => {
    setRenameFolderOpen(false);
    if (
      props.folderName.find((element) => {
        return element === newFolderName;
      })
    ) {
      setNameExistOpen(true);
    } else {
      dispatch(
        updateFolder(
          props.folderId[renameFolderIndex],
          localStorage.getItem("subId"),
          newFolderName,
          props.folderModelList[renameFolderIndex]
        )
      );
    }
  };

  const deleteSelectedFolder = (index) => {
    setDeleteFolderIndex(index);
    setDeleteFolderOpen(true);

    handleToggle(index);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSubscriptionAddedSnack(false);
  };

  return (
    <div>
      {props.folderName && props.folderName.length > 0 ? (
        <Box mt={5} className={classes.dashboardContainer}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            className="folderWrapper"
          >
            {props.folderName.map((folderName, index) => (
              <Grid item xs={12} sm={6} md={2} key={index}>
                <Card
                  style={{
                    borderRadius: "10%",
                    backgroundColor: "#062D35",
                  }}
                  className="folderCardWrapper"
                >
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      maxHeight: "10px",
                      alignItems: "center",
                      backgroundColor: "#062D35",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mr={3}
                      pt={2}
                      className={classes.dotsContainer}
                    >
                      <MoreHorizIcon
                        className={classes.delThreeDots}
                        onClick={() => handleToggle(index)}
                        ref={(element) => (anchorRef.current[index] = element)}
                      />
                      <Popper
                        className="card-shadow"
                        open={popperOpen[index] ? true : false}
                        anchorEl={anchorRef.current[index]}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener
                                onClickAway={(event) =>
                                  handleClose(event, index)
                                }
                              >
                                <MenuList
                                  autoFocusItem={
                                    popperOpen[index] ? true : false
                                  }
                                  id="menu-list-grow"
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setRenameFolderIndex(index);
                                      setRenameFolderOpen(true);
                                    }}
                                  >
                                    <span style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}>Rename</span>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => deleteSelectedFolder(index)}
                                  >
                                    <span style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}>Delete</span>
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Box>
                  </CardContent>
                  <CardContent onClick={() => redirectToDashboard(index)}>
                    <BootstrapTooltip
                      title={folderName}
                      enterDelay={1000}
                      leaveDelay={300}
                      placement="top"
                      arrow
                    >
                      <Typography style={{ fontFamily: "'DM Sans Medium' , sans-serif"}} className="folderLableWrapper">
                        <FolderIcon style={{ color: "#F19505" }} />{" "}
                        {folderName.length > 20
                          ? folderName.substr(0, 8) +
                            "..." +
                            folderName.substr(
                              folderName.length - 7,
                              folderName.length
                            )
                          : folderName}
                      </Typography>
                    </BootstrapTooltip>
                    {props.folderModelList &&
                    isJson(props.folderModelList[index]) ? (
                      <div
                        style={{
                          paddingTop: "5px",
                          fontSize: "small",
                          marginTop: "15%",
                          color: "#ffffff",
                        }}
                      >
                        Model Count :{" "}
                        {JSON.parse(props.folderModelList[index]).length}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Grid container spacing={3} className={classes.gridViewerContainer}>
          {props.folderName && props.folderName.length < 1 && (
            <Grid item xs={12}>
              <div className={classes.noContentText}>No folders created</div>
            </Grid>
          )}
        </Grid>
      )}
      <Dialog
        open={renameFolderOpen}
        onClose={() => setRenameFolderOpen(false)}
      >
        <DialogTitle>Rename Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Folder Name"
            type="email"
            fullWidth
            variant="standard"
            onChange={(event) => setNewFolderName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameFolderOpen(false)}>Cancel</Button>
          <Button onClick={() => renameFolder()}>Rename</Button>
        </DialogActions>
      </Dialog>
      {nameExist ? (
        <Dialog
          open={nameExist}
          onClose={() => {
            setNameExistOpen(false);
          }}
        >
          <DialogTitle>Folder is already exist</DialogTitle>
          <DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setNameExistOpen(false);
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : null}
      <Dialog
        open={deleteFolderOpen}
        onClose={() => {
          setDeleteFolderOpen(false);
          setSelectedDeleteOption(0);
        }}
      >
        <DialogTitle>Delete Folder?</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSelectedDeleteOption(1);
                setDeleteFolderOpen(false);
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setSelectedDeleteOption(0);
                setDeleteFolderOpen(false);
              }}
            >
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {props.UserAddedSubscription === 1 && (
        <Snackbar
          open={subscriptionAddedSnack}
          autoHideDuration={3000}
          onClose={handleSnackBarClose}
        >
          <Alert variant="filled" severity="success">
            Subscribed to Plan successfully!
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { folderId, folderName, folderModelList, fetchFoldersStatus } =
    state.folders;
  const { modelList } = state.models;
  const { UserAddedSubscription, SubscribedPlanId, SubscriptionEndDate } =
    state.subscription;
  return {
    folderId,
    folderName,
    folderModelList,
    modelList,
    fetchFoldersStatus,
    UserAddedSubscription,
    SubscriptionEndDate,
    SubscribedPlanId,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getFolders,
    addFolder,
    getModelList,
    deleteModel,
    setCurrentFolder,
    updateFolder,
    deleteFolder,
  })(Folders)
);
