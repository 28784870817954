import React, { Fragment, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import RepeatIcon from "@material-ui/icons/Repeat";
import EditIcon from "@material-ui/icons/Edit";
import InsertLink from "@material-ui/icons/InsertLink";
import { connect, useDispatch, useSelector } from "react-redux";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import {
  getSavedViewList,
  saveView,
  updateView,
  deleteView,
  startUpdating,
  finishUpdating,
  exportToOBJ,
} from "../../../actions/savedViewAction";
import {
  getTempId,
  reduceUserExportCount,
  getCurrentSubScriptionDetails,
} from "../../../services/apiHelper";
import { getModelList, setCurrentModels } from "../../../actions/modelsActions";
import { toastr } from "react-redux-toastr";
import ViewLilnkShare from "../../Modal/viewLinkShare";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import expObjImage from "../../../images/obj_white.png";
import { entitiesPropertiesList, modelHierarchy } from "../Viewer-helpers";
import { splitURN } from "../../../utils/api";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const panelContent = (props) => {
  const [selectedModel, setSelectedModel] = useState(undefined);
  const [showNaming, setShowNaming] = useState(false);
  const [orginName, setOriginName] = useState("");
  // For saved view's name updating
  const [selectedView, setSelectedView] = useState(null);
  const [selectedViewName, setSelectedViewName] = useState("");
  // To show sharing process
  const [shareIndex, setShareIndex] = useState(-1);

  const [shareModalShow, setShareModalShow] = useState(false);
  const [tempID, setTempID] = useState(null);
  const [multiModelLoaded, setMultiModelLoaded] = useState(false);
  const [exportObjCountInvalid, setexportObjCountInvalid] = useState(false);

  useEffect(() => {
    if (!props.modelList) {
      props.getModelList(localStorage.getItem("subId"));
    }
  }, []);

  useEffect(() => {
    const url = window.location.pathname;
    let viewerModels = [];
    let urlParams = splitURN(url);
    let urlIds = urlParams.urlIds;

    if (urlIds && urlIds.length > 1) {
      setMultiModelLoaded(true);
    }
    urlIds.forEach((urn, index) => {
      let model = findModel(urn);
      if (model && !props.selectedModel) {
        viewerModels.push(model);
        props.getSavedViewList(model.uniqueId);
      }
    });
    if (viewerModels.length) setSelectedModel(viewerModels);
  }, [props.modelList]);

  useEffect(() => {
    if (props.saveApi === 1) {
      setShowNaming(false);
      clearName();
    }
    if (props.renamingStatus) {
      setSelectedView(null);
    }
    if (props.viewsList) {
      setOriginName(`View ${props.viewsList.length + 1}`);
    } else {
      setOriginName("View 1");
    }
  }, [props.saveApi, props.renamingStatus, props.viewsList]);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setexportObjCountInvalid(false);
  };

  const findModel = (urn) => {
    let model = null;
    if (props.modelList) {
      model = props.modelList.find((item) => item.modelURN === urn);
    }
    return model;
  };

  const handleShowSaveView = () => {
    if (showNaming) {
      clearName();
    }
    setShowNaming(!showNaming);
  };

  const clearName = () => {
    props.viewsList
      ? setOriginName(`View ${props.viewsList.length + 1}`)
      : setOriginName("");
  };

  const handleSaveView = () => {
    if (!props.viewsList || props.viewsList.length < 5) {
      const position = props.viewer.getState();
      let uniqueIds = [];
      if (selectedModel) {
        selectedModel.forEach((model) => {
          uniqueIds.push(model.uniqueId);
        });
      }
      if (position && uniqueIds.length) {
        if (!isNameExist(orginName)) {
          props.saveView(JSON.stringify(position), uniqueIds, orginName);
        }
      }
    } else {
      const msg =
        "You reached the max amount of views, please remove one to proceed";
      errorNotification(msg);
    }
  };

  const exportViewToObj = async (index, view) => {
    const modelUrn = selectedModel[0].modelURN;
    let toggeledModel = null;
    let objIds = [];
    let userId = localStorage.getItem("subId");
    let subscriptionDetails = await getCurrentSubScriptionDetails(userId);
    if (
      subscriptionDetails.length > 0 &&
      subscriptionDetails[0].objExportCount !== 0
    ) {
      if (modelHierarchy[modelUrn]) {
        toggeledModel = props.viewer.impl
          .modelQueue()
          .findModel(modelHierarchy[modelUrn].modelId);
      }
      if (toggeledModel) {
        props.viewer.getObjectTree(function (objectTree) {
          objIds = Object.keys(entitiesPropertiesList[modelUrn])
            .filter((entity) => {
              if (objectTree.getChildCount(entity) === 0) return entity;
            })
            .map((entity) => {
              return parseInt(entity);
            });
          props.exportToOBJ(view, modelUrn, objIds);
        });
        reduceUserExportCount(subscriptionDetails[0].objExportCount);
      }
    } else {
      setexportObjCountInvalid(true);
    }
  };

  const errorNotification = (msg) => {
    toastr.error(msg);
  };

  const handleUpdateName = (item, index) => {
    if (!isNameExist(selectedViewName)) {
      props.updateView(index, item, selectedViewName, item.camera, 1);
    }
  };

  const isNameExist = (newName) => {
    if (
      props.viewsList &&
      props.viewsList.find((e) => e.ViewName === newName)
    ) {
      const msg =
        "A view with the same name already exists. Please use another name.";
      errorNotification(msg);
      return true;
    } else {
      return false;
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSaveView();
    }
  };

  const handleRenameEnter = (e, item, index) => {
    if (e.key === "Enter") {
      handleUpdateName(item, index);
    }
  };

  const deleteView = (index, view) => {
    if (props.deleting >= 0) {
      toastr.error(
        "A saved view is still being deleted. Please try again later."
      );
      return false;
    }
    props.deleteView(index, view);
  };

  const handleUpdateView = (index, view) => {
    const position = props.viewer.getState();
    props.updateView(index, view, view.viewName, JSON.stringify(position), 2);
  };

  const setNewPosition = (item) => {
    let curModels = [];
    let parsedObj = JSON.parse(item.camera);
    let mh = modelHierarchy;
    let toggeledModel = null;

    props.viewer.restoreState(parsedObj, true);

    if (parsedObj["objectSet"] && parsedObj["objectSet"].length > 1) {
      parsedObj["objectSet"].forEach((element) => {
        let urn = element.seedUrn;
        if (mh[urn] !== undefined) {
          toggeledModel = props.viewer.impl
            .modelQueue()
            .findModel(mh[urn].modelId);
          if (toggeledModel) {
            toggeledModel.visibilityManager.isolate(
              element.isolated,
              toggeledModel
            );
            element.hidden.forEach((hideElem) => {
              props.viewer.hide(hideElem, toggeledModel);
            });
          }
        }
      });
    } else {
      let viewUrn = parsedObj.seedURN;
      selectedModel.forEach((models) => {
        if (models.modelURN === viewUrn && mh[viewUrn]) {
          toggeledModel = props.viewer.impl
            .modelQueue()
            .findModel(mh[viewUrn].modelId);
          if (toggeledModel) {
            toggeledModel.visibilityManager.isolate(
              parsedObj["objectSet"][0].isolated,
              toggeledModel
            );
            parsedObj["objectSet"][0].hidden.forEach((hideElem) => {
              props.viewer.hide(hideElem, toggeledModel);
            });
          }
        } else {
          if (mh[models.modelURN] && mh[models.modelURN].modelId) {
            toggeledModel = props.viewer.impl
              .modelQueue()
              .findModel(mh[models.modelURN].modelId);
            if (toggeledModel) {
              toggeledModel.visibilityManager.hide(
                mh[models.modelURN].dbId,
                toggeledModel
              );
            }
          }
        }
      });
    }
  };

  const handleUpdateShow = (index, item) => {
    if (index === selectedView) {
      setSelectedView(null);
      return;
    }
    setSelectedView(index);
    setSelectedViewName(item.viewName);
    props.startUpdating();
  };

  const handleShare = async (data, index) => {
    setShareIndex(index);
    await getTempId(data.modelId, data.uniqueId)
      .then(async (res) => {
        setShareIndex(-1);
        if (res.data && res.data.result === 1) {
          setTempID(res.data.value);
          setShareModalShow(true);
        } else {
          console.log("Error");
        }
      })
      .catch((err) => {
        setShareIndex(-1);
      });
  };

  // Render saved views
  const views = () => {
    if (props.viewsList) {
      return props.viewsList.map((item, index) => {
        return (
          <Fragment key={index}>
            <tr>
              <td onClick={() => setNewPosition(item)}>{item.viewName}</td>
              <td className="text-right">
                {shareIndex === index ? (
                  <CircularProgress
                    color="secondary"
                    className="vertial-m mr-1"
                    size={18}
                  />
                ) : (
                  <InsertLink
                    className={"cursor"}
                    style={{ fontSize: 23 }}
                    onClick={() => handleShare(item, index)}
                  />
                )}
                <EditIcon
                  className={"cursor"}
                  style={{ fontSize: 20 }}
                  onClick={() => handleUpdateShow(index, item)}
                />
                {props.updating === index && props.updatingType === 2 ? (
                  <CircularProgress
                    color="secondary"
                    className="vertial-m mr-1"
                    size={18}
                  />
                ) : (
                  <RepeatIcon
                    className={"cursor"}
                    style={{ fontSize: 20 }}
                    onClick={() => handleUpdateView(index, item)}
                  />
                )}

                {props.deleting === index ? (
                  <CircularProgress
                    color="secondary"
                    className="vertial-m"
                    size={18}
                  />
                ) : (
                  <DeleteOutlineOutlinedIcon
                    className={"cursor"}
                    style={{ fontSize: 20 }}
                    onClick={() => deleteView(index, item)}
                  />
                )}
                {!multiModelLoaded && (
                  <Button
                    className={"cursor"}
                    style={{
                      fontSize: 20,
                      display: "-webkit-inline-box",
                    }}
                    onClick={() => exportViewToObj(index, item)}
                  >
                    <img
                      src={expObjImage}
                      style={{
                        width: "15px",
                        height: "20px",
                      }}
                    />
                  </Button>
                )}
              </td>
            </tr>
            {selectedView === index && (
              <tr>
                <td className="rename-td">
                  <input
                    type="text"
                    className="rename-field border-none"
                    placeholder="Name"
                    value={selectedViewName}
                    onChange={(e) => setSelectedViewName(e.target.value)}
                    onKeyPress={(e) => handleRenameEnter(e, item, index)}
                  />
                </td>
                {props.updating !== index && (
                  <td className="text-right saving">
                    <button
                      className="border-none mr-1"
                      onClick={() => handleUpdateName(item, index)}
                    >
                      Update
                    </button>
                    <CloseIcon
                      style={{ fontSize: 20 }}
                      onClick={() => setSelectedView(null)}
                    />
                  </td>
                )}
                {props.updating === index && props.updatingType === 1 && (
                  <td className="text-right">
                    <CircularProgress
                      color="secondary"
                      style={{ marginTop: "5px" }}
                      size={20}
                    />
                  </td>
                )}
              </tr>
            )}
          </Fragment>
        );
      });
    }
  };

  return (
    <div className="react-content">
      <React.Fragment>
        {props.getApi === 1 && !props.viewsList && (
          <div className="no-model">No views</div>
        )}
        <table>
          {props.modelList && (
            <thead>
              <tr>
                <th>Add view</th>
                <th
                  className="text-right saving"
                  onClick={() => handleShowSaveView()}
                >
                  <AddIcon style={{ fontSize: 20 }} />
                </th>
              </tr>
              {showNaming && (
                <tr>
                  <th className="rename-td">
                    <input
                      type="text"
                      className="rename-field border-none"
                      placeholder="Name"
                      value={orginName}
                      onChange={(e) => setOriginName(e.target.value)}
                      onKeyPress={(e) => handleEnter(e)}
                    />
                  </th>
                  {props.saveApi !== 0 && (
                    <th className="text-right saving">
                      <button
                        className="border-none mr-1"
                        onClick={() => handleSaveView()}
                      >
                        Save
                      </button>
                      <CloseIcon
                        style={{ fontSize: 20 }}
                        onClick={() => handleShowSaveView()}
                      />
                    </th>
                  )}
                  {props.saveApi === 0 && (
                    <th className="text-right">
                      <CircularProgress
                        color="secondary"
                        style={{ marginTop: "5px" }}
                        size={20}
                      />
                    </th>
                  )}
                </tr>
              )}
            </thead>
          )}
          {props.viewsList && props.getApi === 1 && <tbody>{views()}</tbody>}
        </table>
        {props.getApi === 0 && (
          <CircularProgress color="secondary" className="spinner" />
        )}
        {shareModalShow && (
          <ViewLilnkShare
            show={shareModalShow}
            tempid={tempID}
            onHide={() => {
              setShareModalShow(false);
              setTempID(null);
            }}
          />
        )}
      </React.Fragment>
      {exportObjCountInvalid && (
        <React.Fragment>
          <Snackbar
            open={exportObjCountInvalid}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            autoHideDuration={5000}
            onClose={handleSnackBarClose}
          >
            <Alert variant="filled" severity="error">
              Object export count has exceeded for your plan!
            </Alert>
          </Snackbar>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    viewsList,
    getApi,
    saveApi,
    deleting,
    updating,
    updatingType,
    renamingStatus,
  } = state.savedViews;
  const { modelList, currentModels } = state.models;
  return {
    viewsList,
    modelList,
    getApi,
    saveApi,
    deleting,
    updating,
    updatingType,
    renamingStatus,
    currentModels,
  };
};

export default connect(mapStateToProps, {
  getSavedViewList,
  exportToOBJ,
  saveView,
  getModelList,
  updateView,
  deleteView,
  startUpdating,
  finishUpdating,
  setCurrentModels,
})(panelContent);
