import React, { useEffect } from "react";
import { useState } from "react";
import { entitiesPropertiesList } from "../Viewer-helpers";

const ClusterPanelContent = (props) => {
  const [selectedCluster, setSelectedCluster] = useState("category");
  const [selectedProperty, setSelectedProperty] = useState("Select Property");
  const [extensionLoaded, setExtensionLoaded] = useState(undefined);
  const [properties, setProperties] = useState(undefined);
  useEffect(() => {
    let loadedExt = Object.keys(props.viewer.loadedExtensions);
    if (loadedExt.length > 0) {
      if (loadedExt.includes("Autodesk.VisualClusters")) {
        setExtensionLoaded(true);
      } else {
        setExtensionLoaded(false);
      }
    }
    let propertyNamesList = [];

    Object.keys(entitiesPropertiesList).forEach((key) => {
      Object.keys(entitiesPropertiesList[key]).forEach((entity) => {
        Object.keys(entitiesPropertiesList[key][entity]).forEach(
          (propertyName) => {
            if (!propertyNamesList.includes(propertyName))
              propertyNamesList.push(propertyName);
          }
        );
      });
    });
    propertyNamesList.sort();
    setProperties(propertyNamesList);
  }, []);

  const onClusterChanged = (event) => {
    setSelectedCluster(event.target.value);
  };

  const handlePropertyChange = (event) => {
    setSelectedProperty(event.target.value);
  };

  const onFormSubmit = async () => {
    if (extensionLoaded) {
      props.viewer.getExtension("Autodesk.VisualClusters").reset();
      props.viewer.unloadExtension("Autodesk.VisualClusters");
    }
    if (selectedCluster === "category") {
      await props.viewer.loadExtension("Autodesk.VisualClusters");
      props.viewer
        .getExtension("Autodesk.VisualClusters")
        .setLayoutActive(true);
      setExtensionLoaded(true);
    } else if (selectedProperty) {
      props.viewer
        .loadExtension("Autodesk.VisualClusters", {
          attribName: selectedProperty,
          searchAncestors: false,
        })
        .then(
          (data) => {
            props.viewer
              .getExtension("Autodesk.VisualClusters")
              .setLayoutActive(true);
            setExtensionLoaded(true);
          },
          (err) => {
            console.log("Error ", err);
            setExtensionLoaded(false);
          }
        );
    }
  };

  const onReset = () => {
    if (extensionLoaded) {
      props.viewer.getExtension("Autodesk.VisualClusters").reset();
    }
    setSelectedCluster("category");
    setSelectedProperty("Select Property");
    props.panel.setVisible(false);
  };

  const onCancel = () => {
    props.panel.setVisible(false);
  };

  return (
    <div className="react-content">
      <div className="gridWrapper">
        <div className="categoryGrid">
          <label htmlFor="category">
            <input
              type="radio"
              name="cluster"
              value="category"
              checked={selectedCluster === "category"}
              onChange={onClusterChanged}
            />{" "}
            Category
          </label>
        </div>
        <div class="solid" />
        <div className="propertyGrid">
          <label htmlFor="property">
            <input
              type="radio"
              name="cluster"
              value="property"
              checked={selectedCluster === "property"}
              onChange={onClusterChanged}
            />{" "}
            Property
          </label>
          {selectedCluster === "property" && properties ? (
            <div className="propertySelect">
              <select value={selectedProperty} onChange={handlePropertyChange}>
                <option value="Select Property">Select Property</option>
                {properties !== undefined ? (
                  properties.map((p) => <option key={p}>{p}</option>)
                ) : (
                  <></>
                )}
              </select>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="formButtons">
        <button className="reset" type="button" onClick={onReset}>
          Reset
        </button>
        <button className="ok" type="submit" onClick={onFormSubmit}>
          Apply
        </button>
        <button className="cancel" type="button" onClick={onCancel}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ClusterPanelContent;
