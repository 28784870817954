export const constants = {
  Development: {
    paypalClientID:
      "ATGO5eTJpG4nb7Vr32KsKBn0BUOVFXbqJ0OLdMKA6B6TeuJ9s8h6fTkkRw-KXMA7_s3fyDGvt7LueXhL",
    paypalSecretID:
      "EEsrw-mSfDu-u2M-kxHRyp5PxTulAMkUKMMSkz2M47LFEPU0hJSR4TAcdUdzWAQRHqPaQb2uZx0o6cgK",
    subscriptionTrialPlanID: "P-TrialPlan",
    subscriptionLitePlanID: "P-78F48871P64590534MMQ36FQ",
    subscriptionBasicPlanID: "P-11A81865TK694281VMMQ37KI",
    subscriptionProPlanID: "P-6718994659176582PMMQ37UQ",
    // subscriptionProPlanID: "P-5JD38813SH364562KMMQ4AZY", // testing plan
    trialSubscriptionId: "I-trialPlan",
    subscriptionTrialPlanName: "Trial",
    subscriptionLitePlanName: "Lite",
    subscriptionBasicPlanName: "Basic",
    subscriptionProPlanName: "Pro",
    subscriptionPlanCancel: "Cancelled",
    paidUserTableName: "PaidUserData",
    subscriptionTableName: "Subscription",
    trialModelLength: 5,
    liteModelLength: 10,
    basicModelLength: 20,
    proModelLength: 40,
    perModelPrice: 6,
    NullCredits: "0",
  },
  Testing: {
    paypalClientID:
      "AWtOe-PosoJBafovUUXsBcaSJuCUsZ3CH6ZrUGg-1I2qyeFPt7-8T1SIR5p-Fu6qXfjbiK7Az5WQVbIF",
    paypalSecretID:
      "EIreh9xC3Hv_mbqwr61QGyS2xgGkazrOR_YAgSiugJU6zSTA1kIWTAGbEzTLZAzFPhXozGSS0MXvvImh",
    subscriptionTrialPlanID: "P-TrialPlan",
    subscriptionLitePlanID: "P-7VG76710FB825174XMGU5VDA",
    subscriptionBasicPlanID: "P-28J79581KE5723148MGW73JY",
    subscriptionProPlanID: "P-7J61306357488652KMGW736Y",
    trialSubscriptionId: "I-trialPlan",
    subscriptionTrialPlanName: "Trial",
    subscriptionLitePlanName: "Lite",
    subscriptionBasicPlanName: "Basic",
    subscriptionProPlanName: "Pro",
    subscriptionPlanCancel: "Cancelled",
    paidUserTableName: "PaidUserData",
    subscriptionTableName: "Subscription",
    trialModelLength: 5,
    liteModelLength: 10,
    basicModelLength: 20,
    proModelLength: 40,
    perModelPrice: 6,
    TestingEmail: "<Add testing email address here>",
    NullCredits: "0",
  },
  Production: {
    paypalClientID:
      "ATGO5eTJpG4nb7Vr32KsKBn0BUOVFXbqJ0OLdMKA6B6TeuJ9s8h6fTkkRw-KXMA7_s3fyDGvt7LueXhL",
    paypalSecretID:
      "EEsrw-mSfDu-u2M-kxHRyp5PxTulAMkUKMMSkz2M47LFEPU0hJSR4TAcdUdzWAQRHqPaQb2uZx0o6cgK",
    subscriptionTrialPlanID: "P-TrialPlan",
    subscriptionLitePlanID: "P-78F48871P64590534MMQ36FQ",
    subscriptionBasicPlanID: "P-11A81865TK694281VMMQ37KI",
    subscriptionProPlanID: "P-6718994659176582PMMQ37UQ",
    // subscriptionProPlanID: "P-5JD38813SH364562KMMQ4AZY", // testing plan
    trialSubscriptionId: "I-trialPlan",
    subscriptionTrialPlanName: "Trial",
    subscriptionLitePlanName: "Lite",
    subscriptionBasicPlanName: "Basic",
    subscriptionProPlanName: "Pro",
    subscriptionPlanCancel: "Cancelled",
    subscriptionTableName: "Subscription",
    paidUserTableName: "PaidUserData",
    trialModelLength: 5,
    liteModelLength: 10,
    basicModelLength: 20,
    proModelLength: 40,
    perModelPrice: 6,
  },
};
export const Environment = "Development";
