import {
  Grid,
  IconButton,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Divider,
  Switch,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { getModelList } from "../../../../actions/modelsActions";
import {
  setTemplate,
  updateTemplate,
  deleteTemplate,
  getTemplate,
  getCustomProperties,
  addCustomProperty,
} from "../../../../actions/customParameterActions";
import { splitURN } from "../../../../utils/api";
import {
  getFolders,
  setCurrentFolder,
} from "../../../../actions/folderActions";
import { modelHierarchy } from "../../Viewer-helpers";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  buttonRoot: {
    color: "white",
    height: 64,
    width: 64,
    padding: 0,
    fontSize: "small",
  },
  iconRoot: {
    width: 45,
    height: 45,
  },
});

const Template = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [propertyNames, setPropertyNames] = useState([""]);
  const [propertyId, setPropertyId] = useState([1]);
  const [modelTemplates, setModelTemplates] = useState([]);
  const [multiModelTemplates, setMultiModelTemplates] = useState([]);
  const [showAddTemplateUI, setShowAddTemplateUI] = useState(false);
  const [editTemplateIndex, setEditTemplateIndex] = useState(-1);
  const [templateName, setTemplateName] = useState("");
  const [removedProperties, setRemovedProperties] = useState([]);
  const [editMultiModelIndex, setEditMultiModelIndex] = useState(-1);
  const [addMultiTemplate, setAddMultiTemplate] = useState(false);
  const [selectedModel, setSelectedModel] = useState([]);
  const [selectedColourProperty, setSelectedColourProperty] = useState([]);
  const [addTemplateIndex, setAddTemplateIndex] = useState(-1);
  const [inputsAreEmpty, setInputsAreEmpty] = useState(false);
  const [inputsAreDuplicate, setInputsAreDuplicate] = useState(false);

  useEffect(() => {
    if (!props.modelList) {
      props.getModelList(localStorage.getItem("subId"));
    }
    if (!props.folderId.length) {
      dispatch(getFolders(localStorage.getItem("subId")));
    }
  }, []);

  useEffect(() => {
    let tempSelected = [];
    const url = window.location.pathname;
    let urlParams = splitURN(url);
    let urlIds = urlParams.urlIds;

    urlIds.forEach((urn, index) => {
      let model = findModel(urn);
      if (model) {
        let name = checkModel(model.uniqueId);
        if (name) {
          tempSelected.push(name);
        }
        if (
          props.templateProperties.length === 0 &&
          props.propertyValuesFetched < 0
        ) {
          dispatch(getTemplate(model.uniqueId));
          dispatch(getCustomProperties(model.uniqueId));
        }
      }
    });
    if (tempSelected.length) {
      setSelectedModel(tempSelected);
    }
    if (props.fetchFoldersStatus) {
      let model = findModel(urlIds[0]);
      if (model) {
        dispatch(setCurrentFolder(findFolder(model)));
      }
      let selectFolder = parseInt(props.selectedFolder);
      if (selectFolder > -1) {
        dispatch(getTemplate(props.folderId[selectFolder], true));
      }
    }
  }, [props.modelList, props.fetchFoldersStatus, props.selectedFolder]);

  useEffect(() => {
    let temp = [];
    for (let i in props.templateProperties) {
      temp.push({
        tempModelId: props.tempModelId[i],
        templateId: props.templateId[i],
        name: props.templateName[i],
        template: props.templateProperties[i],
      });
    }
    setModelTemplates(temp);
  }, [props.templateProperties]);

  useEffect(() => {
    let temp = [];
    for (let i in props.multipleTemplateProperties) {
      temp.push({
        tempModelId: props.multipleTempModelId[i],
        templateId: props.multipleTemplateId[i],
        name: props.multipleTemplateName[i],
        template: props.multipleTemplateProperties[i],
      });
    }
    setMultiModelTemplates(temp);
  }, [props.multipleTemplateProperties]);

  const findModel = (urn) => {
    let model = null;
    if (props.modelList) {
      model = props.modelList.find((item) => item.modelURN === urn);
    }
    return model;
  };

  const checkModel = (id) => {
    let model = null;
    if (props.modelList) {
      model = props.modelList.find((item) => item.uniqueId === id);
    }
    return model;
  };

  const checkModelTemplate = (id) => {
    let modelT = -1;
    if (props.modelList) {
      modelT = modelTemplates.findIndex((item) => item.tempModelId === id);
    }
    return modelT;
  };

  const findFolder = (model) => {
    let index = -1;
    if (model) {
      if (props.folderModelList) {
        index = props.folderModelList.findIndex((item) =>
          item.includes(model.uniqueId)
        );
      }
    }
    return index;
  };

  const createTemplateId = () => {
    return "id" + new Date().getTime();
  };

  const createPropertyId = () => {
    let existingKeys = Object.keys(propertyNames);
    let found = true;
    let randomNum;
    do {
      randomNum = Math.floor(Math.random() * 100);
      found = existingKeys.includes(randomNum);
    } while (found);
    return randomNum;
  };

  const getUniqueIds = (templateId) => {
    let uniqueIdsList = [];
    if (
      props.propertiesValuesList[templateId] &&
      props.propertiesValuesList[templateId].length > 0
    ) {
      props.propertiesValuesList[templateId].map((temp) => {
        uniqueIdsList.push(temp.uniqueId);
      });
    }
    return uniqueIdsList;
  };

  const nameChanged = (value, index) => {
    let newProperties = [...propertyNames];
    newProperties[index] = value;
    setPropertyNames(newProperties);
  };

  const addPropertyName = () => {
    if (propertyNames.length < 10) {
      let newProperties = [...propertyNames];
      let newPropertyIds = [...propertyId];
      let propKey = createPropertyId();
      newProperties.push("");
      newPropertyIds.push(propKey);
      setPropertyNames(newProperties);
      setPropertyId(newPropertyIds);
    }
  };

  const removePropertyName = (index) => {
    let newProperties = [...propertyNames];
    newProperties.splice(index, 1);
    let newPropertyIds = [...propertyId];
    let removedPropId = newPropertyIds[index];
    newPropertyIds.splice(index, 1);
    let tempRemovedPropertyIds = [...removedProperties];
    tempRemovedPropertyIds.push(removedPropId);
    setRemovedProperties(tempRemovedPropertyIds);
    setPropertyNames(newProperties);
    setPropertyId(newPropertyIds);
  };

  const updateCustomProperties = (selectedTemplateId, selectedModelId) => {
    if (removedProperties.length > 0) {
      let uniqueIdsList = [];
      let entityIdsList = [];
      let propertyObject = [];
      let tempPropertyObject = [];
      let modelId = null;
      for (let i in props.propertiesValuesList[selectedTemplateId]) {
        uniqueIdsList.push(
          props.propertiesValuesList[selectedTemplateId][i].uniqueId
        );
        entityIdsList.push(
          props.propertiesValuesList[selectedTemplateId][i].id
        );
        tempPropertyObject =
          props.propertiesValuesList[selectedTemplateId][i].values;
        for (let j = tempPropertyObject.length - 1; j >= 0; --j) {
          if (removedProperties.includes(tempPropertyObject[j].id)) {
            tempPropertyObject.splice(j, 1);
          }
        }
        propertyObject.push(JSON.stringify(tempPropertyObject));
        modelId = props.propertiesValuesList[selectedTemplateId][i].modelId;
      }
      dispatch(
        addCustomProperty(
          uniqueIdsList,
          modelId,
          selectedTemplateId,
          entityIdsList,
          propertyObject
        )
      );
    }
  };

  const addTemplate = () => {
    if (templateName === "") {
      setInputsAreEmpty(true);
      return;
    }
    for (let i in propertyNames) {
      if (propertyNames[i] === "") {
        setInputsAreEmpty(true);
        return;
      }
      for (let j in propertyNames) {
        if (j !== i && propertyNames[j] === propertyNames[i]) {
          setInputsAreDuplicate(true);
          return;
        }
      }
    }
    setInputsAreEmpty(false);
    setInputsAreDuplicate(false);
    let propertyObject = [];
    if (propertyNames)
      propertyNames.map((propName, index) => {
        propertyObject.push({
          id: propertyId[index],
          propertyName: propName,
        });
      });
    //Template Update
    if (editTemplateIndex > -1 || editMultiModelIndex > -1) {
      let temp = [],
        editIndex = -1,
        multiModel = false;
      if (editTemplateIndex > -1) {
        //Update single model template
        temp = [...modelTemplates];
        temp[editTemplateIndex].template = propertyObject;
        temp[editTemplateIndex].name = templateName;
        editIndex = editTemplateIndex;
        multiModel = false;
        setModelTemplates(temp);
      } else if (editMultiModelIndex > -1) {
        //Update multimodel template
        temp = [...multiModelTemplates];
        temp[editMultiModelIndex].template = propertyObject;
        temp[editMultiModelIndex].name = templateName;
        editIndex = editMultiModelIndex;
        multiModel = true;
        setMultiModelTemplates(temp);
      }
      dispatch(
        updateTemplate(
          editIndex,
          temp[editIndex].tempModelId,
          temp[editIndex].templateId,
          templateName,
          propertyObject,
          multiModel
        )
      );
      updateCustomProperties(
        temp[editIndex].templateId,
        temp[editIndex].tempModelId
      );
      setEditTemplateIndex(-1);
    } else {
      //Template Adding for first time
      let temp = null;
      let templateModels = null;
      if (addMultiTemplate) {
        //adding multi model template
        if (parseInt(props.selectedFolder) > -1) {
          templateModels = props.folderId[parseInt(props.selectedFolder)];
        }
        temp = [...multiModelTemplates];
        temp.push({ name: templateName, template: propertyObject });
        setMultiModelTemplates(temp);
      } else {
        //adding single model template
        if (
          selectedModel &&
          selectedModel[addTemplateIndex] &&
          selectedModel[addTemplateIndex].uniqueId
        ) {
          temp = [...modelTemplates];
          temp.push({ name: templateName, template: propertyObject });
          setModelTemplates(temp);
          templateModels = selectedModel[addTemplateIndex].uniqueId;
        }
      }
      if (templateModels) {
        dispatch(
          setTemplate(
            templateModels,
            createTemplateId(),
            templateName,
            propertyObject,
            addMultiTemplate
          )
        );
      }
    }
    setAddTemplateIndex(-1);
    cancelTemplate();
  };

  const cancelTemplate = () => {
    setPropertyNames([""]);
    setPropertyId([""]);
    setShowAddTemplateUI(false);
    setEditTemplateIndex(-1);
    setTemplateName("");
    setEditMultiModelIndex(-1);
    setAddMultiTemplate(false);
    setSelectedColourProperty([]);
    setAddTemplateIndex(-1);
    setInputsAreEmpty(false);
    setInputsAreDuplicate(false);
  };

  const editTemplate = (index, multiModel = false) => {
    let temp = [];
    let tempPropIds = [];
    let tempName = null;
    if (multiModel) {
      Object.keys(multiModelTemplates[index].template).map((key) => {
        temp[key] = multiModelTemplates[index].template[key].propertyName;
      });
      multiModelTemplates[index].template.map((property) => {
        tempPropIds.push(property.id);
      });
      tempName = multiModelTemplates[index].name;
      setEditMultiModelIndex(index);
    } else {
      Object.keys(modelTemplates[index].template).map((key) => {
        temp[key] = modelTemplates[index].template[key].propertyName;
      });
      modelTemplates[index].template.map((property) => {
        tempPropIds.push(property.id);
      });
      tempName = modelTemplates[index].name;
      setEditTemplateIndex(index);
    }
    setPropertyId(tempPropIds);
    setTemplateName(tempName);
    setPropertyNames(temp);
    setShowAddTemplateUI(true);
  };

  const deleteModelTemplate = (index, multiModel = false) => {
    let temp = [];
    let uniqueIdsList = [];
    let tempModelId = null,
      tempTemplateId = null;
    if (multiModel) {
      temp = [...multiModelTemplates];
      uniqueIdsList = getUniqueIds(multiModelTemplates[index].templateId);
      tempModelId = multiModelTemplates[index].tempModelId;
      tempTemplateId = multiModelTemplates[index].templateId;
      temp.splice(index, 1);
      setMultiModelTemplates(temp);
    } else {
      temp = [...modelTemplates];
      uniqueIdsList = getUniqueIds(modelTemplates[index].templateId);
      tempModelId = modelTemplates[index].tempModelId;
      tempTemplateId = modelTemplates[index].templateId;
      temp.splice(index, 1);
      setModelTemplates(temp);
    }
    dispatch(
      deleteTemplate(
        index,
        tempModelId,
        tempTemplateId,
        uniqueIdsList,
        multiModel
      )
    );
    setPropertyNames([""]);
    setPropertyId([""]);
    setShowAddTemplateUI(false);
    setEditTemplateIndex(-1);
    setEditMultiModelIndex(-1);
    setTemplateName("");
    setAddTemplateIndex(-1);
  };

  const clearThemeingColours = () => {
    const url = window.location.pathname;
    let urlParams = splitURN(url);
    let urlIds = urlParams.urlIds;

    urlIds.forEach((urn) => {
      if (modelHierarchy[urn]) {
        let toggeledModel = props.viewer.impl
          .modelQueue()
          .findModel(modelHierarchy[urn].modelId);
        if (toggeledModel) {
          props.viewer.clearThemingColors(toggeledModel);
        }
      }
    });
  };

  const handleColourPropertyChange = (event, propertyIndex) => {
    clearThemeingColours();
    let tempColourToggle = [...selectedColourProperty];
    if (event.target.checked) {
      for (let i in tempColourToggle) {
        if (tempColourToggle[i]) {
          tempColourToggle[i] = false;
        }
      }
      tempColourToggle[propertyIndex] = true;
      if (editTemplateIndex > -1) {
        let templateId = props.templateId[editTemplateIndex];
        let curModelId = props.tempModelId[editTemplateIndex];
        let modelUrn = checkModel(curModelId).modelURN;
        let toggeledModel = null;
        if (modelHierarchy[modelUrn])
          toggeledModel = props.viewer.impl
            .modelQueue()
            .findModel(modelHierarchy[modelUrn].modelId);
        if (
          props.propertyValuesFetched &&
          props.propertiesValuesList[templateId] &&
          toggeledModel
        ) {
          toggeledModel.getObjectTree(function (objectTree) {
            props.propertiesValuesList[templateId].map((item) => {
              if (
                item.values &&
                item.values[propertyIndex] &&
                item.values[propertyIndex].color &&
                item.values[propertyIndex].color.rgb
              ) {
                if (objectTree.getChildCount(parseInt(item.id)) === 0) {
                  const r = item.values[propertyIndex].color.rgb.r / 255;
                  const b = item.values[propertyIndex].color.rgb.b / 255;
                  const g = item.values[propertyIndex].color.rgb.g / 255;
                  var themeColor = new Window.THREE72.Vector4(r, g, b, 1);
                  if (toggeledModel)
                    toggeledModel.setThemingColor(
                      parseInt(item.id),
                      themeColor,
                      toggeledModel
                    );
                }
              }
            });
          });

          props.viewer.impl.invalidate(true);
        }
      } else if (editMultiModelIndex > -1) {
        let templateId = props.multipleTemplateId[editMultiModelIndex];
        if (
          props.propertyValuesFetched &&
          props.propertiesValuesList[templateId]
        ) {
          props.propertiesValuesList[templateId].map((item) => {
            let model = checkModel(item.modelId);
            let modelUrn = model.modelURN;
            let toggeledModel = null;
            if (modelHierarchy[modelUrn])
              toggeledModel = props.viewer.impl
                .modelQueue()
                .findModel(modelHierarchy[modelUrn].modelId);

            if (toggeledModel) {
              toggeledModel.getObjectTree(function (objectTree) {
                if (
                  item.values &&
                  item.values[propertyIndex] &&
                  item.values[propertyIndex].color &&
                  item.values[propertyIndex].color.rgb &&
                  item.modelId === model.uniqueId
                ) {
                  if (objectTree.getChildCount(parseInt(item.id)) === 0) {
                    const r = item.values[propertyIndex].color.rgb.r / 255;
                    const b = item.values[propertyIndex].color.rgb.b / 255;
                    const g = item.values[propertyIndex].color.rgb.g / 255;
                    var themeColor = new Window.THREE72.Vector4(r, g, b, 1);
                    if (toggeledModel)
                      toggeledModel.setThemingColor(
                        parseInt(item.id),
                        themeColor,
                        toggeledModel
                      );
                  }
                }
              });
            }
          });
          props.viewer.impl.invalidate(true);
        }
      }
      props.viewer.setDisplayEdges(true);
    } else {
      tempColourToggle[propertyIndex] = false;
    }
    setSelectedColourProperty(tempColourToggle);
  };

  const addTemplateUI = () => {
    return (
      <>
        <div>
          <ArrowBackIcon onClick={cancelTemplate} />
          <Typography> Property names:</Typography>
        </div>
        {propertyNames &&
          propertyNames.map((name, id) => {
            return (
              <Fragment key={id}>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "3px", marginBottom: "2px" }}
                >
                  <Grid item xs={4}>
                    <input
                      className="propetyLableWrapper"
                      id={id}
                      label="Property Name"
                      type="text"
                      value={name}
                      onChange={(event) => nameChanged(event.target.value, id)}
                    />
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={1}>
                    {id === propertyNames.length - 1 &&
                      propertyNames.length < 10 && (
                        <button
                          className="button"
                          style={{
                            backgroundColor: "#ffffff",
                            marginLeft: "3px",
                          }}
                          onClick={addPropertyName}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          {"  "}
                        </button>
                      )}
                  </Grid>
                  <Grid item xs={1}>
                    {(propertyNames.length > 1 || id !== 0) && (
                      <button
                        className="button"
                        style={{
                          backgroundColor: "#ffffff",
                          marginLeft: "3px",
                        }}
                        onClick={() => removePropertyName(id)}
                      >
                        <i class="fa fa-minus" aria-hidden="true"></i>
                        {"  "}
                      </button>
                    )}
                  </Grid>
                  {(editTemplateIndex > -1 || editMultiModelIndex > -1) && (
                    <Grid item xs={1} style={{ marginLeft: "20px" }}>
                      <FormControl>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                className="toggleAlignment"
                                color="primary"
                                checked={
                                  selectedColourProperty[id] ? true : false
                                }
                                onChange={(event) =>
                                  handleColourPropertyChange(event, id)
                                }
                                size="medium"
                              />
                            }
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Fragment>
            );
          })}{" "}
        <Grid container spacing={3} style={{ marginTop: "15px" }}>
          <Grid item xs={4}>
            <input
              className="propetyLableWrapper"
              id="templateName"
              label="Template Name"
              placeholder="Template Name"
              type="text"
              value={templateName}
              onChange={(event) => setTemplateName(event.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <div></div>
          </Grid>
          <Grid item xs={5}>
            <button
              type="submit"
              className="button saveButtonWrapper"
              style={{
                backgroundColor: "#ffffff",
                marginLeft: "2px",
                marginBottom: "20px",
              }}
              onClick={addTemplate}
            >
              Save
            </button>
          </Grid>
        </Grid>
        {inputsAreEmpty && (
          <div style={{ fontSize: "small", color: "white" }}>
            Property name or template name cannot be empty!
          </div>
        )}
        {inputsAreDuplicate && (
          <div style={{ fontSize: "small", color: "white" }}>
            Property Names cannot be duplicate!
          </div>
        )}
      </>
    );
  };

  const listTemplateUI = () => {
    return selectedModel.map((item, index) => {
      let tempIndex = checkModelTemplate(item.uniqueId);
      let temp = modelTemplates[tempIndex];
      return (
        <Fragment key={index}>
          <List>
            <ListItem>
              <ListItemText
                primary={item.displayName ? item.displayName : ""}
              />
            </ListItem>
            {tempIndex > -1 && modelTemplates[tempIndex] ? (
              <List style={{ marginLeft: "2%" }}>
                <ListItem>
                  <AssignmentIcon
                    className={"cursor"}
                    style={{ fontSize: 20, marginRight: "15px" }}
                  />
                  <ListItemText
                    primary={temp.name}
                    onClick={() => editTemplate(tempIndex)}
                  />
                  <EditIcon
                    className={"cursor"}
                    style={{ fontSize: 20, marginRight: "15px" }}
                    onClick={() => editTemplate(tempIndex)}
                  />
                  <DeleteOutlineOutlinedIcon
                    className={"cursor"}
                    style={{ fontSize: 20 }}
                    onClick={() => deleteModelTemplate(tempIndex)}
                  />
                </ListItem>
              </List>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <IconButton
                  className={classes.buttonRoot}
                  onClick={() => {
                    setShowAddTemplateUI(true);
                    setAddTemplateIndex(index);
                  }}
                >
                  <AddCircleOutlineIcon className={classes.iconRoot} />
                </IconButton>
              </div>
            )}
          </List>
        </Fragment>
      );
    });
  };

  const listMultiTemplatesUI = () => {
    return multiModelTemplates.map((item, index) => {
      return (
        <Fragment key={index}>
          <List>
            <ListItem>
              <AssignmentIcon
                className={"cursor"}
                style={{ fontSize: 20, marginRight: "15px" }}
              />
              <ListItemText
                primary={item.name}
                onClick={() => editTemplate(index, true)}
              />
              <EditIcon
                className={"cursor"}
                style={{ fontSize: 20, marginRight: "15px" }}
                onClick={() => editTemplate(index, true)}
              />
              <DeleteOutlineOutlinedIcon
                className={"cursor"}
                style={{ fontSize: 20 }}
                onClick={() => deleteModelTemplate(index, true)}
              />
            </ListItem>
          </List>
        </Fragment>
      );
    });
  };

  return (
    <div className="react-content">
      {props.propertyValuesFetched && props.templatesFetched ? (
        <Fragment>
          {!showAddTemplateUI && selectedModel ? (
            <div style={{ marginBottom: "10px" }}>
              {listTemplateUI()}

              <div>
                <div style={{ marginRight: "5%", marginLeft: "5%" }}>
                  <Divider
                    variant="fullWidth"
                    style={{ backgroundColor: "#fff" }}
                  />

                  <div style={{ fontSize: "initial" }}>
                    Multi Model Templates :
                  </div>
                </div>

                {listMultiTemplatesUI()}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {multiModelTemplates.length < 1 ? (
                    <IconButton
                      className={classes.buttonRoot}
                      onClick={() => {
                        setShowAddTemplateUI(true);
                        setAddMultiTemplate(true);
                      }}
                    >
                      <AddCircleOutlineIcon className={classes.iconRoot} />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginLeft: "5%", marginRight: "5%" }}>
              {addTemplateUI()}
            </div>
          )}
          <div style={{ paddingBottom: "10%" }}></div>
        </Fragment>
      ) : (
        <Fragment>
          <CircularProgress color="secondary" className="spinner" />
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { modelList } = state.models;
  const {
    tempModelId,
    templateId,
    templateName,
    templateProperties,
    multipleTempModelId,
    multipleTemplateId,
    multipleTemplateName,
    multipleTemplateProperties,
    propertiesValuesList,
    propertyValuesFetched,
    templatesFetched,
  } = state.customProperties;
  const { folderId, selectedFolder, fetchFoldersStatus, folderModelList } =
    state.folders;
  return {
    modelList,
    templateName,
    templateProperties,
    templateId,
    tempModelId,
    multipleTempModelId,
    multipleTemplateId,
    multipleTemplateName,
    multipleTemplateProperties,
    propertiesValuesList,
    propertyValuesFetched,
    templatesFetched,
    folderId,
    selectedFolder,
    fetchFoldersStatus,
    folderModelList,
  };
};

export default connect(mapStateToProps, {
  getModelList,
  setTemplate,
  updateTemplate,
  deleteTemplate,
  getTemplate,
  getCustomProperties,
  addCustomProperty,
  getFolders,
  setCurrentFolder,
})(Template);
