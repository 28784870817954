import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useDispatch } from "react-redux";
import {
  getCustomProperties,
  getTemplate,
  setLoadCustomProperties,
} from "../../actions/customParameterActions";
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Helpers from "./Viewer-helpers";
import { checkViewerValidation } from "../../services/apiHelper";
import { toastr } from "react-redux-toastr";
import { getSavedView } from "../../services/apiHelper";
import {
  withOrientationChange,
  isBrowser,
  isMobile,
  isIOS,
  isTablet,
} from "react-device-detect";
import { useState } from "react";
import {
  setViewerModel,
  getModelList,
  setCurrentModels,
  updateTranslationStatus,
} from "../../actions/modelsActions";
import {
  modelHierarchy,
  viewer,
  entitiesPropertiesList,
} from "./Viewer-helpers";
import { getFolders, setCurrentFolder } from "../../actions/folderActions";
import { splitURN } from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  viewerContainer: {
    margin: 0,
    height: "85vh",
    width: "100%",
  },
  drawerViewerContainer: {
    margin: 0,
    height: "85vh",
    float: "left",
    width: "100%",
    "& .adsk-viewing-viewer": {
      width: "80% !important",
    },
  },
  iosViewerContainer: {
    margin: 0,
    width: "100%",
  },
  viewer: {
    "& .adsk-viewing-viewer": {
      height: "calc(100vh - 5rem) !important",
    },
  },
  drawerViewer: {
    float: "left",
    "& .adsk-viewing-viewer": {
      height: "calc(100vh - 5rem) !important",
      width: "80%",
    },
  },
  androidViewer: {
    "& .adsk-viewing-viewer": {
      height: "88vh !important",
      width: "50%!important",
    },
    "& .adsk-viewing-viewer .adsk-toolbar": {
      bottom: "4rem !important",
    },
  },
  androidViewerDrawer: {
    "& .adsk-viewing-viewer": {
      height: "88vh !important",
    },
    "& .adsk-viewing-viewer .adsk-toolbar": {
      bottom: "4rem !important",
    },
  },
  iosViewer: {
    "& .adsk-viewing-viewer": {
      height: "calc(100vh - 10rem) !important",
      ["@media(max-width: 321px)"]: {
        height: "calc(100vh - 9.5rem) !important",
      },
      width: "50%!important",
    },
    "& .adsk-viewing-viewer .adsk-toolbar": {
      bottom: "2rem !important",
    },
  },
  iosViewerDrawer: {
    "& .adsk-viewing-viewer": {
      height: "calc(100vh - 10rem) !important",
      ["@media(max-width: 321px)"]: {
        height: "calc(100vh - 9.5rem) !important",
      },
    },
    "& .adsk-viewing-viewer .adsk-toolbar": {
      bottom: "2rem !important",
    },
  },
  landScapeViewer: {
    "& .adsk-viewing-viewer": {
      height: "calc(100vh - 5rem) !important",
      width: "70%!important",
    },
    "& .adsk-viewing-viewer .adsk-toolbar": {
      bottom: "1rem !important",
    },
  },
  landScapeViewerDrawer: {
    "& .adsk-viewing-viewer": {
      height: "calc(100vh - 5rem) !important",
    },
    "& .adsk-viewing-viewer .adsk-toolbar": {
      bottom: "1rem !important",
    },
  },
  modelName: {
    height: "calc(100vh - 5rem) !important",
    right: "200px",
    overflow: "hidden",
    position: "absolute",
    top: "70px",
    whitespace: "nowrap",
    textalign: "right",
    zindex: 2,
    width: "800px",
    userselect: "none",
    webkituserdrag: "none",
    webkituserselect: "none",
  },
}));
const Autodesk = window.Autodesk;
export let modelNames = {};

const Viewer = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { documentId } = useParams();
  const { isLandscape } = props;
  const [modelToggleOpen, setModelToggleOpen] = useState(true);
  const [allModelsSelected, setAllModelsSelected] = useState(true);
  const [sharedView, setSharedView] = useState(false);
  const [viewerToggleEnabled, setViewerToggleEnabled] = useState(true);
  const [showAllEvents, setShowAllEvents] = useState(false);

  useEffect(() => {
    if (!props.modelList) {
      dispatch(getModelList(localStorage.getItem("subId")));
    } else {
      const url = window.location.pathname;
      if (!url.includes("/shared_viewer")) {
        let curModel = [];
        let viewerModels = [];
        let urlParams = splitURN(url);
        let urlIds = urlParams.urlIds;
        let mCount = 0;

        var x = document.getElementById("customsnackbar");
        x.innerText = "Please wait while database is loading...";
        x.className = "show";

        urlIds.forEach(async (urn, index) => {
          curModel[index] = true;
          let model = findModel(urn);
          if (model && !props.selectedModel) {
            modelNames[urn] = model.displayName;
            viewerModels.push(model);
            dispatch(getTemplate(model.uniqueId));
            await dispatch(getCustomProperties(model.uniqueId));
            mCount++;
            if (mCount === urlIds.length) {
              dispatch(setLoadCustomProperties(false));
              x.innerText = "Database loaded!";
              setTimeout(function () {
                x.className = x.className.replace("show", "");
              }, 3000);
            }
          }
        });
        if (viewerModels.length) dispatch(setViewerModel(viewerModels));
        dispatch(setCurrentModels(curModel));

        if (!props.folderId.length) {
          dispatch(getFolders(localStorage.getItem("subId")));
        }
      }
    }
  }, []);

  useEffect(() => {
    if (props.propertyValuesFetched) {
      setViewerToggleEnabled(false);
      if (props.selectedModel) {
        props.selectedModel.forEach((smodel) => {
          if (
            smodel &&
            smodel.loadingStatus !== "Loaded" &&
            smodel.loadingStatus !== "Translation Failed"
          ) {
            dispatch(
              updateTranslationStatus(
                localStorage.getItem("subId"),
                smodel.uniqueId,
                "Loaded"
              )
            );
          }
        });
      }
    }
  }, [props.propertyValuesFetched, props.selectedModel]);

  useEffect(() => {
    const url = window.location.pathname;
    if (!url.includes("/shared_viewer")) {
      let urlParams = splitURN(url);
      let urlIds = urlParams.urlIds;
      let mCount = 0;

      if (props.fetchCustomProperties) {
        var x = document.getElementById("customsnackbar");
        x.innerText = "Please wait while database is loading...";
        x.className = "show";
      }

      urlIds.forEach(async (urn, index) => {
        let model = findModel(urn);

        if (model && props.fetchCustomProperties) {
          dispatch(getTemplate(model.uniqueId));
          await dispatch(getCustomProperties(model.uniqueId));
          mCount++;
          if (mCount === urlIds.length) {
            dispatch(setLoadCustomProperties(false));
            x.innerText = "Database loaded!";
            setTimeout(function () {
              x.className = x.className.replace("show", "");
            }, 3000);
          }
        }
      });
    }
  }, [props.modelList, props.fetchCustomProperties]);

  useEffect(() => {
    const url = window.location.pathname;
    let curModel = [];
    let viewerModels = [];
    let urlParams = splitURN(url);
    let urlIds = urlParams.urlIds;

    urlIds.forEach(async (urn, index) => {
      curModel[index] = true;
      let model = findModel(urn);
      if (model && !props.selectedModel) {
        modelNames[urn] = model.displayName;
        viewerModels.push(model);
      }
    });
    if (viewerModels.length) dispatch(setViewerModel(viewerModels));
    dispatch(setCurrentModels(curModel));

    if (props.fetchFoldersStatus) {
      let model = findModel(urlIds[0]);
      //set the current folder
      if (model) {
        dispatch(setCurrentFolder(findFolder(model)));
      }
      let selectFolder = parseInt(props.selectedFolder);
      if (selectFolder > -1) {
        //fetch multi model template by passing the folder id
        dispatch(getTemplate(props.folderId[selectFolder], true));
      }
    }
  }, [props.modelList, props.fetchFoldersStatus, props.selectedFolder]);

  useEffect(() => {
    if (
      props.currentModels &&
      viewer &&
      props.currentModels.length > 0 &&
      Object.keys(modelHierarchy).length &&
      props.selectedModel &&
      props.selectedModel.length &&
      modelHierarchy
    ) {
      if (!showAllEvents) {
        viewer.addEventListener(Autodesk.Viewing.SHOW_ALL_EVENT, () => {
          selectAllModels(true);
        });
        setShowAllEvents(true);
      }
      for (let index in props.currentModels) {
        if (!props.currentModels[index]) {
          if (
            props.selectedModel[index] &&
            props.selectedModel[index].modelURN &&
            modelHierarchy[props.selectedModel[index].modelURN]
          ) {
            let toggeledModel = viewer.impl
              .modelQueue()
              .findModel(
                modelHierarchy[props.selectedModel[index].modelURN].modelId
              );
            if (toggeledModel)
              toggeledModel.visibilityManager.hide(
                modelHierarchy[props.selectedModel[index].modelURN].dbId,
                toggeledModel
              );
          }
        } else {
          if (
            props.selectedModel[index] &&
            props.selectedModel[index].modelURN &&
            modelHierarchy[props.selectedModel[index].modelURN]
          ) {
            let toggeledModel = viewer.impl
              .modelQueue()
              .findModel(
                modelHierarchy[props.selectedModel[index].modelURN].modelId
              );
            if (toggeledModel)
              toggeledModel.visibilityManager.show(
                modelHierarchy[props.selectedModel[index].modelURN].dbId,
                toggeledModel
              );
          }
        }
      }
    }
  }, [props.currentModels]);

  const findModel = (urn) => {
    let model = null;
    if (props.modelList) {
      model = props.modelList.find((item) => item.modelURN === urn);
    }
    return model;
  };

  const findFolder = (model) => {
    let index = -1;
    if (model) {
      if (props.folderModelList) {
        index = props.folderModelList.findIndex((item) =>
          item.includes(model.uniqueId)
        );
      }
    }
    return index;
  };

  const checkModel = (id) => {
    let model = null;
    if (props.selectedModel) {
      model = props.selectedModel.find((item) => item.uniqueId === id);
    }
    if (model) return model.modelURN;
    else return model;
  };

  //Check the URL if shared view or normal view and render model
  useEffect(() => {
    let url = window.location.pathname;
    if (!url.includes("/shared_viewer/")) {
      let urlParams = splitURN(url);
      let urlIds = urlParams.urlIds;
      let queryRefPoint = urlParams.queryRefPoint;

      let applyRefPoint = props.applyRefPointBool || queryRefPoint;
      Helpers.launchViewer("viewerDiv", urlIds, "0002", null, 1, applyRefPoint);
    } else {
      let applyRefPoint = window.location.search.includes("true")
        ? true
        : false;
      const param = documentId;
      setSharedView(true);
      checkViewerValidation(param)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "ExpiredID") {
              error("The link has expired. Please regenerate the link!");
              return;
            }
            localStorage.setItem("viewModelId", res.data.modelId);
            if (res.data.viewId) {
              let mid = res.data.modelId;
              try {
                mid = JSON.parse(res.data.modelId)[0];
              } catch (e) {
                mid = res.data.modelId;
              }
              getSavedView(mid, res.data.viewId).then((response) => {
                if (
                  response.status === 200 &&
                  response.data &&
                  response.data.length === 1
                ) {
                  openViewer(
                    res.status,
                    res.data.modelURN,
                    response.data[0].camera,
                    applyRefPoint
                  );
                } else {
                  error("This url doesn't have any saved view.");
                }
              });
            } else {
              openViewer(res.status, res.data.modelURN, null, applyRefPoint);
            }
          }
        })
        .catch((err) => {
          error("Something went wrong. Please try again");
        });
    }
  }, [documentId]);

  const openViewer = (res, urn, camera, applyRefPoint) => {
    if (res === 200) {
      let urnIds = urn.split("/");
      Helpers.launchViewer(
        "viewerDiv",
        urnIds,
        "0002",
        camera,
        2,
        applyRefPoint
      );
    } else {
      error("The link is not valid anymore");
    }
  };

  if (!documentId) {
    return null;
  }

  const error = (msg) => {
    toastr.error(msg);
  };

  const handleModelToggle = (index) => {
    let curModels = [...props.currentModels];
    curModels[index] = !curModels[index];
    if (
      !curModels[index] &&
      modelHierarchy[props.selectedModel[index].modelURN]
    ) {
      let toggeledModel = viewer.impl
        .modelQueue()
        .findModel(modelHierarchy[props.selectedModel[index].modelURN].modelId);
      if (toggeledModel)
        toggeledModel.visibilityManager.hide(
          modelHierarchy[props.selectedModel[index].modelURN].dbId,
          toggeledModel
        );
    } else {
      if (modelHierarchy[props.selectedModel[index].modelURN]) {
        let toggeledModel = viewer.impl
          .modelQueue()
          .findModel(
            modelHierarchy[props.selectedModel[index].modelURN].modelId
          );
        if (toggeledModel)
          toggeledModel.visibilityManager.show(
            modelHierarchy[props.selectedModel[index].modelURN].dbId,
            toggeledModel
          );
      }
    }
    let allModelsFlag = true;
    for (let i in curModels) {
      if (!curModels[i]) {
        allModelsFlag = false;
        break;
      }
    }
    setAllModelsSelected(allModelsFlag);
    dispatch(setCurrentModels(curModels));
  };

  const selectAllModels = (checked) => {
    let curModels = [...props.currentModels];
    if (checked) {
      if (curModels && curModels.length > 0) {
        for (let i in curModels) {
          curModels[i] = true;
        }
        setAllModelsSelected(true);
      }
    } else {
      if (curModels && curModels.length > 0) {
        for (let i in curModels) {
          curModels[i] = false;
        }
        setAllModelsSelected(false);
      }
    }
    dispatch(setCurrentModels(curModels));
  };

  const toggleModelList = () => {
    const buttonExelExportState = document.getElementById("show-form-button");
    if (document.getElementById("maindashcontainer") && buttonExelExportState) {
      document.getElementById("maindashcontainer").remove();
      buttonExelExportState.classList.remove("active");
      if (document.getElementById("colourToggleDiv"))
        document.getElementById("colourToggleDiv").style.display = "";
    } else {
      document.getElementsByClassName("adsk-viewing-viewer")[0].style.width =
        "100%";
    }
    setModelToggleOpen(!modelToggleOpen);
    if (viewer) {
      setTimeout(() => {
        viewer.resize();
        viewer.impl.invalidate();
      }, 10);
    }
  };

  const handleViewerToggle = (checked) => {
    const url = window.location.pathname;
    let urlParams = splitURN(url);
    let urlIds = urlParams.urlIds;

    urlIds.forEach((urn) => {
      if (modelHierarchy[urn]) {
        let toggeledModel = viewer.impl
          .modelQueue()
          .findModel(modelHierarchy[urn].modelId);
        if (toggeledModel) {
          viewer.clearThemingColors(toggeledModel);
        }
      }
    });
    if (checked) {
      props.templateId.forEach((temp, index) => {
        let curModelId = props.tempModelId[index];
        let modelUrn = checkModel(curModelId);
        let toggeledModel = null;
        if (modelHierarchy[modelUrn])
          toggeledModel = viewer.impl
            .modelQueue()
            .findModel(modelHierarchy[modelUrn].modelId);

        if (toggeledModel && props.propertiesValuesList[temp]) {
          toggeledModel.getObjectTree(function (objectTree) {
            props.propertiesValuesList[temp].forEach((item) => {
              if (objectTree.getChildCount(parseInt(item.id)) === 0) {
                item.values.forEach(function (itemValue) {
                  if (
                    itemValue &&
                    itemValue.color &&
                    itemValue.color.rgb &&
                    itemValue.toggle
                  ) {
                    const r = itemValue.color.rgb.r / 255;
                    const g = itemValue.color.rgb.g / 255;
                    const b = itemValue.color.rgb.b / 255;
                    var themeColor = new Window.THREE72.Vector4(r, g, b, 1);
                    if (toggeledModel)
                      toggeledModel.setThemingColor(
                        parseInt(item.id),
                        themeColor,
                        toggeledModel
                      );
                  }
                });
              }
            });
          });
          viewer.impl.invalidate(true);
        }
      });
      props.multipleTemplateId.forEach((temp, index) => {
        if (props.propertiesValuesList[temp]) {
          props.propertiesValuesList[temp].forEach((item) => {
            let modelUrn = checkModel(item.modelId);

            let toggeledModel = null;
            if (modelHierarchy[modelUrn])
              toggeledModel = viewer.impl
                .modelQueue()
                .findModel(modelHierarchy[modelUrn].modelId);
            if (toggeledModel) {
              toggeledModel.getObjectTree(function (objectTree) {
                if (objectTree.getChildCount(parseInt(item.id)) === 0) {
                  item.values.forEach((itemValue) => {
                    if (
                      itemValue &&
                      itemValue.color &&
                      itemValue.color.rgb &&
                      itemValue.toggle
                    ) {
                      let r = itemValue.color.rgb.r / 255;
                      let g = itemValue.color.rgb.g / 255;
                      let b = itemValue.color.rgb.b / 255;

                      var themeColor = new Window.THREE72.Vector4(r, g, b, 1);
                      if (toggeledModel)
                        toggeledModel.setThemingColor(
                          parseInt(item.id),
                          themeColor,
                          toggeledModel
                        );
                    }
                  });
                }
              });
            }
          });
          viewer.impl.invalidate(true);
        }
      });
      viewer.setDisplayEdges(true);
    }
  };

  return (
    <Fragment>
      <Box
        mt={3}
        className={
          isIOS && isMobile
            ? classes.iosViewerContainer
            : modelToggleOpen && !sharedView
            ? classes.drawerViewerContainer
            : classes.viewerContainer
        }
      >
        <div id="snackbar"></div>
        <div id="customsnackbar"></div>
        {!sharedView && (
          <Fragment>
            {modelToggleOpen ? (
              <div
                id="colourToggleDiv"
                style={
                  isBrowser
                    ? {
                        position: "absolute",
                        zIndex: "2",
                        right: "20%",
                        top: "70px",
                      }
                    : isTablet
                    ? {
                        position: "absolute",
                        zIndex: "2",
                        right: "32%",
                        top: "70px",
                      }
                    : isLandscape
                    ? {
                        position: "absolute",
                        zIndex: "2",
                        right: "33%",
                        top: "70px",
                      }
                    : {
                        position: "absolute",
                        zIndex: "2",
                        right: "55%",
                        top: "70px",
                      }
                }
              >
                <IconButton aria-label="Collapse" color="primary">
                  <label
                    style={{
                      fontSize: "15px",
                      paddingRight: "10px",
                      marginBottom: "0px",
                    }}
                  >
                    Color{" "}
                  </label>

                  <Switch
                    id="colourToggleSwitch"
                    color="primary"
                    disabled={viewerToggleEnabled}
                    onChange={(event) =>
                      handleViewerToggle(event.target.checked)
                    }
                  />
                </IconButton>
              </div>
            ) : (
              <div
                id="colourToggleDiv"
                style={
                  isBrowser
                    ? {
                        position: "absolute",
                        zIndex: "2",
                        right: "0%",
                        top: "70px",
                      }
                    : isTablet
                    ? {
                        position: "absolute",
                        zIndex: "2",
                        right: "3%",
                        top: "70px",
                      }
                    : isLandscape
                    ? {
                        position: "absolute",
                        zIndex: "2",
                        right: "5%",
                        top: "70px",
                      }
                    : {
                        position: "absolute",
                        zIndex: "2",
                        right: "5%",
                        top: "70px",
                      }
                }
              >
                <IconButton aria-label="Collapse" color="primary">
                  <label
                    className="colorForMobile"
                    style={{
                      fontSize: "15px",
                      paddingRight: "10px",
                      marginBottom: "0px",
                    }}
                  >
                    Color{" "}
                  </label>

                  <Switch
                    id="colourToggleSwitch"
                    color="primary"
                    disabled={viewerToggleEnabled}
                    onChange={(event) =>
                      handleViewerToggle(event.target.checked)
                    }
                  />
                </IconButton>
              </div>
            )}
          </Fragment>
        )}
        {!isMobile ? (
          <div
            className={
              modelToggleOpen && !sharedView
                ? classes.drawerViewer
                : classes.viewer
            }
            id="viewerDiv"
          >
            <div className="floorPlan" id="floorPlan"></div>
          </div>
        ) : isTablet ? (
          <div
            className={
              modelToggleOpen && !sharedView
                ? classes.landScapeViewer
                : classes.landScapeViewerDrawer
            }
            id="viewerDiv"
          >
            <div className="floorPlan" id="floorPlan"></div>
          </div>
        ) : isIOS ? (
          isLandscape ? (
            <div
              className={
                modelToggleOpen && !sharedView
                  ? classes.landScapeViewer
                  : classes.landScapeViewerDrawer
              }
              id="viewerDiv"
            >
              <div className="floorPlan" id="floorPlan"></div>
            </div>
          ) : (
            <div
              className={
                modelToggleOpen && !sharedView
                  ? classes.iosViewer
                  : classes.iosViewerDrawer
              }
              id="viewerDiv"
            >
              <div className="floorPlan" id="floorPlan"></div>
            </div>
          )
        ) : isLandscape ? (
          <div
            className={
              modelToggleOpen && !sharedView
                ? classes.landScapeViewer
                : classes.landScapeViewerDrawer
            }
            id="viewerDiv"
          >
            <div className="floorPlan" id="floorPlan"></div>
          </div>
        ) : (
          <div
            className={
              modelToggleOpen && !sharedView
                ? classes.androidViewer
                : classes.androidViewerDrawer
            }
            id="viewerDiv"
          >
            <div className="floorPlan" id="floorPlan"></div>
          </div>
        )}
        {!sharedView ? (
          <>
            {modelToggleOpen &&
            props.selectedModel &&
            props.currentModels &&
            props.selectedModel.length ? (
              <div
                style={
                  isLandscape
                    ? {
                        width: "30%",
                        height: "100%",
                        float: "right",
                        overflowY: "scroll",
                      }
                    : isTablet
                    ? {
                        width: "30%",
                        height: "100%",
                        float: "right",
                        overflowY: "scroll",
                      }
                    : isBrowser
                    ? {
                        width: "20%",
                        height: "100%",
                        float: "right",
                        overflowY: "scroll",
                      }
                    : {
                        width: "50%",
                        height: "100%",
                        float: "right",
                        overflowY: "scroll",
                      }
                }
              >
                <List style={{ paddingTop: "0px" }}>
                  <ListItem
                    style={{
                      position: "sticky",
                      zIndex: "1",
                      top: "0px",
                      background: "white",
                      borderBottom: "0.2px solid #999",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={allModelsSelected}
                        onChange={(event) =>
                          selectAllModels(event.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                        color="primary"
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        padding: "3px",
                        fontSize: "0.9rem",
                      }}
                    >
                      Select All
                    </ListItemText>
                  </ListItem>
                  <div style={{ paddingLeft: "5px" }}>
                    {/* <Divider variant="middle" /> */}
                    {props.selectedModel.map((model, index) => {
                      return (
                        <List>
                          <ListItem>
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  props.currentModels[index] ? true : false
                                }
                                onChange={() => handleModelToggle(index)}
                                inputProps={{ "aria-label": "controlled" }}
                                size="small"
                                color="primary"
                              />
                            </ListItemIcon>{" "}
                            <ListItemText
                              style={{
                                padding: "3px",
                                fontSize: "0.9rem",
                                overflowWrap: "break-word",
                              }}
                            >
                              {model.displayName}{" "}
                            </ListItemText>
                          </ListItem>
                          {/* <Divider variant="middle" /> */}
                        </List>
                      );
                    })}
                  </div>
                </List>
              </div>
            ) : (
              <></>
            )}

            {modelToggleOpen ? (
              <div
                id="modelListShowDivIcon"
                style={
                  isBrowser
                    ? {
                        position: "absolute",
                        zIndex: "1",
                        right: "20%",
                        top: "40%",
                      }
                    : isTablet
                    ? {
                        position: "absolute",
                        zIndex: "1",
                        right: "30%",
                        top: "40%",
                      }
                    : isLandscape
                    ? {
                        position: "absolute",
                        zIndex: "1",
                        right: "30%",
                        top: "40%",
                      }
                    : {
                        position: "absolute",
                        zIndex: "1",
                        right: "50%",
                        top: "40%",
                      }
                }
                onClick={() => toggleModelList()}
              >
                <IconButton aria-label="Collapse" color="primary">
                  <ChevronRightIcon style={{ fontSize: 40 }} />
                </IconButton>
              </div>
            ) : (
              <div
                id="modelListShowDivIcon2"
                style={{
                  position: "absolute",
                  zIndex: "1",
                  right: "0%",
                  top: "40%",
                }}
                onClick={() => toggleModelList()}
              >
                <IconButton aria-label="Collapse" color="primary">
                  <ChevronLeftIcon style={{ fontSize: 40 }} />
                </IconButton>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const { modelList, selectedModel, currentModels, applyRefPointBool } =
    state.models;

  const {
    templateId,
    templateName,
    templateProperties,
    propertiesValuesList,
    propertyValuesFetched,
    templatesFetched,
    tempModelId,
    multipleTempModelId,
    multipleTemplateId,
    multipleTemplateName,
    multipleTemplateProperties,
    fetchCustomProperties,
  } = state.customProperties;
  const { folderId, selectedFolder, fetchFoldersStatus, folderModelList } =
    state.folders;
  return {
    modelList,
    selectedModel,
    currentModels,
    applyRefPointBool,
    templateId,
    templateName,
    templateProperties,
    propertiesValuesList,
    propertyValuesFetched,
    templatesFetched,
    tempModelId,
    multipleTempModelId,
    multipleTemplateId,
    multipleTemplateName,
    multipleTemplateProperties,
    fetchCustomProperties,
    folderId,
    selectedFolder,
    fetchFoldersStatus,
    folderModelList,
  };
};

export default connect(mapStateToProps, {
  getCustomProperties,
  getTemplate,
  getFolders,
  setCurrentFolder,
  updateTranslationStatus,
})(withOrientationChange(Viewer));
