import axios from "axios";

const api = axios.create();

const splitURN = (url) => {
  let urlIds = url.slice(url.indexOf("viewer/"));
  urlIds = urlIds.split("/").splice(1);

  let queryRefPoint = false;
  if (window.location.search) {
    queryRefPoint = window.location.search.includes("true") ? true : false;
  }
  return { urlIds, queryRefPoint };
};

export { api, splitURN };
