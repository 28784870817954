import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { changeModelName } from "../../actions/modelsActions";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { getURN } from '../../services/apiHelper';
import { setDefault } from "../../actions/modelsActions";
import {toastr} from 'react-redux-toastr';
import { useDispatch, useSelector } from "react-redux";
import './style.scss';
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  fullWidth: {
      width: '100%'
  },
  modalBody: {
      padding: '2.5rem 1rem'
  } 
}));

const reNameModel = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { position, model } = props
    const { isRename, reNameResult, modelList } = useSelector((state) => state.models);
    
    
    const [title, setTitle] = useState("");
    const [snackBarOpen, setSnackBarOpen] = React.useState(true);

    useEffect(() => {
        setTitle(model.displayName)
    }, []);

    useEffect(() => {
        if(!isRename && reNameResult === 1) {
            clear();
        }
    }, [isRename])

    const handleRename = (e) => {
        e.preventDefault();
        if(!title) {
            error("Please input file name");
            return false;
        }
        if (modelList && modelList.some(e => e.DisplayName === title)) {
            const msg = "A file with the same name already exists so I cannot be renamed";
            error(msg);
            return false;
        }
        if(model.modelURN.toLowerCase() === 'null') {
            getURN(model.DisplayName).then(res => {
                if(res.data && res.data.LoadingStatus === "Loaded") {
                    dispatch(changeModelName(res.data, title, position));
                } else {
                  error("File is still being uploaded");
                }
              })
              .catch(err => {
                error("Something went wrong. Please try again");
              })
        } else {
            dispatch(changeModelName(model, title, position));
        }
    }

    const clear = () => {
        props.onHide();
        setTitle(model.displayName);
        setSnackBarOpen(true);
        dispatch(setDefault());
    }

    const showSnackBar = () => {
        if(reNameResult === 1) {
            return (
                <Snackbar open={snackBarOpen} autoHideDuration={5000} onClose={handleSnackBarClose}>
                    <Alert variant="filled" severity="success">
                        File has been renamed successfully!
                    </Alert>
                </Snackbar>
            )
        }
    }

    const error = (msg) => {
        toastr.error(msg);
      }

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    return(
        <React.Fragment>
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Rename
                </Modal.Title>
            </Modal.Header>
            <form className={classes.root} noValidate autoComplete="off" onSubmit={(e) => handleRename(e)}>
                <Modal.Body className={classes.modalBody}>
                    <TextField
                        className={classes.fullWidth}
                        name="newName"
                        required={true}
                        id="outlined-required"
                        label="Rename"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        variant="outlined"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" onClick={() => clear()} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        color={isRename ? "inherit" : "primary"}
                        type="submit"
                        variant="contained"
                        autoFocus
                        disabled={isRename}
                    >
                      {isRename ?  <CircularProgress color="secondary"/> : "RENAME"}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
        {reNameResult === 1 && (
            showSnackBar()
        )}
        </React.Fragment>
    )
}

export default reNameModel;