import VisualClusterPanel from "./clusterExtension/reactPanel";
const Autodesk = window.Autodesk;

function visualClusterExtension(viewer, options) {
  Autodesk.Viewing.Extension.call(this, viewer, options);
}

visualClusterExtension.prototype = Object.create(
  Autodesk.Viewing.Extension.prototype
);
visualClusterExtension.prototype.constructor = visualClusterExtension;

visualClusterExtension.prototype.load = function () {
  return true;
};

visualClusterExtension.prototype.onToolbarCreated = function (toolbar) {
  var viewer = this.viewer;
  let clusterPanel = null;

  viewer.addEventListener(Autodesk.Viewing.TRANSITION_STARTED, () => {
    let _group = viewer.toolbar.getControl("modelTools");
    let extensionId = -1;
    for (let controlId in _group._controls) {
      if (_group._controls[controlId]._id === "toolbar-clusterTool") {
        extensionId = controlId;
        break;
      }
    }
    if (!clusterPanel) {
      clusterPanel = new VisualClusterPanel(viewer, {
        id: "react-panel-id",
        title: "Visual Cluster Parameters",
      });
      clusterPanel.setVisible(!clusterPanel.isVisible());
    } else if (
      !clusterPanel.isVisible() &&
      !_group._controls[extensionId]._state
    ) {
      clusterPanel.setVisible(true);
    }
  });
};

visualClusterExtension.prototype.unload = function () {
  return true;
};

Autodesk.Viewing.theExtensionManager.registerExtension(
  "visualClusterExtension",
  visualClusterExtension
);

export default visualClusterExtension;
