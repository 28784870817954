import { produce } from "immer";
import {
  GET_VIEWS_LIST,
  GET_VIEWS_LIST_SUCCESS,
  GET_VIEWS_LIST_FAILED,
  SAVE_VIEW,
  SAVE_VIEW_SUCCESS,
  SAVE_VIEW_FAILED,
  DELETE_VIEW,
  DELETE_VIEW_SUCCESS,
  DELETE_VIEW_FAILED,
  UPDATE_VIEW,
  UPDATE_VIEW_SUCCESS,
  UPDATE_VIEW_FAILED,
  START_RENAME,
  FINISH_RENAME,
} from "../actions/savedViewAction";

const initialState = {
  viewsList: undefined,
  getApi: 0,
  saveApi: -1,
  deleting: -1,
  updating: -1,
  updatingType: 0,
  renamingStatus: 0,
};

const savedViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_RENAME: {
      return produce(state, (draft) => {
        draft.renamingStatus = 0;
      });
    }
    case FINISH_RENAME: {
      return produce(state, (draft) => {
        draft.renamingStatus = 1;
      });
    }
    case GET_VIEWS_LIST: {
      return produce(state, (draft) => {
        draft.viewsList = undefined;
        draft.getApi = 0;
      });
    }
    case GET_VIEWS_LIST_SUCCESS: {
      return produce(state, (draft) => {
        if (draft.viewsList) {
          for (let i in action.payload) {
            let index = draft.viewsList.findIndex(
              (item) => item.uniqueId === action.payload[i].uniqueId
            );
            if (index < 0) {
              draft.viewsList.push(action.payload[i]);
            }
          }
        } else {
          draft.viewsList = [];
          for (let i in action.payload) {
            let index = draft.viewsList.findIndex(
              (item) => item.uniqueId === action.payload[i].uniqueId
            );
            if (index < 0) {
              draft.viewsList.push(action.payload[i]);
            }
          }
        }
        draft.getApi = 1;
      });
    }
    case GET_VIEWS_LIST_FAILED: {
      return produce(state, (draft) => {
        draft.getApi = 1;
      });
    }
    case SAVE_VIEW: {
      return produce(state, (draft) => {
        draft.saveApi = 0;
      });
    }
    case SAVE_VIEW_SUCCESS: {
      return produce(state, (draft) => {
        if (draft.viewsList) {
          draft.viewsList.push(action.payload);
        } else {
          draft.viewsList = [action.payload];
        }
        draft.saveApi = 1;
      });
    }
    case SAVE_VIEW_FAILED: {
      return produce(state, (draft) => {
        draft.saveApi = 1;
      });
    }
    case DELETE_VIEW: {
      return produce(state, (draft) => {
        draft.deleting = action.payload;
      });
    }
    case DELETE_VIEW_SUCCESS: {
      return produce(state, (draft) => {
        let views = draft.viewsList;
        views.splice(action.payload, 1);
        draft.viewsList = views;
        draft.deleting = -1;
      });
    }
    case DELETE_VIEW_FAILED: {
      return produce(state, (draft) => {
        draft.deleting = -1;
      });
    }
    case UPDATE_VIEW: {
      return produce(state, (draft) => {
        draft.updating = action.payload;
        draft.updatingType = action.updatingType;
      });
    }
    case UPDATE_VIEW_SUCCESS: {
      return produce(state, (draft) => {
        draft.viewsList[action.index] = action.payload;
        draft.updating = -1;
        draft.updatingType = 0;
        draft.renamingStatus = 1;
      });
    }
    case UPDATE_VIEW_FAILED: {
      return produce(state, (draft) => {
        draft.updating = -1;
        draft.updatingType = 0;
        draft.renamingStatus = 1;
      });
    }
    default: {
      return state;
    }
  }
};

export default savedViewerReducer;
