import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, connect } from "react-redux";
import { getModelList } from "../../../../actions/modelsActions";
import {
  getTemplate,
  addCustomProperty,
  getCustomProperties,
  updateCustomProperties,
} from "../../../../actions/customParameterActions";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Switch,
  FormControl,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { splitURN } from "../../../../utils/api";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import { modelHierarchy } from "../../Viewer-helpers";
import ColorPickerComponent from "../../colourExtension/ColorPickerComponent";
import {
  getFolders,
  setCurrentFolder,
} from "../../../../actions/folderActions";
import CircularProgress from "@material-ui/core/CircularProgress";
// import AWS from "aws-sdk";

const Autodesk = window.Autodesk;

let selectedColour = null,
  existingColour = null,
  selectedPropIndex = -1;

const CustomProperties = (props) => {
  const dispatch = useDispatch();
  const blankString = "<set blank>";
  const [selectedTemplate, setSelectedTemplate] = useState(-1);
  const [selectedMultiTemplate, setSelectedMultiTemplate] = useState(-1);
  const [modelTemplates, setModelTemplates] = useState([]);
  const [multiModelTemplates, setMultiModelTemplates] = useState([]);
  const [selectedModelId, setSelectedModelId] = useState(undefined);
  const [propertyValues, setPropertyValues] = useState([]);
  const [valuesChanged, setValuesChanged] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState(null);
  const [entityExists, setEntityExists] = useState(false);
  const [entityName, setEntityName] = useState("");
  const [sharedView, setSharedView] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [toggleChanged, setToggleChanged] = useState(false);
  const [propertyToggle, setPropertyToggle] = useState([]);
  const [colorsChanged, setColorsChanged] = useState(false);
  const [propertyColor, setPropertyColor] = useState([]);

  const [selectedEntityURN, setSelectedEntityURN] = useState(null);
  const [multiModelEntitiesSelected, setMultiModelEntitiesSelected] =
    useState(false);
  const [eventListenerAdded, setEventListenerAdded] = useState(false);
  const [suggestedValues, setSuggestedValues] = useState([]);
  const [colorCheckDialog, setColorCheckDialog] = useState(false);
  const [replaceColorsPrompt, setReplaceColorsPrompt] = useState(-1);

  useEffect(() => {
    if (!eventListenerAdded) {
      props.viewer.addEventListener(
        Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
        () => {
          selectionChanged();
        }
      );
      setEventListenerAdded(true);
    }
    if (!props.modelList) {
      if (window.location.href.includes("shared_viewer")) {
        setSharedView(true);
        setInputDisabled(true);
        let viewModelId = localStorage.getItem("viewModelId");
        setSelectedModelId(viewModelId);
        if (props.templateProperties.length === 0) {
          dispatch(getTemplate(viewModelId));
          dispatch(getCustomProperties(viewModelId));
        }
      } else {
        props.getModelList(localStorage.getItem("subId"));
      }
    }
    if (!props.folderId.length) {
      dispatch(getFolders(localStorage.getItem("subId")));
    }

    //if entities from more than 1 model are selected set multi model template as active.
    let selectedObj = props.viewer.impl.selector.getAggregateSelection();
    setEntitiesInformation(selectedObj);
  }, []);

  const selectionChanged = () => {
    let selectedObj = props.viewer.impl.selector.getAggregateSelection();
    if (selectedObj.length > 0) {
      setEntitiesInformation(selectedObj);
    }
  };

  const setEntitiesInformation = (selectedObj) => {
    if (selectedObj && selectedObj.length > 1) {
      setMultiModelEntitiesSelected(true);
    } else {
      setMultiModelEntitiesSelected(false);
    }
    setSelectedTemplate(-1);
    setSelectedMultiTemplate(-1);

    //set selected entities
    let entitiesSelected = [];
    entitiesSelected = selectedObj.map((obj) => {
      return obj.selection;
    });
    setSelectedEntities(entitiesSelected);

    //set urn's for all selected entities
    let entitiesURN = [];
    entitiesURN = selectedObj.map((obj) => {
      return String(obj.model.myData.urn);
    });
    setSelectedEntityURN(entitiesURN);

    //if no. of selected entities is 1, then set the entity name for modal
    if (
      selectedObj &&
      selectedObj.length === 1 &&
      selectedObj[0].selection.length === 1
    ) {
      let selectedEntityName;
      selectedEntityName = checkChildren(
        selectedObj[0].selection[0],
        selectedObj[0].model.myData.urn
      );
      setEntityName(selectedEntityName);
      props.panel.setTitle(selectedEntityName);
    } else {
      setEntityName("Custom Properties");
      props.panel.setTitle("Custom Properties");
    }
  };

  useEffect(() => {
    if (!sharedView) {
      let selectedMIds = [];
      const url = window.location.pathname;
      let urlParams = splitURN(url);
      let urlIds = urlParams.urlIds;

      urlIds.forEach((urn, index) => {
        let model = findModel(urn);
        if (model) {
          //Add model Ids for all selected entities
          if (selectedEntityURN && selectedEntityURN.includes(model.modelURN)) {
            selectedMIds.push(model.uniqueId);
          }
          //fetch templates and custom properties for loaded models
          if (
            props.templateProperties.length === 0 &&
            props.propertyValuesFetched < 0
          ) {
            dispatch(getTemplate(model.uniqueId));
            dispatch(getCustomProperties(model.uniqueId));
          }
        }
        //set all the modelids for selected entities
        setSelectedModelId(selectedMIds);
      });

      if (props.fetchFoldersStatus) {
        let model = findModel(urlIds[0]);
        //set the current folder
        if (model) {
          dispatch(setCurrentFolder(findFolder(model)));
        }
        let selectFolder = parseInt(props.selectedFolder);
        if (selectFolder > -1) {
          //fetch multi model template by passing the folder id
          dispatch(getTemplate(props.folderId[selectFolder], true));
        }
      }
    }
  }, [
    props.modelList,
    selectedEntityURN,
    props.fetchFoldersStatus,
    props.selectedFolder,
  ]);

  useEffect(() => {
    let temp = [];
    for (let i in props.templateProperties) {
      temp.push({
        tempModelId: props.tempModelId[i],
        templateId: props.templateId[i],
        name: props.templateName[i],
        template: props.templateProperties[i],
      });
    }
    setModelTemplates(temp);
  }, [props.templateProperties]);

  useEffect(() => {
    let temp = [];
    for (let i in props.multipleTemplateProperties) {
      temp.push({
        tempModelId: props.multipleTempModelId[i],
        templateId: props.multipleTemplateId[i],
        name: props.multipleTemplateName[i],
        template: props.multipleTemplateProperties[i],
      });
    }
    setMultiModelTemplates(temp);
  }, [props.multipleTemplateProperties]);

  useEffect(() => {
    if (selectedTemplate > -1) {
      setSelectedTemplateValues(selectedTemplate, false);
    }
    if (selectedMultiTemplate > -1) {
      setSelectedTemplateValues(selectedMultiTemplate, true);
    }
  }, [props.propertiesValuesList]);

  useEffect(() => {
    if (replaceColorsPrompt === 1) {
      let uniqueIdsList = [];
      let propertyObject = [];
      let tempId = "";
      if (selectedTemplate > -1) {
        tempId = props.templateId[selectedTemplate];
        if (props.propertiesValuesList[tempId]) {
          props.propertiesValuesList[tempId].forEach((item) => {
            if (
              item.values &&
              item.values[selectedPropIndex] &&
              item.values[selectedPropIndex].value ===
                propertyValues[selectedPropIndex] &&
              item.values[selectedPropIndex].color
            ) {
              uniqueIdsList.push(item.uniqueId);
              let temp = item.values;
              temp[selectedPropIndex].color = selectedColour;
              propertyObject.push(JSON.stringify(temp));
            }
          });
        }
      } else if (selectedMultiTemplate > -1) {
        tempId = props.multipleTemplateId[selectedMultiTemplate];
        if (props.propertiesValuesList[tempId]) {
          props.propertiesValuesList[tempId].forEach((item) => {
            if (
              item.values &&
              item.values[selectedPropIndex] &&
              item.values[selectedPropIndex].value ===
                propertyValues[selectedPropIndex] &&
              item.values[selectedPropIndex].color
            ) {
              uniqueIdsList.push(item.uniqueId);
              let temp = item.values;
              temp[selectedPropIndex].color = selectedColour;
              propertyObject.push(JSON.stringify(temp));
            }
          });
        }
      }
      if (uniqueIdsList.length && tempId !== "") {
        dispatch(updateCustomProperties(uniqueIdsList, propertyObject, tempId));
      }
      setColorsChanged(true);
      handleToggleChange(selectedPropIndex, true, selectedColour);
    } else if (replaceColorsPrompt === 0) {
      let tempColor = [...propertyColor];
      tempColor[selectedPropIndex] = existingColour;
      setPropertyColor(tempColor);
    }
    setReplaceColorsPrompt(-1);
  }, [replaceColorsPrompt]);

  const findModel = (urn) => {
    let model = null;
    if (props.modelList) {
      model = props.modelList.find((item) => item.modelURN === urn);
    }
    return model;
  };

  const findFolder = (model) => {
    let index = -1;
    if (model) {
      if (props.folderModelList) {
        index = props.folderModelList.findIndex((item) =>
          item.includes(model.uniqueId)
        );
      }
    }
    return index;
  };

  const checkModel = (id) => {
    let model = null;
    if (props.modelList) {
      model = props.modelList.find((item) => item.uniqueId === id);
    }
    return model;
  };

  const checkChildren = (id, urn) => {
    let recursiveChildren = (ob) => {
      for (let i in ob) {
        if (ob[i].dbId === id) {
          // foundName = ob[i].name + " [" + ob[i].externalId + "]";
          foundName = ob[i].name;
          break;
        }
        if (ob[i].children) {
          recursiveChildren(ob[i].children);
        }
      }
    };
    let foundName;
    let mh = modelHierarchy;
    if (mh[urn] && mh[urn].children) recursiveChildren(mh[urn].children);
    else if (Array.isArray(mh[urn])) {
      for (let j in mh[urn]) {
        recursiveChildren(mh[urn][j].children);
      }
    }
    return foundName;
  };

  const checkPreviousColour = (index, value) => {
    let setColour = "";
    if (selectedTemplate > -1) {
      let tempId = props.templateId[selectedTemplate];
      if (props.propertiesValuesList[tempId]) {
        props.propertiesValuesList[tempId].forEach((item) => {
          if (
            item.values &&
            item.values[index] &&
            item.values[index].value === value &&
            item.values[index].color
          ) {
            setColour = item.values[index].color;
          }
        });
      }
    } else if (selectedMultiTemplate > -1) {
      let tempId = props.multipleTemplateId[selectedMultiTemplate];
      if (props.propertiesValuesList[tempId]) {
        props.propertiesValuesList[tempId].forEach((item) => {
          if (
            item.values &&
            item.values[index] &&
            item.values[index].value === value &&
            item.values[index].color
          ) {
            setColour = item.values[index].color;
          }
        });
      }
    }
    return setColour;
  };

  const propertyValueChanged = (index, value) => {
    let temp = [...propertyValues];
    temp[index] = value;
    setPropertyValues(temp);
    if (value === blankString) {
      let colorTemp = [...propertyColor];
      let toggleTemp = [...propertyToggle];
      colorTemp[index] = "";
      toggleTemp[index] = false;
      setPropertyToggle(toggleTemp);
      setPropertyColor(colorTemp);
    }
    let existingColour = checkPreviousColour(index, value);
    if (existingColour !== "") {
      let tempColor = [...propertyColor];
      tempColor[index] = existingColour;
      setPropertyColor(tempColor);
      handleToggleChange(index, true, existingColour);
    }
    setValuesChanged(true);
  };

  const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  const getUniqueIds = (entities, modelId, multiModel = false) => {
    let tempId = -1;
    let uniqueIdsList = [];
    if (props.propertiesValuesList) {
      if (!multiModel && selectedTemplate > -1) {
        tempId = modelTemplates[selectedTemplate].templateId;
      } else {
        tempId = multiModelTemplates[selectedMultiTemplate].templateId;
      }
      entities.forEach((entity) => {
        let temp = null;
        if (props.propertiesValuesList[tempId]) {
          props.propertiesValuesList[tempId].map((element) => {
            if (element.id === entity && element.modelId === modelId)
              temp = element.uniqueId;
          });
        }
        if (temp !== null) {
          uniqueIdsList.push(temp);
        } else {
          uniqueIdsList.push(uuidv4());
        }
      });
    }
    return uniqueIdsList;
  };

  const matchURLRegEx = (inputUrl) => {
    var expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);

    if (inputUrl && inputUrl.match(regex)) {
      return true;
    } else {
      return false;
    }
  };

  const onPropertiesSave1 = () => {
    var x = document.getElementById("snackbar");
    x.innerText = "Please wait while the custom properties are being added..";
    x.className = "show";
    onPropertiesSave();
  };

  const onPropertiesSave = () => {
    setTimeout(() => {
      if (multiModelEntitiesSelected || selectedMultiTemplate > -1) {
        for (let i in selectedEntities) {
          let toggeledModel = props.viewer.impl
            .modelQueue()
            .findModel(modelHierarchy[selectedEntityURN[i]].modelId);
          if (toggeledModel) {
            toggeledModel.getObjectTree(function (objectTree) {
              let model = findModel(selectedEntityURN[i]);
              let stringSelectedEntities = [];
              stringSelectedEntities = selectedEntities[i]
                .filter((entity) => {
                  if (objectTree.getChildCount(entity) === 0) return entity;
                })
                .map((entity) => {
                  return String(entity);
                });
              let propertyObject2 = [];
              stringSelectedEntities.map((entity) => {
                let entityFound = false;
                if (
                  props.propertiesValuesList[
                    multiModelTemplates[selectedMultiTemplate].templateId
                  ]
                ) {
                  props.propertiesValuesList[
                    multiModelTemplates[selectedMultiTemplate].templateId
                  ].forEach((element) => {
                    if (
                      element.id === entity &&
                      element.modelId === model.uniqueId
                    ) {
                      entityFound = true;
                      let temp = multiModelTemplates[
                        selectedMultiTemplate
                      ].template.map((temp, index) => {
                        return {
                          id: temp.id,
                          value:
                            propertyValues[index] !== blankString &&
                            propertyValues[index] === undefined
                              ? element.values[index] &&
                                element.values[index].value !== ""
                                ? element.values[index].value
                                : ""
                              : propertyValues[index] === blankString
                              ? ""
                              : propertyValues[index],
                          color:
                            propertyValues[index] !== blankString
                              ? propertyColor[index] === undefined
                                ? element.values[index] &&
                                  element.values[index].color !== ""
                                  ? element.values[index].color
                                  : ""
                                : propertyColor[index]
                              : "",
                          toggle:
                            propertyToggle[index] === undefined
                              ? false
                              : propertyToggle[index],
                        };
                      });
                      propertyObject2.push(JSON.stringify(temp));
                    }
                  });
                }
                if (!entityFound) {
                  let temp = multiModelTemplates[
                    selectedMultiTemplate
                  ].template.map((temp, index) => {
                    return {
                      id: temp.id,
                      value:
                        propertyValues[index] === undefined
                          ? ""
                          : propertyValues[index],
                      color:
                        propertyColor[index] === undefined
                          ? ""
                          : propertyColor[index],
                      toggle:
                        propertyToggle[index] === undefined
                          ? false
                          : propertyToggle[index],
                    };
                  });
                  propertyObject2.push(JSON.stringify(temp));
                }
              });

              dispatch(
                addCustomProperty(
                  getUniqueIds(stringSelectedEntities, model.uniqueId, true),
                  model.uniqueId,
                  multiModelTemplates[selectedMultiTemplate].templateId,
                  stringSelectedEntities,
                  propertyObject2
                )
              );
            });
          }
        }
      } else {
        props.viewer.getObjectTree(function (objectTree) {
          let stringSelectedEntities = [];
          stringSelectedEntities = selectedEntities[0]
            .filter((entity) => {
              if (objectTree.getChildCount(entity) === 0) return entity;
            })
            .map((entity) => {
              return String(entity);
            });

          let propertyObject2 = [];
          stringSelectedEntities.map((entity) => {
            let entityFound = false;
            if (
              props.propertiesValuesList[
                modelTemplates[selectedTemplate].templateId
              ]
            ) {
              props.propertiesValuesList[
                modelTemplates[selectedTemplate].templateId
              ].forEach((element) => {
                if (element.id === entity) {
                  entityFound = true;
                  let temp = modelTemplates[selectedTemplate].template.map(
                    (temp, index) => {
                      return {
                        id: temp.id,
                        value:
                          propertyValues[index] !== blankString &&
                          propertyValues[index] === undefined
                            ? element.values[index] &&
                              element.values[index].value !== ""
                              ? element.values[index].value
                              : ""
                            : propertyValues[index] === blankString
                            ? ""
                            : propertyValues[index],
                        color:
                          propertyValues[index] !== blankString
                            ? propertyColor[index] === undefined
                              ? element.values[index] &&
                                element.values[index].color !== ""
                                ? element.values[index].color
                                : ""
                              : propertyColor[index]
                            : "",
                        toggle:
                          propertyToggle[index] === undefined
                            ? false
                            : propertyToggle[index],
                      };
                    }
                  );
                  propertyObject2.push(JSON.stringify(temp));
                }
              });
            }
            if (!entityFound) {
              let temp = modelTemplates[selectedTemplate].template.map(
                (temp, index) => {
                  return {
                    id: temp.id,
                    value:
                      propertyValues[index] === undefined
                        ? ""
                        : propertyValues[index],
                    color:
                      propertyColor[index] === undefined
                        ? ""
                        : propertyColor[index],
                    toggle:
                      propertyToggle[index] === undefined
                        ? false
                        : propertyToggle[index],
                  };
                }
              );
              propertyObject2.push(JSON.stringify(temp));
            }
          });
          dispatch(
            addCustomProperty(
              getUniqueIds(
                stringSelectedEntities,
                modelTemplates[selectedTemplate].tempModelId
              ),
              modelTemplates[selectedTemplate].tempModelId,
              modelTemplates[selectedTemplate].templateId,
              stringSelectedEntities,
              propertyObject2
            )
          );
        });
      }
      setValuesChanged(false);
      setToggleChanged(false);
      setColorsChanged(false);
    }, 0);
  };

  const setSelectedTemplateValues = (index, multiModel = false) => {
    if (props.propertiesValuesList) {
      let tempId = null;
      if (multiModel) {
        tempId = multiModelTemplates[index].templateId;
        setSelectedMultiTemplate(index);

        let tempSuggested = [];
        let suggested = [];
        multiModelTemplates[index].template.forEach((prop, pid) => {
          if (
            props.propertiesValuesList[tempId] &&
            props.propertiesValuesList[tempId].length
          ) {
            tempSuggested[pid] = props.propertiesValuesList[tempId].map(
              (item) => {
                if (item.values[pid] !== undefined)
                  return item.values[pid].value;
                else return "";
              }
            );
            suggested[pid] = [];
            tempSuggested[pid].forEach((val) => {
              if (val !== "" && !suggested[pid].includes(val))
                suggested[pid].push(val);
            });
            if (!suggested[pid].includes(blankString))
              suggested[pid].push(blankString);
          }
        });

        setSuggestedValues(suggested);
      } else {
        tempId = modelTemplates[index].templateId;
        setSelectedTemplate(index);

        let tempSuggested = [];
        let suggested = [];
        modelTemplates[index].template.forEach((prop, pid) => {
          if (
            props.propertiesValuesList[tempId] &&
            props.propertiesValuesList[tempId].length
          ) {
            tempSuggested[pid] = props.propertiesValuesList[tempId].map(
              (item) => {
                if (item.values[pid] !== undefined)
                  return item.values[pid].value;
                else return "";
              }
            );
            suggested[pid] = [];
            tempSuggested[pid].forEach((val) => {
              if (val !== "" && !suggested[pid].includes(val))
                suggested[pid].push(val);
            });
            if (!suggested[pid].includes(blankString))
              suggested[pid].push(blankString);
          }
        });
        setSuggestedValues(suggested);
      }
      let propValues = [];
      let propColors = [];
      let propToggled = [];
      if (
        selectedEntities &&
        selectedEntities.length === 1 &&
        selectedEntities[0].length === 1
      ) {
        let model = findModel(selectedEntityURN[0]);
        for (let i in props.propertiesValuesList[tempId]) {
          if (
            props.propertiesValuesList[tempId] &&
            props.propertiesValuesList[tempId][i] &&
            props.propertiesValuesList[tempId][i].id ===
              String(selectedEntities[0][0]) &&
            props.propertiesValuesList[tempId][i].modelId === model.uniqueId
          ) {
            propValues = props.propertiesValuesList[tempId][i].values.map(
              (val) => {
                return val.value;
              }
            );
            propColors = props.propertiesValuesList[tempId][i].values.map(
              (color) => {
                return color.color;
              }
            );
            propToggled = props.propertiesValuesList[tempId][i].values.map(
              (toggle) => {
                return toggle.toggle;
              }
            );
          }
        }
      }
      setPropertyValues(propValues);
      setPropertyColor(propColors);
      setPropertyToggle(propToggled);
    }
  };

  const listTemplatesUI = () => {
    return modelTemplates.map((template, index) => {
      return (
        <div key={index}>
          {selectedModelId && selectedModelId.includes(template.tempModelId) ? (
            <List>
              <ListItem>
                <AssignmentIcon
                  className={"cursor"}
                  style={{ fontSize: 20, marginRight: "15px" }}
                />
                <ListItemText
                  primary={template.name}
                  onClick={() => {
                    setSelectedTemplateValues(index);
                  }}
                />
              </ListItem>
            </List>
          ) : (
            <div key={index}></div>
          )}
        </div>
      );
    });
  };

  const listMultiTemplatesUI = () => {
    return multiModelTemplates.map((template, index) => {
      return (
        <div key={index}>
          <List>
            <ListItem>
              <AssignmentIcon
                className={"cursor"}
                style={{ fontSize: 20, marginRight: "15px" }}
              />
              <ListItemText
                primary={template.name}
                onClick={() => setSelectedTemplateValues(index, true)}
              />
            </ListItem>
          </List>
        </div>
      );
    });
  };

  const showCustomPropertiesUI = () => {
    let template = null;
    if (selectedTemplate > -1) {
      template = modelTemplates[selectedTemplate];
    } else {
      template = multiModelTemplates[selectedMultiTemplate];
    }
    return (
      <>
        <div
          style={{
            display: "table",
            paddingLeft: "13px",
            paddingBottom: "0px",
            paddingTop: "0px",
            width: "100%",
            height: "35px",
            minWidth: "400px",
          }}
        >
          <div
            className="propertyHeadingwrapper"
            style={{
              color: "f3f7fb",
              fontSize: "14px",
              fontWeight: "600",
              display: "table-cell",
              paddingLeft: "12px",
              maxWidth: "10px",
              width: "40%",
              verticalAlign: "middle",
            }}
          >
            Property
          </div>
          <div
            className="propertyHeadingGap"
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              width: "1px",
            }}
          ></div>

          <div
            className="valueHeadingWrapeer"
            style={{
              background: "transparent",
              border: "1px #e4e9ee",
              display: "table-cell",
              color: "#e4e9ee",
              fontWeight: "400",
              height: "100%",
              paddingLeft: "10px",
              position: "relative",
              verticalAlign: "middle",
              width: "40%",
            }}
          >
            {" "}
            Value
          </div>
          <div
            className="propertyHeadingGap"
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              width: "1px",
            }}
          ></div>
          <div
            style={{
              background: "transparent",
              border: "1px #e4e9ee",
              display: "table-cell",
              color: "#e4e9ee",
              fontWeight: "400",
              height: "100%",
              paddingRight: "30px",
              position: "relative",
              verticalAlign: "middle",
              width: "20%",
            }}
          >
            {" "}
            Color
          </div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              width: "1px",
            }}
          ></div>
        </div>
        {template &&
          template.template &&
          template.template.map((tempName, index) => {
            return (
              <div
                style={{
                  display: "table",
                  paddingLeft: "13px",
                  paddingBottom: "0px",
                  paddingTop: "0px",
                  width: "100%",
                  height: "35px",
                }}
              >
                <div
                  style={{
                    color: "f3f7fb",
                    fontSize: "14px",
                    fontWeight: "600",
                    display: "table-cell",
                    paddingLeft: "12px",
                    maxWidth: "10px",
                    width: "30%",
                    overflow: "hidden",
                    verticalAlign: "middle",
                  }}
                >
                  {tempName.propertyName}
                </div>
                <div
                  className="valuePaddingLeft"
                  style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                    width: "1px",
                    paddingLeft: "10px",
                  }}
                ></div>

                {inputDisabled ? (
                  <>
                    <div
                      style={{
                        background: "transparent",
                        border: "1px solid #e4e9ee",
                        display: "table-cell",
                        color: "#e4e9ee",
                        fontWeight: "400",
                        height: "100%",
                        paddingLeft: "20px",
                        position: "relative",
                        verticalAlign: "middle",
                        width: "40%",
                      }}
                    >
                      {matchURLRegEx(propertyValues[index]) ? (
                        <a
                          href={propertyValues[index]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {propertyValues[index]}
                        </a>
                      ) : propertyValues[index] === blankString ? (
                        ""
                      ) : (
                        propertyValues[index]
                      )}
                    </div>
                    {!sharedView ? (
                      <EditIcon
                        style={{
                          color: "#e4e9ee",
                          fontSize: 20,
                          paddingRight: "5px",
                        }}
                        onClick={() => setInputDisabled(false)}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      list={"customPropertiesList" + index}
                      value={
                        propertyValues[index] === blankString
                          ? ""
                          : propertyValues[index]
                      }
                      className="valueBlockWrapper"
                      style={{
                        background: "transparent",
                        border: "1px solid #e4e9ee",
                        display: "table-cell",
                        color: "#e4e9ee",
                        fontWeight: "400",
                        height: "100%",
                        paddingLeft: "5px",
                        position: "relative",
                        verticalAlign: "middle",
                        width: "60%",
                      }}
                      disabled={inputDisabled}
                      onChange={(event) =>
                        propertyValueChanged(index, event.target.value)
                      }
                    />
                    <datalist id={"customPropertiesList" + index}>
                      {suggestedValues[index] &&
                        suggestedValues[index].map((item) => {
                          return <option value={item} />;
                        })}
                    </datalist>

                    {!sharedView ? (
                      <EditIcon
                        style={{
                          color: "#e4e9ee",
                          fontSize: 20,
                          paddingRight: "0px",
                        }}
                        onClick={() => setInputDisabled(false)}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}

                <div
                  style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                    width: "1px",
                  }}
                ></div>
                <div
                  style={{
                    background: "transparent",
                    display: "table-cell",
                    height: "100%",
                    paddingLeft: "5%",
                    position: "relative",
                    verticalAlign: "middle",
                    width: "10%",
                  }}
                >
                  <ColorPickerComponent
                    style={{ paddingright: "10px", width: "30%" }}
                    onColorSelect={(color) => handleColorChange(color, index)}
                    selectedColor={propertyColor[index]}
                  />
                </div>
                <div
                  style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                    width: "1px",
                  }}
                ></div>
                <div
                  style={{
                    background: "transparent",
                    display: "table-cell",
                    height: "100%",
                    paddingLeft: "5%",
                    paddingRight: "2%",
                    position: "relative",
                    verticalAlign: "middle",
                    width: "10%",
                  }}
                >
                  {propertyColor[index] === "" ||
                  propertyColor[index] === undefined ? (
                    <Switch
                      disabled
                      color="primary"
                      onChange={(event) =>
                        handleToggleChange(index, event.target.checked)
                      }
                    />
                  ) : (
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={propertyToggle[index] ? true : false}
                              onChange={(event) =>
                                handleToggleChange(index, event.target.checked)
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  )}
                </div>
              </div>
            );
          })}
      </>
    );
  };

  const setEntitiesColour = (themeColor) => {
    selectedEntities.forEach((modelEntity, urnId) => {
      modelEntity.forEach((entity) => {
        if (
          selectedEntityURN[urnId] &&
          modelHierarchy[selectedEntityURN[urnId]]
        ) {
          let toggeledModel = props.viewer.impl
            .modelQueue()
            .findModel(modelHierarchy[selectedEntityURN[urnId]].modelId);
          if (toggeledModel) {
            toggeledModel.getObjectTree(function (objectTree) {
              if (objectTree.getChildCount(parseInt(entity)) === 0) {
                toggeledModel.setThemingColor(
                  parseInt(entity),
                  themeColor,
                  toggeledModel
                );
              } else {
                
              }
            });
          }
        }
      });

      props.viewer.impl.invalidate(true);
    });
  };

  const handleColorChange = (color, index) => {
    let toggeledModel = null;
    let tempColor = [...propertyColor];
    let newColor = {
      rgb: {
        r: color.rgb.r,
        b: color.rgb.b,
        g: color.rgb.g,
        a: color.rgb.a,
      },
      hex: color.hex,
      hsl: {
        a: color.hsl.a,
        h: color.hsl.h,
        l: color.hsl.l,
        s: color.hsl.s,
      },
    };
    tempColor[index] = newColor;
    setPropertyColor(tempColor);

    const r = color.rgb.r / 255;
    const g = color.rgb.g / 255;
    const b = color.rgb.b / 255;
    let themeColor = new Window.THREE72.Vector4(r, g, b, 1);

    selectedColour = color;
    selectedPropIndex = index;
    let tempExistingColour = checkPreviousColour(index, propertyValues[index]);
    if (
      tempExistingColour !== undefined &&
      tempExistingColour !== "" &&
      tempExistingColour.hex !== color.hex
    ) {
      setColorCheckDialog(true);
      existingColour = tempExistingColour;
    } else {
      if (selectedTemplate > -1) {
        if (
          props.templateProperties[selectedTemplate] &&
          props.templateProperties[selectedTemplate][index]
        ) {
          setEntitiesColour(themeColor);
        }
      } else if (selectedMultiTemplate > -1) {
        if (
          props.multipleTemplateProperties[selectedMultiTemplate] &&
          props.multipleTemplateProperties[selectedMultiTemplate][index]
        ) {
          setEntitiesColour(themeColor);
        }
      }
      handleToggleChange(index, true, color);
    }
  };

  const handleToggleChange = (index, checked, color = null) => {
    if (checked) {
      let tempToggle = [...propertyToggle];
      for (let i in propertyToggle) {
        if (propertyToggle[i] !== undefined && propertyToggle[i]) {
          tempToggle[i] = false;
        }
      }
      tempToggle[index] = true;
      setPropertyToggle(tempToggle);
    } else {
      let tempToggle = [...propertyToggle];
      tempToggle[index] = false;
      setPropertyToggle(tempToggle);
    }
    if (checked) {
      let themeColor = null;
      if (color) {
        const r = color.rgb.r / 255;
        const b = color.rgb.b / 255;
        const g = color.rgb.g / 255;
        themeColor = new Window.THREE72.Vector4(r, g, b, 1);
      } else if (propertyColor[index] && propertyColor[index].rgb) {
        const r = propertyColor[index].rgb.r / 255;
        const b = propertyColor[index].rgb.b / 255;
        const g = propertyColor[index].rgb.g / 255;
        themeColor = new Window.THREE72.Vector4(r, g, b, 1);
      }
      setEntitiesColour(themeColor);
    } else {
      //Current loaded model
      let toggeledModel = null;
      const url = window.location.pathname;
      let urlParams = splitURN(url);
      let urlIds = urlParams.urlIds;

      urlIds.forEach((urn) => {
        if (modelHierarchy[urn])
          toggeledModel = props.viewer.impl
            .modelQueue()
            .findModel(modelHierarchy[urn].modelId);
        if (toggeledModel) {
          props.viewer.clearThemingColors(toggeledModel);
        }
      });
    }
    setToggleChanged(true);
    setTimeout(() => {
      props.viewer.impl.invalidate(true);
    }, 10);
  };

  return (
    <div className="react-content">
      <Fragment>
        {props.templatesFetched && props.propertyValuesFetched ? (
          <Fragment>
            {/* Either single model template exist or multi Model template exist AND neither templates are selected currently */}
            {(modelTemplates.length > 0 || multiModelTemplates.length > 0) &&
            selectedTemplate < 0 &&
            selectedMultiTemplate < 0 ? (
              <>
                <div>
                  <Typography
                    style={{ marginTop: "10px", marginBottom: "5px" }}
                  >
                    Select Template :{" "}
                  </Typography>
                  {!multiModelEntitiesSelected ? listTemplatesUI() : null}
                  <div style={{ marginRight: "5%", marginLeft: "5%" }}>
                    <Divider
                      variant="fullWidth"
                      style={{ backgroundColor: "#fff" }}
                    />

                    <div style={{ fontSize: "initial" }}>
                      Multi Model Templates :
                    </div>
                  </div>
                  {listMultiTemplatesUI()}
                </div>
              </>
            ) : (
              <>
                {(modelTemplates.length > 0 ||
                  multiModelTemplates.length > 0) &&
                (selectedTemplate >= 0 || selectedMultiTemplate >= 0) ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        position: "sticky",
                        zIndex: "10",
                        top: "0",
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        marginLeft: "-5%",
                        marginRight: "-5%",
                        backgroundColor: "#333",
                      }}
                    >
                      <ArrowBackIcon
                        className="ArrowBackIcon"
                        onClick={() => {
                          setSelectedTemplate(-1);
                          setSelectedMultiTemplate(-1);
                        }}
                      />

                      {valuesChanged || colorsChanged || toggleChanged ? (
                        <button
                          className="saveButtonWrapper2"
                          type="button"
                          style={{ borderRadius: "5px" }}
                          onClick={() => onPropertiesSave1()}
                        >
                          <i class="far fa-save"></i>
                          {"  "}Save
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                    {showCustomPropertiesUI()}
                    <div className="bottom-padding"></div>
                  </>
                ) : (
                  <div>No Templates</div>
                )}
              </>
            )}
          </Fragment>
        ) : (
          <div>
            <CircularProgress color="secondary" className="spinner" />
          </div>
        )}
      </Fragment>
      <Fragment>
        <Dialog
          open={colorCheckDialog}
          onClose={() => {
            setColorCheckDialog(false);
            setReplaceColorsPrompt(0);
          }}
        >
          <DialogTitle>Change previous colours?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have previously assigned different colours for the same
              property value. Do you want replace colour for the property value?
            </DialogContentText>
            <DialogActions>
              <Button
                onClick={() => {
                  setReplaceColorsPrompt(1);
                  setColorCheckDialog(false);
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setReplaceColorsPrompt(0);
                  setColorCheckDialog(false);
                }}
              >
                No
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Fragment>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { modelList, selectedModel } = state.models;
  const {
    tempModelId,
    templateId,
    templateName,
    templateProperties,
    multipleTempModelId,
    multipleTemplateId,
    multipleTemplateName,
    multipleTemplateProperties,
    propertiesValuesList,
    propertyValuesFetched,
    templatesFetched,
  } = state.customProperties;
  const { folderId, selectedFolder, fetchFoldersStatus, folderModelList } =
    state.folders;
  return {
    modelList,
    selectedModel,
    templateName,
    templateProperties,
    templateId,
    tempModelId,
    multipleTempModelId,
    multipleTemplateId,
    multipleTemplateName,
    multipleTemplateProperties,
    propertiesValuesList,
    propertyValuesFetched,
    templatesFetched,
    folderId,
    selectedFolder,
    fetchFoldersStatus,
    folderModelList,
  };
};

export default connect(mapStateToProps, {
  getModelList,
  getTemplate,
  addCustomProperty,
  getCustomProperties,
  getFolders,
  setCurrentFolder,
})(CustomProperties);
