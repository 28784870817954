import React, { forwardRef } from "react";

import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import commonStyles from "./styles";

const FButtonPrimary = forwardRef((props, ref) => {
  const newProps = { ...props };
  delete newProps.classes;
  return (
    <Button
      variant="contained"
      disabled={props.disabled}
      color="primary"
      ref={ref}
      className={props.classes.tb_m}
      {...newProps}
    >
      {props.children}
    </Button>
  );
});

FButtonPrimary.displayName = "FButtonPrimary";

FButtonPrimary.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any,
};

export default commonStyles(FButtonPrimary);
