import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../images/Headerlogo.png";
import "./style.scss";

import Navbar from "react-bootstrap/Navbar";

const useStyles = makeStyles((theme) => ({
  topBarContainer: {
    background: theme.palette.common.white,
    width: "100vw",
    height: "5rem",
    display: "flex",
    alignItems: "center",
    boxShadow: "10px 10px 20px #dbdbdb, -10px -10px 20px #ffffff",
  },
  menuText: {
    textAlign: "right",
  },
  textDisabled: {
    color: theme.palette.text.disabled,
    fontWeight: "bold",
    // color: "#363636",
    fontSize: "1.25rem",

    // fontWeight: "500",
    lineHeight: "1.6",
    letterSpacing: "0.0075em",
    "&:hover": {
      textDecoration: "unset",
    },
  },
  button: {
    fontWeight: "bold",
  },
  avatarContainer: {
    right: "2rem",
    position: "absolute",
    top: ".8rem",
  },
  avatar: {
    boxShadow: "none",
    fontWeight: "bold",
    borderRadius: "50%",
    height: "3.5rem",
    width: "3.5rem !important",
  },
  logoStyle: {
    width: "12rem !important",
  },
}));

const NonAuthTopBar = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Navbar
        collapseOnSelect
        expand="md"
        bg="white"
        className="nav-back-shadow"
      >
        <Navbar.Brand>
          <Box display="flex" flexDirection="row" ml={3}>
            <a href="https://cotrinity.com/education/" target="blank">
              <img src={logo} alt="Logo" className={classes.logoStyle}></img>
            </a>
          </Box>
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        <Navbar.Collapse id="responsive-navbar-nav"></Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  );
};

export default NonAuthTopBar;
