import { produce } from "immer";
import {
  ADD_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  SET_CREDITS,
} from "../actions/subscriptionActions";

const initialState = {
  SubscribedPlanId: [],
  SubscriptionStartDate: [],
  SubscriptionEndDate: [],
  UserCredits: "",
  ActivePlan: [],
  SubscriptionId: [],
  UserAddedSubscription: 0,
  SubscriptionDetailsFetched: 0,
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUBSCRIPTION: {
      return produce(state, (draft) => {
        draft.SubscribedPlanId.push(action.payload.PlanId);
        draft.SubscriptionStartDate.push(action.payload.SubscriptionStartDate);
        draft.SubscriptionEndDate.push(action.payload.SubscriptionEndDate);

        draft.ActivePlan.push(action.payload.ActivePlan);
        draft.SubscriptionId.push(action.payload.SubscriptionId);
        draft.UserCredits = action.payload.UserCredits;
        draft.UserAddedSubscription = 1;
      });
    }
    case SET_CREDITS: {
      return produce(state, (draft) => {
        draft.UserCredits = action.payload.UserCredits;
      });
    }
    case UPDATE_SUBSCRIPTION: {
      return produce(state, (draft) => {
        draft.SubscribedPlanId[action.payload.subscriptionIndex] =
          action.payload.planId;
        draft.SubscriptionStartDate[action.payload.subscriptionIndex] =
          action.payload.SubscriptionStartDate;
        draft.SubscriptionEndDate[action.payload.subscriptionIndex] =
          action.payload.SubscriptionEndDate;

        draft.ActivePlan[action.payload.subscriptionIndex] =
          action.payload.ActivePlan;
        draft.SubscriptionId[action.payload.subscriptionIndex] =
          action.payload.SubscriptionId;
        draft.UserCredits = action.payload.UserCredits;
      });
    }
    case DELETE_SUBSCRIPTION: {
      return produce(state, (draft) => {
        draft.SubscribedPlanId.splice(action.payload.subscriptionIndex);
        draft.SubscriptionStartDate.splice(action.payload.subscriptionIndex);
        draft.SubscriptionEndDate.splice(action.payload.subscriptionIndex);
        draft.UserCredits = "";
      });
    }
    case GET_SUBSCRIPTION: {
      return produce(state, (draft) => {
        for (let i in action.payload.data) {
          draft.SubscribedPlanId.push(action.payload.data[0].planId);
          draft.SubscriptionStartDate.push(
            action.payload.data[0].subscriptionStartDate
          );
          draft.SubscriptionEndDate.push(
            action.payload.data[0].subscriptionEndDate
          );
          draft.UserCredits = action.payload.data[0].userCredits;
          draft.ActivePlan.push(action.payload.data[0].activePlan);
          draft.SubscriptionId.push(action.payload.data[0].subscriptionId);
        }
        draft.SubscriptionDetailsFetched = 1;
      });
    }
    default: {
      return state;
    }
  }
};
export default subscriptionReducer;
