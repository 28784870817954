import React from "react";
import ReactDOM from "react-dom";
import { Environment, constants } from "./config";
import axios from "axios";
import qs from "qs";
import scriptLoader from "react-async-script-loader";
import { withRouter, useHistory } from "react-router-dom";
import Client from "../Client";
import {
  Button,
  CssBaseline,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import logo from "../../images/logo.png";
import Trial from "../../images/Trial.png"
import Lite from "../../images/Lite.png"
import Stan from "../../images/Stan.png"
import Pro from "../../images/Pro.jpg"
import FButtonPrimary from "./FButtonPrimary";
import PropTypes, { array } from "prop-types";
import { toastr } from "react-redux-toastr";
import {
  setSubscription,
  getSubscription,
  deleteSubscription,
  updateSubscription,
} from "../../actions/subscriptionActions";
import { connect } from "react-redux";
import getPlanDetails from "../../actions/planActions";
import { getModelList } from "../../actions/modelsActions";
import "../Subscription/style.scss";

const CLIENT_ID = constants.Development.paypalClientID;
const CLIENT_ID_Test = constants.Testing.paypalClientID;
var script = ``;
if (!(localStorage.getItem("email") === constants.Testing.TestingEmail)) {
  script = `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&vault=true`;
} else {
  script = `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID_Test}&vault=true`;
}

const i = 0;
let PayPalButton = null;
const errorNotification = (msg) => {
  toastr.error(msg);
};

class PSubscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      planPricingSet: false,
      SubscribedPlanIdSet: false,
      showButtons: false,
      subscriptionId: "",
      loading: true,
      paid: false,
      isDevelopment: false,
      isSubscribetoLite: false,
      isSubscribetoBasic: false,
      isSubscribetoPro: false,
      isSubscribetoTrial: false,
      TrialPricing: 0,
      LitePricing: 0,
      BasicPricing: 0,
      ProPricing: 0,
      isOpen: false,
      PlanSelectionPricing: 0,
      settingsData: undefined,
      PlanName: "",
      isShowUpdatePlanButton: false,
      isShowPaymentDialog: false,
      PlanId: "",
      subscriptionStartDate: "",
      subscriptionEndDate: "",
      subscriptionIndex: 0,
      isLiteVisible: false,
      isBasicVisible: false,
      isProVisible: false,
      TryNowButton: "Try Now",
      MoreDetailsButton: "More Details",
      modelLength: 0,
      modelLengthSet: false,
      isLitePlanActive: false,
      isBasicPlanActive: false,
      isProPlanActive: false,
      isTrialPlanActive: false,
      oldCredits: 0,
      userSubscribedToTrial: false,
      activePlan: "",
      trialSubscriptionId: "",
      userId: "",
      email: "",
      objExportCount: 0,
    };
    window.React = React;
    window.ReactDOM = ReactDOM;
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    const userId = localStorage.getItem("subId");
    const email = localStorage.getItem("email");
    this.setState({ email: email });
    this.setState({ userId: userId });

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }

    await this.props.getSubscription(userId);
    await this.props.getPlanDetails(email);
    await this.props.getModelList(userId);
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (process.env.REACT_APP_COTRINITY_ENV=== "development") this.setState({ isDevelopment: true });
    else  this.setState({ isDevelopment: false });

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }
  componentDidUpdate() {
    if (
      this.props.modelList &&
      Array.isArray(this.props.modelList) &&
      !this.state.modelLengthSet
    ) {
      this.state.modelLength = this.setState({
        modelLength: this.props.modelList.length,
      });
      this.setState({ modelLengthSet: true });
    }
    //Fetch Plan Pricing
    if (
      this.props.PlanId.length > 0 &&
      this.props.PlanPricing.length > 0 &&
      !this.state.planPricingSet
    ) {
      for (let j in this.props.PlanId) {
        if (!(this.state.email === constants.Testing.TestingEmail)) {
          switch (this.props.PlanId[j]) {
            case constants.Development.subscriptionTrialPlanID:
              this.setState({ TrialPricing: this.props.PlanPricing[j] });
              break;
            case constants.Development.subscriptionLitePlanID:
              this.setState({ LitePricing: this.props.PlanPricing[j] });
              break;
            case constants.Development.subscriptionBasicPlanID:
              this.setState({ BasicPricing: this.props.PlanPricing[j] });
              break;
            case constants.Development.subscriptionProPlanID:
              this.setState({ ProPricing: this.props.PlanPricing[j] });
              break;
          }
        } else {
          switch (this.props.PlanId[j]) {
            case constants.Testing.subscriptionTrialPlanID:
              this.setState({ TrialPricing: this.props.PlanPricing[j] });
              break;
            case constants.Testing.subscriptionLitePlanID:
              this.setState({ LitePricing: this.props.PlanPricing[j] });
              break;
            case constants.Testing.subscriptionBasicPlanID:
              this.setState({ BasicPricing: this.props.PlanPricing[j] });
              break;
            case constants.Testing.subscriptionProPlanID:
              this.setState({ ProPricing: this.props.PlanPricing[j] });
              break;
          }
        }
      }
      this.setState({ planPricingSet: true });
    }

    //Fetch Subscription data
    if (
      this.props.SubscribedPlanId.length > 0 &&
      !this.state.SubscribedPlanIdSet
    ) {
      if (!(this.state.email === constants.Testing.TestingEmail)) {
        this.setState({ PlanId: this.props.SubscribedPlanId[0] });
        switch (this.props.SubscribedPlanId[0]) {
          case constants.Development.subscriptionTrialPlanID:
            this.setState({ isSubscribetoTrial: true });
            this.setState({
              isSubscribetoLite: false,
              isSubscribetoBasic: false,
              isSubscribetoPro: false,
              paid: false,
            });
            break;
          case constants.Development.subscriptionLitePlanID:
            this.setState({ isSubscribetoLite: true });
            break;
          case constants.Development.subscriptionBasicPlanID:
            this.setState({ isSubscribetoBasic: true });
            break;
          case constants.Development.subscriptionProPlanID:
            this.setState({ isSubscribetoPro: true });
            break;
        }
        this.setState({ SubscribedPlanIdSet: true });
      } else {
        this.setState({ PlanId: this.props.SubscribedPlanId[0] });
        switch (this.props.SubscribedPlanId[0]) {
          case constants.Testing.subscriptionTrialPlanID:
            this.setState({ isSubscribetoTrial: true });
            this.setState({
              isSubscribetoLite: false,
              isSubscribetoBasic: false,
              isSubscribetoPro: false,
              paid: false,
            });
            break;
          case constants.Testing.subscriptionLitePlanID:
            this.setState({ isSubscribetoLite: true });
            break;
          case constants.Testing.subscriptionBasicPlanID:
            this.setState({ isSubscribetoBasic: true });
            break;
          case constants.Testing.subscriptionProPlanID:
            this.setState({ isSubscribetoPro: true });
            break;
        }
        this.setState({ SubscribedPlanIdSet: true });
      }
    }
  }

  createLiteSubscription = (data, actions) => {
    if (!(this.state.email === constants.Testing.TestingEmail)) {
      this.setState({ PlanSelectionPricing: this.state.LitePricing });
      this.setState({ PlanName: "LitePlan" });
      this.setState({ isSubscribetoPro: false, isSubscribetoBasic: false });
      this.setState({
        activePlan: constants.Development.subscriptionLitePlanName,
      });
      this.setState({ objExportCount: 10 });
      return actions.subscription.create({
        plan_id: constants.Development.subscriptionLitePlanID,
      });
    } else {
      this.setState({ PlanSelectionPricing: this.state.LitePricing });
      this.setState({ PlanName: "LitePlan" });
      this.setState({ isSubscribetoPro: false, isSubscribetoBasic: false });
      this.setState({
        activePlan: constants.Testing.subscriptionLitePlanName,
      });

      this.setState({ objExportCount: 10 });
      return actions.subscription.create({
        plan_id: constants.Testing.subscriptionLitePlanID,
      });
    }
  };

  createBasicSubscription = (data, actions) => {
    if (!(this.state.email === constants.Testing.TestingEmail)) {
      this.setState({ PlanSelectionPricing: this.state.BasicPricing });
      this.setState({ PlanName: "BasicPlan" });
      this.setState({ isSubscribetoPro: false, isSubscribetoLite: false });
      this.setState({
        activePlan: constants.Development.subscriptionBasicPlanName,
      });
      this.setState({ objExportCount: 20 });
      return actions.subscription.create({
        plan_id: constants.Development.subscriptionBasicPlanID,
      });
    } else {
      this.setState({ PlanSelectionPricing: this.state.BasicPricing });
      this.setState({ PlanName: "BasicPlan" });
      this.setState({ isSubscribetoPro: false, isSubscribetoLite: false });
      this.setState({
        activePlan: constants.Testing.subscriptionBasicPlanName,
      });
      this.setState({ objExportCount: 20 });
      return actions.subscription.create({
        plan_id: constants.Testing.subscriptionBasicPlanID,
      });
    }
  };

  createProSubscription = (data, actions) => {
    if (!(this.state.email === constants.Testing.TestingEmail)) {
      this.setState({ PlanSelectionPricing: this.state.ProPricing });
      this.setState({ PlanName: "ProPlan" });
      this.setState({ isSubscribetoBasic: false, isSubscribetoLite: false });
      this.setState({
        activePlan: constants.Development.subscriptionProPlanName,
      });

      this.setState({ objExportCount: 40 });
      return actions.subscription.create({
        plan_id: constants.Development.subscriptionProPlanID,
      });
    } else {
      this.setState({ PlanSelectionPricing: this.state.ProPricing });
      this.setState({ PlanName: "ProPlan" });
      this.setState({ isSubscribetoBasic: false, isSubscribetoLite: false });
      this.setState({
        activePlan: constants.Testing.subscriptionProPlanName,
      });
      this.setState({ objExportCount: 40 });
      return actions.subscription.create({
        plan_id: constants.Testing.subscriptionProPlanID,
      });
    }
  };
  //Function when subscription approves
  onApproveSubscription = async (data, actions) => {
    // Capture the funds from the transaction
    return actions.subscription
      .get()
      .then(async (details) => {
        if (details.status === "ACTIVE") {
          this.setState({ subscriptionId: data.subscriptionID });
          this.setState({ PlanId: details.plan_id });
          this.setState({
            subscriptionStartDate: new Date(
              details.create_time
            ).toLocaleDateString(),
          });
          this.setState({
            subscriptionEndDate: new Date(
              details.billing_info.next_billing_time
            ).toLocaleDateString(),
          });

          await this.AddSubscription();
          if (this.state.PlanSelectionPricing === this.state.LitePricing) {
            this.setState({ paid: true, isSubscribetoLite: true });
          } else if (
            this.state.PlanSelectionPricing === this.state.BasicPricing
          ) {
            this.setState({ paid: true, isSubscribetoBasic: true });
          } else if (
            this.state.PlanSelectionPricing === this.state.ProPricing
          ) {
            this.setState({ paid: true, isSubscribetoPro: true });
          }
          this.setState({
            isShowUpdatePlanButton: true,
            isShowPaymentDialog: false,
          });
        } else {
          const msg = "Request not processed";
          errorNotification(msg);
        }
        this.props.history.push("/folders");
      })
      .catch((error) => {
        errorNotification(error);
      });
  };
  //Adding subscription to database
  AddSubscription = async () => {
    //Check and add old credits to new credits

    if (!(this.state.email === constants.Testing.TestingEmail)) {
      if (
        parseInt(this.props.UserCredits) > 0 &&
        this.props.SubscribedPlanId[i] !=
          constants.Development.subscriptionTrialPlanID
      ) {
        this.props.setSubscription(
          this.state.userId,
          this.state.PlanId,
          this.state.subscriptionStartDate,
          this.state.subscriptionEndDate,
          this.props.UserCredits,
          this.state.activePlan,
          this.state.subscriptionId,
          this.state.objExportCount
        );
      } else {
        this.props.setSubscription(
          this.state.userId,
          this.state.PlanId,
          this.state.subscriptionStartDate,
          this.state.subscriptionEndDate,
          constants.Development.NullCredits,
          this.state.activePlan,
          this.state.subscriptionId,
          this.state.objExportCount
        );
      }
    } else {
      if (
        parseInt(this.props.UserCredits) > 0 &&
        this.props.SubscribedPlanId[i] !=
          constants.Testing.subscriptionTrialPlanID
      ) {
        this.props.setSubscription(
          this.state.userId,
          this.state.PlanId,
          this.state.subscriptionStartDate,
          this.state.subscriptionEndDate,
          this.props.UserCredits,
          this.state.activePlan,
          this.state.subscriptionId,
          this.state.objExportCount
        );
      } else {
        this.props.setSubscription(
          this.state.userId,
          this.state.PlanId,
          this.state.subscriptionStartDate,
          this.state.subscriptionEndDate,
          constants.Testing.NullCredits,
          this.state.activePlan,
          this.state.subscriptionId,
          this.state.objExportCount
        );
      }
    }
  };
  //Function to handle user cancellation
  onCancel = () => {
    var subscriptionId = this.props.SubscriptionId[i];
    if (
      this.props.SubscriptionId[i] === constants.Development.trialSubscriptionId
    ) {
      const msg = "Subscription Cancelled";
      errorNotification(msg);
    } else {
      if (!(this.state.email === constants.Testing.TestingEmail)) {
        var data = qs.stringify({
          grant_type: "client_credentials",
        });
        var config = {
          url: "https://api.paypal.com/v1/oauth2/token",
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Credentials": true,
          },
          auth: {
            username: constants.Development.paypalClientID,
            password: constants.Development.paypalSecretID,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            var data = { reason: "Not satisfied with service." };
            var config = {
              method: "post",
              url: `https://api-m.paypal.com/v1/billing/subscriptions/${subscriptionId}/cancel`,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + response.data.access_token,
              },
              data: data,
            };
            axios(config)
              .then(function (response) {})
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        var data = qs.stringify({
          grant_type: "client_credentials",
        });
        var config = {
          url: "https://api.sandbox.paypal.com/v1/oauth2/token",
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Credentials": true,
          },
          auth: {
            username: constants.Testing.paypalClientID,
            password: constants.Testing.paypalSecretID,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            var data = { reason: "Not satisfied with service." };
            var config = {
              method: "post",
              url: `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${subscriptionId}/cancel`,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + response.data.access_token,
              },
              data: data,
            };
            axios(config)
              .then(function (response) {})
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  //Function to handle error if occurs
  onError = (error) => {
    errorNotification(error);
  };
  //Function for Cancelling subscription
  CancelSubscription() {
    this.onCancel();
    this.setState({
      activePlan: constants.Development.subscriptionPlanCancel,
    });
    this.props
      .updateSubscription(
        this.state.subscriptionIndex,
        this.state.userId,
        this.props.SubscribedPlanId[i],
        this.props.SubscriptionStartDate[i],
        this.props.SubscriptionEndDate[i],
        this.props.UserCredits,
        constants.Development.subscriptionPlanCancel,
        this.props.SubscriptionId[i]
      )
      .then(() => {
        this.setState({
          paid: false,
          isSubscribetoLite: false,
          isSubscribetoBasic: false,
          isSubscribetoPro: false,
        });
      })
      .catch(() => {
        alert("Error while UnSubscribe");
      });
  }
  handleOnInit = (actions) => {
    if (
      this.state.isSubscribetoLite &&
      this.props.ActivePlan[i] ===
        constants.Development.subscriptionLitePlanName
    ) {
      actions.disable();
    } else if (
      this.state.isSubscribetoBasic &&
      this.props.ActivePlan[i] ===
        constants.Development.subscriptionBasicPlanName
    ) {
      actions.disable();
    } else if (
      this.state.isSubscribetoPro &&
      this.props.ActivePlan[i] === constants.Development.subscriptionProPlanName
    ) {
      actions.disable();
    }
  };
  handleClick = () => {
    const msg = "Already subscribed";
    if (
      this.state.isSubscribetoLite &&
      this.props.ActivePlan[i] ===
        constants.Development.subscriptionLitePlanName
    ) {
      errorNotification(msg);
    } else if (
      this.state.isSubscribetoBasic &&
      this.props.ActivePlan[i] ===
        constants.Development.subscriptionBasicPlanName
    ) {
      errorNotification(msg);
    } else if (
      this.state.isSubscribetoPro &&
      this.props.ActivePlan[i] === constants.Development.subscriptionProPlanName
    ) {
      errorNotification(msg);
    }
  };

  //Try Now days counter
  calculateCountdown = () => {
    const endDate = new Date(this.props.SubscriptionEndDate[i]);
    endDate.setDate(endDate.getDate());

    if (
      new Date() > new Date(this.props.SubscriptionEndDate[i]) &&
      new Date().getDate() !=
        new Date(this.props.SubscriptionEndDate[i]).getDate() &&
      this.props.ActivePlan[i] ==
        constants.Development.subscriptionTrialPlanName
    ) {
      return "Your Trial Plan has expired";
    } else {
      return "Plan expires on: " + endDate.toLocaleDateString();
    }
  };

  goToDetails = ()=> {
    window.open("https://cotrinity.com/pricing/",'_blank');
  }
  //Trial Plan
  trialPlanTest = () => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate());
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    endDate.setHours(23, 59, 59, 0);
    this.state.objExportCount = 5;
    this.state.subscriptionStartDate =
      startDate.getMonth() +
      1 +
      "/" +
      startDate.getDate() +
      "/" +
      startDate.getFullYear();
    this.state.subscriptionEndDate =
      endDate.getMonth() +
      1 +
      "/" +
      endDate.getDate() +
      "/" +
      endDate.getFullYear();
    this.state.PlanId = constants.Development.subscriptionTrialPlanID;
    this.state.PlanSelectionPricing = this.state.TrialPricing;
    this.state.activePlan = constants.Development.subscriptionTrialPlanName;
    this.state.subscriptionId = constants.Development.trialSubscriptionId;
    this.setState({
      isSubscribetoTrial: true,
      paid: false,
    });
    this.props.setSubscription(
      this.state.userId,
      this.state.PlanId,
      this.state.subscriptionStartDate,
      this.state.subscriptionEndDate,
      this.state.PlanSelectionPricing,
      this.state.activePlan,
      this.state.subscriptionId,
      this.state.objExportCount
    );

    this.props.history.push("/folders");
  };
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const { showButtons, loading, paid } = this.state;

    return (
      this.state.isDevelopment ? 
      <h5 style={{ textAlign: "center", paddingTop: "16px", }}>
        You are in development mode.
      </h5> :
      <div className="hero-anime">
        {new Date() > new Date(this.props.SubscriptionEndDate[i]) &&
        new Date().getDate() !=
          new Date(this.props.SubscriptionEndDate[i]).getDate() ? (
          <></>
        ) : (
          <div className="closeSubcription" onClick={this.goBack.bind(this)}>
            Close
          </div>
        )}

        {loading}

        {/*Subscription Plan*/}
        {showButtons && (
          <div className="auth-only-wrapper1">
              <Grid container justify="center">
              <Container
                component="main"
                maxWidth="xs"
                className="auth-container"
                style={{backgroundColor: "#6f8b8e"}}
              >
                <CssBaseline />
                <div>
                <img style={{ borderLeft : "0px"}} src={Trial} alt="Logo" className="subLogoStyle"></img>
                  <Container
                      component="main"
                      maxWidth="xs"
                      className="auth-contain"
                    >
                    <Typography component="h1" variant="h5" align="center">
                    <p style={{ marginBottom: "-15px" ,fontWeight: "bold", fontSize: "3.5rem", fontFamily: "'DM Sans Medium' , sans-serif" }}>£0</p>
                    <span style={{ fontWeight: "bold",fontSize: "1.2rem", fontFamily: "'DM Sans Medium' , sans-serif" }}>DURATION 7 DAYS</span>
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={16}>
                      <p style={{marginBottom: "0px",fontSize: "2rem",fontWeight: "bold" ,marginTop: "20px" ,textAlign: "center"}}><i>BENEFITS</i></p>
                          <p style={{marginBottom: "-5px",fontSize: "1.2rem",textAlign: "center"}}>Upload files upto 5</p>
                          <p style={{marginBottom: "-5px",fontSize: "1.2rem",textAlign: "center"}}>Upload file of maximum size 300MB</p>
                          <p style={{fontSize: "1.2rem",textAlign: "center"}}>Export upto 5 obj files</p>
                        {/* <label>
                          <b>Benefits:</b>
                        </label>
                        <ul>
                          <li>Upload files upto 5</li>
                          <li>Upload file of maximum size 300MB</li>
                          <li>Export upto 5 obj files</li>
                        </ul>
                        <label>
                          <b>Fully functional:</b>
                        </label>
                        <ul>
                          <li>Revit</li>
                          <li>IFC (coming soon)</li>
                        </ul>
                        <label>
                          <b>Supported File translation:</b>
                        </label>
                        <ul>
                          <li>
                            Revit, NWD, IFC, NWC, FBX, 3DS Max, DWG, DXF, OBJ,
                            sketchUp, IAM
                          </li>
                        </ul>
                        <label>
                          <b>Revit supported features:</b>
                        </label>
                        <ul>
                          <li>Unlimited Projects</li>
                          <li>3D Model View</li>
                          <li>Floor Plan Split</li>
                          <li>Visual clustering</li>
                          <li>Create custom template</li>
                          <li>Create custom properties</li>
                          <li>Create custom filters</li>
                          <li>Multi model viewing</li>
                          <li>Unlimited view saving</li>
                          <li>Unlimited model sharing (valid for 6 days)</li>
                          <li>Export excel data</li>
                        </ul> */}
                      </Grid>
                    </Grid>
                    <Button
                     type="submit"
                     variant="contained"
                     color="primary"
                     style={{
                       width: "304px",
                       color: "#F19505",
                       height: "45px",
                       fontWeight:"bold",
                       fontFamily: "'DM Sans Medium' , sans-serif",
                       marginLeft: "-25px",
                       borderRadius: "0px"
                     }}
                     onClick={() => this.goToDetails()}
                   >
                     {this.state.MoreDetailsButton}
                   </Button>
                      
                  </Container>

                  <br />
                  {this.state.isSubscribetoTrial ? (
                    <div>
                      <h5 style={{ textAlign: "center",  color: "#FFFFFF", }}>
                        You have subscribed to Cotrinity Trial Plan.
                      </h5>
                      <h5
                        style={{
                          textAlign: "center",
                          backgroundColor: "#062D35",
                          borderRadius: "5px",
                          marginBottom: "10px",
                          height: "40px",
                          color: "#F19505",
                          paddingTop: "8px",
                          
                        }}
                      >
                        {this.calculateCountdown()}
                      </h5>
                      {new Date() >
                        new Date(this.props.SubscriptionEndDate[i]) &&
                      new Date().getDate() !=
                        new Date(this.props.SubscriptionEndDate[i]).getDate() &&
                      this.props.ActivePlan[i] ==
                        constants.Development.subscriptionTrialPlanName ? (
                        <></>
                      ) : (
                        <label style={{ paddingLeft: "120px", color: "#FFFFFF", }}>
                          File upload remaining:{" "}
                          {parseInt(this.props.UserCredits) /
                            constants.Development.perModelPrice}
                        </label>
                      )}
                    </div>
                  ) : (
                    <div>
                      {this.props.SubscribedPlanId.length == 0 ? (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          style={{
                            fontSize: "1.1rem",
                            borderRadius: "30px",
                            backgroundColor: "#0070ba",
                            color:"#fff",
                            layout: "horizontal",
                            marginBottom: "10px",
                            height: "45px",
                            fontWeight: "bolder",
                            fontFamily: "'DM Sans Medium' , sans-serif"
                          }}
                          onClick={() => this.trialPlanTest()}
                        >
                          {this.state.TryNowButton}
                        </Button>
                      ) : (
                        
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          style={{
                            fontSize: "1.1rem",
                            borderRadius: "30px",
                            backgroundColor: "#0070ba",
                            color:"#fff",
                            layout: "horizontal",
                            marginBottom: "10px",
                            height: "45px",
                            fontWeight: "bolder",
                            pointerEvents: "none",
                            fontFamily: "'DM Sans Medium' , sans-serif"
                          }}
                          onClick={() => this.trialPlanTest()}
                        >
                          {this.state.TryNowButton}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </Container>
              <Container
                component="main"
                maxWidth="xs"
                className="auth-container"
                style={{backgroundColor: "#516f73"}}
              >
                <CssBaseline />
                <div>
                <img src={Lite} alt="Logo" className="subLogoStyle"></img>
                  <Container
                      component="main"
                      maxWidth="xs"
                      className="auth-contain"
                    >
                    <Typography component="h1" variant="h5" align="center">
                    <p style={{ marginBottom: "-15px" ,fontWeight: "bold", fontSize: "3.5rem", fontFamily: "'DM Sans Medium' , sans-serif" }}>£60</p>
                    <span style={{ fontWeight: "bold",fontSize: "1.2rem", fontFamily: "'DM Sans Medium' , sans-serif" }}>DURATION 1 MONTH</span>
                    </Typography>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={16}>
                        <p style={{marginBottom: "0px",fontSize: "2rem",fontWeight: "bold" ,marginTop: "20px" ,textAlign: "center"}}><i>BENEFITS</i></p>
                          <p style={{marginBottom: "-5px",fontSize: "1.2rem",textAlign: "center"}}>Upload files upto 10</p>
                          <p style={{marginBottom: "-5px",fontSize: "1.2rem",textAlign: "center"}}>Upload file of maximum size 500MB</p>
                          <p style={{fontSize: "1.2rem",textAlign: "center"}}>Export upto 10 obj files</p>
                          {/* <label>
                            <b>Benefits:</b>
                          </label>
                          <ul>
                            <li>Upload files upto 10</li>
                            <li>Upload file of maximum size 500MB</li>
                            <li>Export upto 10 obj files</li>
                          </ul>
                          <label>
                            <b>Fully functional:</b>
                          </label>
                          <ul>
                            <li>Revit</li>
                            <li>IFC (coming soon)</li>
                          </ul>
                          <label>
                            <b>Supported File translation:</b>
                          </label>
                          <ul>
                            <li>
                              Revit, NWD, IFC, NWC, FBX, 3DS Max, DWG, DXF, OBJ,
                              sketchUp, IAM
                            </li>
                          </ul>
                          <label>
                            <b>Revit supported features:</b>
                          </label>
                          <ul>
                            <li>Unlimited Projects</li>
                            <li>3D Model View</li>
                            <li>Floor Plan Split</li>
                            <li>Visual clustering</li>
                            <li>Create custom template</li>
                            <li>Create custom properties</li>
                            <li>Create custom filters</li>
                            <li>Multi model viewing</li>
                            <li>Unlimited view saving</li>
                            <li>Unlimited model sharing (valid for 6 days)</li>
                            <li>Export excel data</li>
                          </ul> */}
                        </Grid>
                      </Grid>
                    </div>
                    <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            width: "304px",
                            color: "#F19505",
                            height: "45px",
                            fontWeight:"bold",
                            fontFamily: "'DM Sans Medium' , sans-serif",
                            marginLeft: "-25px",
                            borderRadius: "0px"
                          }}
                          onClick={() => this.goToDetails()}
                        >
                          {this.state.MoreDetailsButton}
                        </Button>
                  </Container>

                  <br />
                  {this.state.isSubscribetoLite &&
                  this.props.ActivePlan[i] !=
                    constants.Development.subscriptionPlanCancel ? (
                    <div>
                      <div>
                        <h5 style={{ textAlign: "center",  color: "#FFFFFF",}}>
                          You have subscribed to Cotrinity Lite Plan.
                        </h5>
                      </div>
                      <div>
                        &emsp;&emsp;&emsp;&emsp;
                        <FButtonPrimary
                          onClick={() => this.CancelSubscription()}
                        >
                          Cancel Subscription
                        </FButtonPrimary>
                        <label style={{ paddingLeft: "120px",  color: "#FFFFFF", }}>
                          Plan Expires on: {this.props.SubscriptionEndDate[i]}
                          <br />
                          File upload remaining:{" "}
                          {parseInt(this.props.UserCredits) /
                            constants.Development.perModelPrice}
                        </label>
                      </div>
                    </div>
                  ) : (  
                    <div>
                      <PayPalButton
                        options={{ vault: true }}
                        style={{
                          pointerEvents: "none",
                          shape: "pill",
                          color: "blue",
                          layout: "horizontal",
                          label: "subscribe",
                        }}
                        onClick={() => this.handleClick()}
                        onInit={(data, actions) => this.handleOnInit(actions)}
                        createSubscription={(data, actions) =>
                          this.createLiteSubscription(data, actions)
                        }
                        onApprove={(data, actions) =>
                          this.onApproveSubscription(data, actions)
                        }
                        onCancel={() => this.onCancel()}
                        onError={(error) => this.onError(error)}
                      />
                      {this.props.ActivePlan[i] ==
                        constants.Development.subscriptionPlanCancel &&
                      this.props.SubscribedPlanId[i] ===
                        constants.Development.subscriptionLitePlanID ? (
                        <div>
                          <h5 style={{ textAlign: "center" }}>
                            Your subscription is inactive
                          </h5>
                          <label style={{ paddingLeft: "120px",  color: "#FFFFFF", }}>
                            Plan Expires on: {this.props.SubscriptionEndDate[i]}
                            <br />
                            File upload remaining:{" "}
                            {parseInt(this.props.UserCredits) /
                              constants.Development.perModelPrice}
                          </label>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </Container>
              <Container
                component="main"
                maxWidth="xs"
                className="auth-container"
                style={{backgroundColor: "#33545a"}}
              >
                <CssBaseline />
                <div>
                <img style={{ marginTop: "-5px" , width: "353px"}} src={Stan} alt="Logo" className="subLogoStyle"></img>
                  <Container
                      component="main"
                      maxWidth="xs"
                      className="auth-contain"
                    >
                    <Typography component="h1" variant="h5" align="center">
                    <p style={{ marginBottom: "-15px" ,fontWeight: "bold", fontSize: "3.5rem", fontFamily: "'DM Sans Medium' , sans-serif" }}>£120</p>
                    <span style={{ fontWeight: "bold",fontSize: "1.2rem", fontFamily: "'DM Sans Medium' , sans-serif" }}>DURATION 1 MONTH</span>
                    </Typography>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={16}>
                        <p style={{marginBottom: "0px",fontSize: "2rem",fontWeight: "bold" ,marginTop: "20px" ,textAlign: "center"}}><i>BENEFITS</i></p>
                          <p style={{marginBottom: "-5px",fontSize: "1.2rem",textAlign: "center"}}>Upload files upto 20</p>
                          <p style={{fontSize: "1.2rem",textAlign: "center", marginBottom: "65px"}}>Export upto 20 obj files</p>
                          {/* <label>
                            <b>Benefits:</b>
                          </label>
                          <ul>
                            <li>Upload files upto 20</li> */}
                            {/* <li>Upload file of maximum size 1 GB</li> */}
                            {/* <li>Export upto 20 obj files</li>
                          </ul>
                          <label>
                            <b>Fully functional:</b>
                          </label>
                          <ul>
                            <li>Revit</li>
                            <li>IFC (coming soon)</li>
                          </ul>
                          <label>
                            <b>Supported File translation:</b>
                          </label>
                          <ul>
                            <li>
                              Revit, NWD, IFC, NWC, FBX, 3DS Max, DWG, DXF, OBJ,
                              sketchUp, IAM
                            </li>
                          </ul>
                          <label>
                            <b>Revit supported features:</b>
                          </label>
                          <ul>
                            <li>Unlimited Projects</li>
                            <li>3D Model View</li>
                            <li>Floor Plan Split</li>
                            <li>Visual clustering</li>
                            <li>Create custom template</li>
                            <li>Create custom properties</li>
                            <li>Create custom filters</li>
                            <li>Multi model viewing</li>
                            <li>Unlimited view saving</li>
                            <li>Unlimited model sharing (valid for 6 days)</li>
                            <li>Export excel data</li>
                            <li>API integrations</li>
                          </ul> */}
                        </Grid>
                      </Grid>
                    </div>
                    <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            width: "304px",
                            color: "#F19505",
                            height: "45px",
                            fontWeight:"bold",
                            fontFamily: "'DM Sans Medium' , sans-serif",
                            marginLeft: "-25px",
                            borderRadius: "0px"
                          }}
                          onClick={() => this.goToDetails()}
                        >
                          {this.state.MoreDetailsButton}
                        </Button>
                  </Container>

                  <br />
                  {this.state.isSubscribetoBasic &&
                  this.props.ActivePlan[i] !=
                    constants.Development.subscriptionPlanCancel ? (
                    <div>
                      <div>
                        <h5 style={{ textAlign: "center",  color: "#FFFFFF", }}>
                          You have subscribed to Cotrinity Basic Plan.
                        </h5>
                      </div>
                      <div>
                        &emsp;&emsp;&emsp;&emsp;
                        <FButtonPrimary
                          onClick={() => this.CancelSubscription()}
                        >
                          Cancel Subscription
                        </FButtonPrimary>
                        <label style={{ paddingLeft: "120px",  color: "#FFFFFF", }}>
                          Plan Expires on: {this.props.SubscriptionEndDate[i]}
                          <br />
                          File upload remaining:{" "}
                          {parseInt(this.props.UserCredits) /
                            constants.Development.perModelPrice}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <PayPalButton
                        options={{ vault: true }}
                        style={{
                          shape: "pill",
                          color: "blue",
                          layout: "horizontal",
                          label: "subscribe",
                          pointerEvents: "none",
                        }}
                        onClick={() => this.handleClick()}
                        onInit={(data, actions) => this.handleOnInit(actions)}
                        createSubscription={(data, actions) =>
                          this.createBasicSubscription(data, actions)
                        }
                        onApprove={(data, actions) =>
                          this.onApproveSubscription(data, actions)
                        }
                        onCancel={() => this.onCancel()}
                        onError={(error) => this.onError(error)}
                      />
                      {this.props.ActivePlan[i] ==
                        constants.Development.subscriptionPlanCancel &&
                      this.props.SubscribedPlanId[i] ===
                        constants.Development.subscriptionBasicPlanID ? (
                        <div>
                          <h5 style={{ textAlign: "center" }}>
                            Your subscription is inactive
                          </h5>
                          <label style={{ paddingLeft: "120px",  color: "#FFFFFF", }}>
                            Plan Expires on: {this.props.SubscriptionEndDate[i]}
                            <br />
                            File upload remaining:{" "}
                            {parseInt(this.props.UserCredits) /
                              constants.Development.perModelPrice}
                          </label>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </Container>
              <Container
                component="main"
                maxWidth="xs"
                className="auth-container"
                style={{backgroundColor: "#22434a"}}
              >
                <CssBaseline />
                <div>
                <img src={Pro} alt="Logo" className="subLogoStyle"></img>
                    <Container
                      component="main"
                      maxWidth="xs"
                      className="auth-contain"
                    >
                    <Typography component="h1" variant="h5" align="center">
                    <p style={{ marginBottom: "-15px" ,fontWeight: "bold", fontSize: "3.5rem", fontFamily: "'DM Sans Medium' , sans-serif" }}>£240</p>
                    <span style={{ fontWeight: "bold",fontSize: "1.2rem", fontFamily: "'DM Sans Medium' , sans-serif" }}>DURATION 1 MONTH</span>
                    </Typography>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={16}>
                          <p style={{marginBottom: "0px",fontSize: "2rem",fontWeight: "bold" ,marginTop: "20px" ,textAlign: "center"}}><i>BENEFITS</i></p>
                          <p style={{marginBottom: "-5px",fontSize: "1.2rem",textAlign: "center"}}>Upload files upto 40</p>
                          <p style={{fontSize: "1.2rem",textAlign: "center", marginBottom: "65px"}}>Export upto 40 obj files</p>
                          {/* <label>
                            <b>Benefits:</b>
                          </label>
                          <ul>
                            <li>Upload files upto 40</li> */}
                            {/* <li>Upload file of maximum size 1.5 GB</li> */}
                            {/* <li>Export upto 40 obj files</li>
                          </ul>
                          <label>
                            <b>Fully functional:</b>
                          </label>
                          <ul>
                            <li>Revit</li>
                            <li>IFC (coming soon)</li>
                          </ul>
                          <label>
                            <b>Supported File translation:</b>
                          </label>
                          <ul>
                            <li>
                              Revit, NWD, IFC, NWC, FBX, 3DS Max, DWG, DXF, OBJ,
                              sketchUp, IAM
                            </li>
                          </ul>
                          <label>
                            <b>Revit supported features:</b>
                          </label>
                          <ul>
                            <li>Unlimited Projects</li>
                            <li>3D Model View</li>
                            <li>Floor Plan Split</li>
                            <li>Visual clustering</li>
                            <li>Create custom template</li>
                            <li>Create custom properties</li>
                            <li>Create custom filters</li>
                            <li>Multi model viewing</li>
                            <li>Unlimited view saving</li>
                            <li>Unlimited model sharing (valid for 6 days)</li>
                            <li>Export excel data</li>
                            <li>API integrations</li>
                          </ul> */}
                        </Grid>
                      </Grid>
                    </div>
                    <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            width: "304px",
                            color: "#F19505",
                            height: "45px",
                            fontWeight:"bold",
                            fontFamily: "'DM Sans Medium' , sans-serif",
                            marginLeft: "-25px",
                            borderRadius: "0px"
                          }}
                          onClick={() => this.goToDetails()}
                        >
                          {this.state.MoreDetailsButton}
                        </Button>
                    </Container>

                  <br />
                  {this.state.isSubscribetoPro &&
                  this.props.ActivePlan[i] !=
                    constants.Development.subscriptionPlanCancel ? (
                    <div>
                      <div>
                        <h5 style={{ textAlign: "center",  color: "#FFFFFF", }}>
                          You have subscribed to Cotrinity Pro Plan.
                        </h5>
                      </div>
                      <div>
                        &emsp;&emsp;&emsp;&emsp;
                        <FButtonPrimary
                          onClick={() => this.CancelSubscription()}
                        >
                          Cancel Subscription
                        </FButtonPrimary>
                        <label style={{ paddingLeft: "120px",  color: "#FFFFFF", }}>
                          Plan Expires on: {this.props.SubscriptionEndDate[i]}
                          <br />
                          File upload remaining:{" "}
                          {parseInt(this.props.UserCredits) /
                            constants.Development.perModelPrice}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <PayPalButton
                        options={{ vault: true }}
                        style={{
                          shape: "pill",
                          color: "blue",
                          layout: "horizontal",
                          label: "subscribe",
                          pointerEvents: "none",
                        }}
                        onClick={() => this.handleClick()}
                        onInit={(data, actions) => this.handleOnInit(actions)}
                        createSubscription={(data, actions) =>
                          this.createProSubscription(data, actions)
                        }
                        onApprove={(data, actions) =>
                          this.onApproveSubscription(data, actions)
                        }
                        onCancel={() => this.onCancel()}
                        onError={(error) => this.onError(error)}
                      />
                      {this.props.ActivePlan[i] ==
                        constants.Development.subscriptionPlanCancel &&
                      this.props.SubscribedPlanId[i] ===
                        constants.Development.subscriptionProPlanID ? (
                        <div>
                          <h5 style={{ textAlign: "center" }}>
                            Your subscription is inactive
                          </h5>
                          <label style={{ paddingLeft: "120px",  color: "#FFFFFF", }}>
                            Plan Expires on: {this.props.SubscriptionEndDate[i]}
                            <br />
                            File upload remaining:{" "}
                            {parseInt(this.props.UserCredits) /
                              constants.Development.perModelPrice}
                          </label>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </Container>
            </Grid>
          </div>
        )}

        {/* Footer */}
        {/* <div class="row footer">
          <div class="comapany-info col-12 col-sm-12 col-md-4">
            Cotrinity (Planning) LTD
            <br />
            Company number: 12909153
          </div>
          <div class="copyright col-12 col-sm-12 col-md-4">
            Copyright © Cotrinity 2021
            <br />
            Unit 9a Reeds Farm Roxwell Road, Writtle, Chelmsford, Essex, United
            Kingdom
          </div>
        </div> */}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //notify: data => dispatch(Actions.notify(data)),
    getModelList: (userId) => dispatch(getModelList(userId)),
    setSubscription: (
      UserId,
      PlanId,
      SubscriptionStartDate,
      SubscriptionEndDate,
      UserCredits,
      ActivePlan,
      SubscriptionId,
      objExportCount
    ) =>
      dispatch(
        setSubscription(
          UserId,
          PlanId,
          SubscriptionStartDate,
          SubscriptionEndDate,
          UserCredits,
          ActivePlan,
          SubscriptionId,
          objExportCount
        )
      ),
    updateSubscription: (
      index,
      UserId,
      PlanId,
      SubscriptionStartDate,
      SubscriptionEndDate,
      UserCredits,
      ActivePlan,
      SubscriptionId
    ) =>
      dispatch(
        updateSubscription(
          index,
          UserId,
          PlanId,
          SubscriptionStartDate,
          SubscriptionEndDate,
          UserCredits,
          ActivePlan,
          SubscriptionId
        )
      ),
    deleteSubscription: (index, UserId, PlanId) =>
      dispatch(deleteSubscription(index, UserId, PlanId)),
    getSubscription: (UserId) => dispatch(getSubscription(UserId)),
    getPlanDetails: (email) => dispatch(getPlanDetails(email)),
  };
}

const mapStateToProps = (state) => {
  const {
    SubscribedPlanId,
    SubscriptionStartDate,
    SubscriptionEndDate,
    UserCredits,
    ActivePlan,
    SubscriptionId,
  } = state.subscription;
  const { modelList } = state.models;
  const { PlanId, PlanName, PlanPricing } = state.plan;
  return {
    PlanId,
    SubscriptionStartDate,
    PlanName,
    PlanPricing,
    SubscriptionEndDate,
    SubscribedPlanId,
    modelList,
    UserCredits,
    ActivePlan,
    SubscriptionId,
  };
};

PSubscription.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  userProfileData: PropTypes.object,
  appState: PropTypes.object,
  updateUserProfileData: PropTypes.func,
};

const Subscription = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PSubscription));

export default scriptLoader(script)(Subscription);
