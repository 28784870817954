import axios from "axios";
import { urlConfig } from "../constants/urlConstants";
import AWS from "aws-sdk";
import Client from "../components/Client";

const s3 = new AWS.S3({
  accessKeyId: "AKIA6BXWWFLFFRQBA4LK",
  secretAccessKey: "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",
  region: "eu-west-2",
});

const reduceUserExportCount = (count) => {
  let userId = localStorage.getItem("subId");
  count--;
  const data = {
    UserId: userId,
    ObjExportCount: count,
  };

  const config = {
    method: "post",
    url: urlConfig.url.API_UPDATE_EXPORT_COUNT,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios(config).then((res) => {
    if (res.status === 200) {
    } else {
      console.log(
        "Error occured while updating export count to database ",
        res
      );
    }
  });
};

const getURN = (fileName) => {
  const userId = localStorage.getItem("subId");
  const config = {
    method: "get",
    url: urlConfig.url.API_GET_MODEL_LIST + `${userId}&displayName=${fileName}`,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

const getManifest = (urn) => {
  return new Promise((resolve, reject) => {
    axios.get("/token").then(async (response) => {
      const token = JSON.parse(response.data);
      if (!token) return;
      let url =
        "https://developer.api.autodesk.com/modelderivative/v2/regions/eu/designdata/";

      const config = {
        method: "get",
        url: url + urn + "/manifest",
        headers: {
          Authorization: "Bearer " + token.access_token,
        },
      };
      return axios(config)
        .then(async (response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              resolve(true);
              return;
            } else if (response.data.status === "failed") {
              resolve(false);
              return;
            }
          }
          reject(false);
        })
        .catch((err) => {
          console.log("Error in getting manifest ", err);
          reject(false);
        });
    });
  });
};

const getTempId = async (modelId, viewId) => {
  const userId = localStorage.getItem("subId");

  const data = JSON.stringify({
    userId: userId,
    viewId: viewId,
    modelId: modelId,
  });

  const config = {
    method: "post",
    url: urlConfig.url.API_PUT_TEMPID,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

const checkViewerValidation = (param) => {
  const config = {
    method: "get",
    url: urlConfig.url.API_MODEL_CHECK_LINK + param,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

const getSavedView = (modelId, viewId = "") => {
  const config = {
    method: "get",
    url: urlConfig.url.API_GET_SAVED_VIEW_LIST + modelId + `&ViewId=${viewId}`,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

const getModelList = async (userId) => {
  await axios
    .get(urlConfig.url.API_GET_MODEL_LIST + userId)
    .then(async (response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      return error;
    });
};
// const downloadFromS3Bucket = async (param) => {
//   const options = {
//     Bucket: param.bucket, // pass your bucket name
//     Key: "5.jpg", // file will be saved as testBucket/contacts.csv
//   };
//   //Download the file/object
//   s3.getObject(options, function (err, data) {
//     let blob = new Blob([data.Body], { type: data.ContentType });
//     let link = document.createElement("a");
//     link.href = window.URL.createObjectURL(blob);
//     link.download =
//       "https://s3.console.aws.amazon.com/s3/object/digitalflowplanning?region=eu-west-2&prefix=modelproperties/5.jpg";
//     link.click();
//     if (err) console.log(err, err.stack);
//   });
// };
const uploadToS3Bucket = async (param) => {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: param.Bucket, // pass your bucket name
      Key: param.Key, // file will be saved as testBucket/contacts.csv
      Body: param.Body,
    };
    //Upload file
    s3.upload(params).send((err, data) => {
      if (err) {
        reject(err);
      }
      var timeInMilliseconds = Number(new Date());
      var date = new Date(timeInMilliseconds);
      var timestamp = date.toUTCString();
      resolve({ Location: data.Location, TimeStamp: timestamp });
    });
  });
};

const updateModelProperties = async (
  ModelId,
  ModelURN,
  ModelName,
  S3Link,
  TimeStamp
) => {
  try {
    const data = {
      ModelId: ModelId,
      ModelURN: ModelURN,
      ModelName: ModelName,
      S3Link: S3Link,
      TimeStamp: TimeStamp,
    };

    const config = {
      method: "post",
      url: urlConfig.url.API_UPDATE_MODELPROPERTIES,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config).then((res) => {
      if (res.status === 200) {
      } else {
        console.log(
          "Error occured while updating model properties to database ",
          res
        );
      }
    });
  } catch (err) {
    console.log("Error in updating model properties ", err);
  }
};

const getCurrentSubScriptionDetails = async (UserId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        method: "get",
        url: urlConfig.url.API_GET_SUBSCRIPTION + UserId,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          console.log("Error fetching subscriptions from database ", res);
          reject();
        }
      });
    } catch (err) {
      console.log("Catch Error in fetching subscriptions from database ", err);
      throw err;
    }
  });
};

export {
  getURN,
  getTempId,
  checkViewerValidation,
  getSavedView,
  getModelList,
  uploadToS3Bucket,
  // downloadFromS3Bucket,
  updateModelProperties,
  getManifest,
  getCurrentSubScriptionDetails,
  reduceUserExportCount,
};
