import ReactDOM from "react-dom";
import React from "react";
import ExtensionStoreProvider from "./provider";
import ReactPanel from "./views/reactPanel";
import MapPanel from "./mapExtension/reactPanel";
import FilterExtensionPanel from "./filterExtension/reactPanel";
import TemplateExtensionPanel from "./customParameter/templates/reactPanel";
import CustomPropertiesExtensionPanel from "./customParameter/properties/reactPanel";
import { modelHierarchy } from "./Viewer-helpers";
import "./style.scss";
import { splitURN } from "../../utils/api";
import { store } from "../..";
const Autodesk = window.Autodesk;
var listDisplayed = false;
var reactComponentNode = null;

function BuildingToolbarExtension(viewer, options) {
  Autodesk.Viewing.Extension.call(this, viewer, options);
  this.panel = null;
  listDisplayed = false;
  this.templatePanel = null;
  this.customPropertiesPanel = null;
  this.filterPanel = null;
  reactComponentNode = null;
}

BuildingToolbarExtension.prototype = Object.create(
  Autodesk.Viewing.Extension.prototype
);
BuildingToolbarExtension.prototype.constructor = BuildingToolbarExtension;

BuildingToolbarExtension.prototype.load = function () {
  // Set background environment to "Infinity Pool"
  // and make sure the environment background texture is visible
  this.viewer.setLightPreset(17);
  this.viewer.setEnvMapBackground(true);
  var viewer = this.viewer;

  viewer.registerContextMenuCallback("Custom Properties ", (menu, status) => {
    if (status.hasSelected) {
      menu.push({
        title: "Custom Properties",
        target: () => {
          if (!this.customPropertiesPanel) {
            this.customPropertiesPanel = new CustomPropertiesExtensionPanel(
              viewer,
              {
                id: "react-panel-id",
                title: "Custom Properties",
              }
            );
            this.customPropertiesPanel.setVisible(true);
          }
          if (!this.customPropertiesPanel.isVisible()) {
            this.customPropertiesPanel.setVisible(true);
          }
          this.customPropertiesPanel.closer.addEventListener(
            "click",
            function () {
              let storeState = store.getState();
              if (viewer && storeState) {
                clearColours(viewer);
                let switchState = document.getElementById("colourToggleSwitch");
                if (switchState.checked)
                  setCustomPropertyColours(
                    viewer,
                    storeState.customProperties,
                    storeState.models.selectedModel
                  );
              }
            }
          );
        },
      });
    }
  });

  return true;
};

BuildingToolbarExtension.prototype.unload = function () {
  // nothing yet
  if (this.subToolbar) {
    this.viewer.toolbar.removeControl(this.subToolbar);
    this.subToolbar = null;
  }
};

BuildingToolbarExtension.prototype.onToolbarCreated = function (toolbar) {
  var comboButton = new Autodesk.Viewing.UI.ComboButton("buildings");
  comboButton.setToolTip("Show More");
  comboButton.icon.classList.add("fa", "fa-ellipsis-h");
  let viewer = this.viewer;
  let viewPanel = null;
  let mapPanel = null;

  // Button 1 Show views
  let button1 = new Autodesk.Viewing.UI.Button("show-env-bg-button");
  button1.icon.classList.add("fa", "fa-list");
  button1.onClick = function (e) {
    let viewButtonState = document.getElementById("show-env-bg-button");
    if (!viewPanel) {
      viewPanel = new ReactPanel(viewer, {
        id: "react-panel-id",
        title: "Views",
      });
    }
    if (!viewPanel.isVisible()) {
      viewButtonState.classList.add("active");
    } else {
      viewButtonState.classList.remove("active");
    }
    // show/hide docking panel
    viewPanel.setVisible(!viewPanel.isVisible());
    viewPanel.closer.addEventListener("click", function () {
      viewButtonState.classList.remove("active");
    });
  };
  //Button 2 Show Map
  let showMapBtn = new Autodesk.Viewing.UI.Button("show-map-button");
  showMapBtn.icon.classList.add("fa", "fa-map");
  showMapBtn.onClick = function (e) {
    let mapButtonState = document.getElementById("show-map-button");
    if (!mapPanel) {
      mapPanel = new MapPanel(viewer, {
        id: "map-panel",
        title: "Geolocation",
      });
    }
    if (!mapPanel.isVisible()) {
      mapButtonState.classList.add("active");
    } else {
      mapButtonState.classList.remove("active");
    }
    // show/hide docking panel
    mapPanel.setVisible(!mapPanel.isVisible());
    mapPanel.closer.addEventListener("click", function () {
      mapButtonState.classList.remove("active");
    });
  };

  // Button 3 Floor Plan Split
  var levelButton = new Autodesk.Viewing.UI.Button("show-level-button");
  levelButton.icon.classList.add("fa", "fa-bars");

  levelButton.onClick = function (e) {
    let levelButtonState = document.getElementById("show-level-button");

    if (!listDisplayed) {
      viewer.showAll();
      listDisplayed = true;
      levelButtonState.classList.add("active");
      reactComponentNode = ReactDOM.render(
        <ExtensionStoreProvider
          id={9}
          viewer={viewer}
          listDisplayed={listDisplayed}
          levelButton={levelButton}
        />,
        document.getElementById("floorPlan")
      );
    } else if (listDisplayed && reactComponentNode) {
      viewer.showAll();
      listDisplayed = false;
      levelButtonState.classList.remove("active");
      ReactDOM.unmountComponentAtNode(document.getElementById("floorPlan"));

      reactComponentNode = null;
    }
  };

  // Button 4 Filter Extension
  let filterExtensionButton = new Autodesk.Viewing.UI.Button(
    "show-filter-button"
  );
  filterExtensionButton.icon.classList.add("fa", "fa-filter");
  filterExtensionButton.onClick = function (e) {
    let filterButtonState = document.getElementById("show-filter-button");
    if (!this.filterPanel) {
      this.filterPanel = new FilterExtensionPanel(viewer, {
        id: "react-panel-id",
        title: "Filter Options",
      });
    }
    if (!this.filterPanel.isVisible()) {
      filterButtonState.classList.add("active");
    } else {
      filterButtonState.classList.remove("active");
    }
    this.filterPanel.setVisible(!this.filterPanel.isVisible());
    this.filterPanel.closer.addEventListener("click", function () {
      if (viewer) clearColours(viewer);
      filterButtonState.classList.remove("active");
    });
  };

  //Button 5 Templates
  let templateExtensionButton = new Autodesk.Viewing.UI.Button(
    "template-button"
  );
  templateExtensionButton.icon.classList.add("fab", "fa-wpforms");
  if (!window.location.href.includes("shared_viewer")) {
    templateExtensionButton.onClick = function (e) {
      let templateButtonState = document.getElementById("template-button");
      if (!this.templatePanel) {
        this.templatePanel = new TemplateExtensionPanel(viewer, {
          id: "react-panel-id",
          title: "Edit Template",
        });
      }
      if (!this.templatePanel.isVisible()) {
        templateButtonState.classList.add("active");
      } else {
        templateButtonState.classList.remove("active");
      }
      this.templatePanel.setVisible(!this.templatePanel.isVisible());
      this.templatePanel.closer.addEventListener("click", function () {
        // if (viewer) clearColours(viewer);
        templateButtonState.classList.remove("active");
      });
    };
  }

  // Button 6 Export to Excel Dashboard
  const url = window.location.pathname;
  // const url = window.location.qu;

  let urlParams = splitURN(url);
  let urlIds = urlParams.urlIds;
  if (urlIds.length === 1) {
    var buttonExportExcel = new Autodesk.Viewing.UI.Button("show-form-button");
    buttonExportExcel.icon.classList.add("fa", "fa-file-excel");
    buttonExportExcel.onClick = async function (e) {
      const buttonExelExportState = document.getElementById("show-form-button");
      if (document.getElementById("maindashcontainer") != null) {
        var dashboardDiv = document.getElementById("maindashcontainer");
        ReactDOM.unmountComponentAtNode(dashboardDiv);
        dashboardDiv.remove();

        document.getElementsByClassName("adsk-viewing-viewer")[0].style.width =
          "100%";
        buttonExelExportState.classList.remove("active");

        if (document.getElementById("colourToggleDiv"))
          document.getElementById("colourToggleDiv").style.display = "";
        viewer.resize();
        document.getElementById("modelListShowDivIcon2").style.display =
          "block";
      } else {
        if (document.getElementById("modelListShowDivIcon")) {
          document.getElementById("modelListShowDivIcon").style.display =
            "none";
        } else if (document.getElementById("modelListShowDivIcon2")) {
          document.getElementById("modelListShowDivIcon2").style.display =
            "none";
        }

        if (document.getElementById("modelListShowDivIcon")) {
          document.getElementById("modelListShowDivIcon").click();
        }
        buttonExelExportState.classList.add("active");
        addElement(viewer);
        if (document.getElementById("colourToggleDiv"))
          document.getElementById("colourToggleDiv").style.display = "none";

        viewer.resize();
      }
    };
    buttonExportExcel.addClass("my-view-front-button");
    buttonExportExcel.setToolTip("Excel Export");
    comboButton.addControl(buttonExportExcel);
  }

  button1.setToolTip("Show Views");
  comboButton.addControl(button1);
  showMapBtn.setToolTip("Show Geolocation");
  comboButton.addControl(showMapBtn);
  levelButton.setToolTip("Floor Plan Split");
  comboButton.addControl(levelButton);
  filterExtensionButton.setToolTip("Filter");
  comboButton.addControl(filterExtensionButton);
  templateExtensionButton.setToolTip("Custom Parameter Template");
  comboButton.addControl(templateExtensionButton);

  comboButton._isCollapsed = true;
  // SubToolbar
  this.subToolbar = new Autodesk.Viewing.UI.ControlGroup("my-custom-toolbar");

  this.subToolbar.addControl(comboButton);
  toolbar.addControl(this.subToolbar);
};

function clearColours(viewer) {
  const url = window.location.pathname;
  let urlParams = splitURN(url);
  let urlIds = urlParams.urlIds;

  urlIds.forEach((urn) => {
    if (modelHierarchy[urn]) {
      let toggeledModel = viewer.impl
        .modelQueue()
        .findModel(modelHierarchy[urn].modelId);
      if (toggeledModel) {
        viewer.clearThemingColors(toggeledModel);
      }
    }
  });
}

function checkModel(id, selectedModel) {
  let model = null;
  if (selectedModel) {
    model = selectedModel.find((item) => item.uniqueId === id);
  }
  if (model) return model.modelURN;
  else return model;
}

function setCustomPropertyColours(viewer, customProperties, selectedModel) {
  customProperties.templateId.forEach((temp, index) => {
    let curModelId = customProperties.tempModelId[index];
    let modelUrn = checkModel(curModelId, selectedModel);
    let toggeledModel = null;
    if (modelHierarchy[modelUrn])
      toggeledModel = viewer.impl
        .modelQueue()
        .findModel(modelHierarchy[modelUrn].modelId);
    if (toggeledModel && customProperties.propertiesValuesList[temp]) {
      toggeledModel.getObjectTree(function (objectTree) {
        customProperties.propertiesValuesList[temp].forEach((item) => {
          if (objectTree.getChildCount(parseInt(item.id)) === 0) {
            item.values.forEach(function (itemValue) {
              if (
                itemValue &&
                itemValue.color &&
                itemValue.color.rgb &&
                itemValue.toggle
              ) {
                const r = itemValue.color.rgb.r / 255;
                const g = itemValue.color.rgb.g / 255;
                const b = itemValue.color.rgb.b / 255;
                var themeColor = new Window.THREE72.Vector4(r, g, b, 1);
                if (toggeledModel)
                  toggeledModel.setThemingColor(
                    parseInt(item.id),
                    themeColor,
                    toggeledModel
                  );
                setTimeout(() => {
                  viewer.impl.invalidate(true);
                }, 10);
              }
            });
          }
        });
      });
    }
  });
  customProperties.multipleTemplateId.forEach((temp, index) => {
    if (customProperties.propertiesValuesList[temp]) {
      customProperties.propertiesValuesList[temp].forEach((item) => {
        let modelUrn = checkModel(item.modelId, selectedModel);

        let toggeledModel = null;
        if (modelHierarchy[modelUrn])
          toggeledModel = viewer.impl
            .modelQueue()
            .findModel(modelHierarchy[modelUrn].modelId);
        if (toggeledModel) {
          toggeledModel.getObjectTree(function (objectTree) {
            if (objectTree.getChildCount(parseInt(item.id)) === 0) {
              item.values.forEach((itemValue) => {
                if (
                  itemValue &&
                  itemValue.color &&
                  itemValue.color.rgb &&
                  itemValue.toggle
                ) {
                  const r = itemValue.color.rgb.r / 255;
                  const g = itemValue.color.rgb.g / 255;
                  const b = itemValue.color.rgb.b / 255;
                  var themeColor = new Window.THREE72.Vector4(r, g, b, 1);
                  if (toggeledModel)
                    toggeledModel.setThemingColor(
                      parseInt(item.id),
                      themeColor,
                      toggeledModel
                    );
                }
              });
            }
          });
        }
      });
    }
  });
  viewer.setDisplayEdges(true);
}

function addElement(viewer) {
  // create the main div
  //const mediaQuery = window.matchMedia('(max-width: 768px)')
  if (window.innerWidth < 768) {
    document.getElementsByClassName("adsk-viewing-viewer")[0].style.width =
      "0%";
  } else {
    document.getElementsByClassName("adsk-viewing-viewer")[0].style.width =
      "50%";
  }

  const newDiv = document.createElement("div");

  newDiv.classList.add("col");
  newDiv.classList.add("fill");
  newDiv.setAttribute("id", "maindashcontainer");
  ReactDOM.render(<ExtensionStoreProvider id={11} viewer={viewer} />, newDiv);

  // add the newly created element and its content into the DOM
  const currentDiv = document.getElementById("viewerDiv");
  currentDiv.appendChild(newDiv);
}

export function setListDisplayed(value) {
  listDisplayed = value;
  ReactDOM.unmountComponentAtNode(document.getElementById("floorPlan"));
  reactComponentNode = null;
}

Autodesk.Viewing.theExtensionManager.registerExtension(
  "BuildingToolbarExtension",
  BuildingToolbarExtension
);

export default BuildingToolbarExtension;
