import { produce } from "immer";
import {
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SIGN_OUT
} from "../actions/accountActions";

const initialState = {
  user: null,
  sentEmail: null,
  changedPassword: null,
  validationsMessage: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.validationsMessage = null;
      });
    }
    case SIGN_UP_SUCCESS: {
      return produce(state, (draft) => {
        draft.user = action.payload;
      });
    }
    case SIGN_UP_FAILURE: {
      return produce(state, (draft) => {
        draft.validationsMessage = action.payload;
      });
    }
    case SIGN_IN: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.validationsMessage = null;
      });
    }
    case SIGN_IN_SUCCESS: {
      return produce(state, (draft) => {
        draft.user = action.payload;
      });
    }
    case SIGN_IN_FAILURE: {
      return produce(state, (draft) => {
        draft.validationsMessage = action.payload;
      });
    }
    case SIGN_OUT: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.validationsMessage = null;
      });
    }
    case PASSWORD_RECOVERY: {
      return produce(state, (draft) => {
        draft.sentEmail = null;
        draft.validationsMessage = null;
      });
    }
    case PASSWORD_RECOVERY_SUCCESS: {
      return produce(state, (draft) => {
        draft.sentEmail = action.payload;
      });
    }
    case PASSWORD_RECOVERY_FAILURE: {
      return produce(state, (draft) => {
        draft.validationsMessage = action.payload;
      });
    }
    case CHANGE_PASSWORD: {
        return produce(state, (draft) => {
          draft.validationsMessage = null;
          draft.changedPassword = null;
        });
      }
      case CHANGE_PASSWORD_SUCCESS: {
        return produce(state, (draft) => {
          draft.changedPassword = action.payload;
        });
      }
      case CHANGE_PASSWORD_FAILURE: {
        return produce(state, (draft) => {
          draft.validationsMessage = action.payload;
        });
      }
    default: {
      return state;
    }
  }
};

export default accountReducer;
