import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  entitiesPropertiesList,
  modelHierarchy,
  viewer,
} from "../Viewer-helpers";
import { connect } from "react-redux";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import { constants } from "../../Subscription/config";
import {
  getTemplate,
  getCustomProperties,
} from "../../../actions/customParameterActions";
import { splitURN } from "../../../utils/api";
import { getFolders, setCurrentFolder } from "../../../actions/folderActions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getModelList } from "../../../actions/modelsActions";
import { getSubscription } from "../../../actions/subscriptionActions";
import { Oval } from "react-loader-spinner";
import CheckboxTree from "react-checkbox-tree";
import "./style.scss";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

//Theme Need For Table Creation
let theme = createTheme();
theme = responsiveFontSizes(theme);
const userId = localStorage.getItem("subId");

class PTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      optionSelected: [],
      data: [],
      columns: [],
      loader: false,
      checked: [],
      expanded: [],
      drowpDown: false,
      nodes: [],
      exportTreeData: [],
      customPropertiesAdded: false,
      dataLoadedFlag: false,
      allTargetNodes: [],
      checkBoxChecked: false,
      checkBoxColumn: [],
      holder: [],
      modal: false,
      validUser: false,
      validDbIds: [],
      rowPerPage: 20,
    };
  }

  async componentDidMount() {
    let epl = entitiesPropertiesList;
    const email = localStorage.getItem("email");
    if (
      !(
        email === "warren.betts@digitalflowplanning.com" ||
        email === "warren.betts@cotrinity.com"
      ) &&
      this.props.SubscriptionDetailsFetched > 0 &&
      (this.props.SubscribedPlanId[0] ===
        constants.Development.subscriptionTrialPlanID ||
        this.props.SubscribedPlanId[0] ===
          constants.Development.subscriptionLitePlanID)
    ) {
      this.setState({ validUser: true });
      this.setState({
        modal: true,
      });
    }

    this.props.getSubscription(userId);

    if (
      this.props.SubscribedPlanId[0] ===
        constants.Development.subscriptionTrialPlanID ||
      this.props.SubscribedPlanId[0] ===
        constants.Development.subscriptionLitePlanID
    ) {
    }
    if (!this.props.modelList) {
      this.props.getModelList(localStorage.getItem("subId"));
    }
    if (!this.props.folderId.length) {
      this.props.getFolders(localStorage.getItem("subId"));
    }

    const url = window.location.pathname;
    let urlParams = splitURN(url);
    let urlIds = urlParams.urlIds;

    urlIds.forEach((urn, index) => {
      let model = this.findModel(urn);
      if (model) {
        if (
          this.props.templatesFetched < 0 ||
          this.props.propertyValuesFetched < 0
        ) {
          this.props.getTemplate(model.uniqueId);
          this.props.getCustomProperties(model.uniqueId);
        }
      }
    });

    if (this.props.fetchFoldersStatus) {
      let model = this.findModel(urlIds[0]);
      this.props.setCurrentFolder(this.findFolder(model));
      let selectFolder = parseInt(this.props.selectedFolder);
      if (selectFolder > -1) {
        this.props.getTemplate(this.props.folderId[selectFolder], true);
      }
    }

    let propertyNamesList = [];
    Object.keys(epl).forEach((key) => {
      Object.keys(epl[key]).forEach((entity) => {
        Object.keys(epl[key][entity]).forEach((propertyName) => {
          if (!propertyNamesList.includes(propertyName))
            propertyNamesList.push(propertyName);
        });
      });
    });
    propertyNamesList.sort();
    const newTreeData = [];
    // let etd = exportTreeData;
    let etd = await this.buildTreeView();
    if (!etd) return;
    for (const category of etd[0].children) {
      if (category.children) {
        const teamp = category.children.filter((child) => {
          return propertyNamesList.find((propertyName) => {
            return propertyName === child.label;
          });
        });
        if (teamp.length > 0) {
          newTreeData.push({
            label: category.label,
            value: category.value,
            children: teamp,
          });
        }
      }
    }
    const finalNode = {
      value: "select all",
      label: "select all",
      children: newTreeData,
    };
    if (
      (this.props.templateProperties.length > 0 ||
        this.props.multipleTemplateProperties.length > 0) &&
      !this.state.customPropertiesAdded
    ) {
      let customProperties = [];
      for (let i in this.props.templateProperties) {
        let customPropertiesArray = [];
        this.props.templateProperties[i].map((Element) => {
          if (Element.propertyName !== "")
            customPropertiesArray.push({
              label: Element.propertyName,
              value:
                Element.propertyName +
                "(" +
                this.props.templateName[i] +
                ")" +
                "/custom_properties_child",
              id: this.props.templateId[i],
            });
        });
        customProperties.push({
          value:
            this.props.templateName[i] +
            "(" +
            this.props.templateName[i] +
            ")" +
            "/custom_properties",
          label: this.props.templateName[i],
          id: this.props.templateId[i],
          children: customPropertiesArray,
        });
      }
      for (let i in this.props.multipleTemplateProperties) {
        let customPropertiesArray = [];
        this.props.multipleTemplateProperties[i].map((Element) => {
          if (Element.propertyName !== "")
            customPropertiesArray.push({
              label: Element.propertyName,
              value:
                Element.propertyName +
                "(" +
                this.props.multipleTemplateName[i] +
                ")" +
                "/custom_properties_multi_child",
              id: this.props.multipleTemplateId[i],
            });
        });
        customProperties.push({
          value:
            this.props.multipleTemplateName[i] +
            "(" +
            this.props.multipleTemplateName[i] +
            ")" +
            "/custom_properties_multi",
          label: this.props.multipleTemplateName[i],
          id: this.props.multipleTemplateId[i],
          children: customPropertiesArray,
        });
      }
      const newA = {
        label: "Custom Properties",
        value: "custom_properties",
        children: customProperties,
      };
      finalNode.children.push(newA);
      this.setState({ customPropertiesAdded: true });
    }
    this.setState({
      // options: options,
      loader: false,
      nodes: [finalNode],
      dataLoadedFlag: true,
    });
  }

  async getPropertySet(allDbIds) {
    return new Promise(async (resolve, reject) => {
      const perLoopDbids = 10000;
      var count = allDbIds.length / perLoopDbids;
      var allProperties = {};
      for (var i = 0; i < count; i++) {
        var tempDbIds = allDbIds.splice(0, perLoopDbids);

        var propsSet = await this.props.viewer.model.getPropertySetAsync(
          tempDbIds
        );

        allProperties = { ...allProperties, ...propsSet.map };
      }

      resolve(allProperties);
    });
  }

  async buildTreeView() {
    try {
      let allDbIds = Object.keys(
        entitiesPropertiesList[Object.keys(entitiesPropertiesList)[0]]
      );
      allDbIds = allDbIds.map((num) => {
        return parseInt(num, 10);
      });

      let treeData = [];

      // let temp = null;
      // temp = await this.props.viewer.model.getPropertySetAsync(allDbIds);
      let temp = await this.getPropertySet(allDbIds);

      // let allDb = [];
      // Object.keys(temp).map((element, index) => {
      //   temp[element].map((entity) => {
      //     let dbId = entity.dbId;
      //     if (!allDb.includes(dbId)) {
      //       allDb.push(dbId);
      //     }
      //   });
      // });

      // this.setState({
      //   validDbIds: allDb,
      // });

      let treeNode = Object.keys(temp);

      treeNode.map((element) => {
        let splitNode = element.split("/");
        let tempLabel = splitNode[0]; //Category
        let tempValue = splitNode[1]; //Child of category(PropertyName)
        if (splitNode[1] === undefined) {
          tempValue = " ";
          splitNode[1] = " ";
        }

        if (treeData.length > 0) {
          const parent = treeData
            .find((test) => {
              if (test.label === "select all") {
                return test;
              }
            })
            .children.find((child) => {
              if (child.label === splitNode[0]) return child;
            });

          if (parent) {
            if (
              treeData
                .find((test) => {
                  if (test.label === "select all") {
                    return test;
                  }
                })
                .children.find((child) => {
                  if (child.label === splitNode[0]) return child;
                }).children === undefined
            )
              treeData
                .find((test) => {
                  if (test.label === "select all") {
                    return test;
                  }
                })
                .children.find((child) => {
                  if (child.label === splitNode[0]) return child;
                }).children = [];

            if (
              !treeData
                .find((test) => {
                  if (test.label === "select all") {
                    return test;
                  }
                })
                .children.find((child) => {
                  if (child.label === splitNode[0]) return child;
                })
                .children.find((grandChild) => {
                  if (grandChild.value === tempValue) return grandChild;
                })
            ) {
              const math = Math.floor(Math.random() * 100000);
              treeData
                .find((test) => {
                  if (test.label === "select all") {
                    return test;
                  }
                })
                .children.find((child) => {
                  if (child.label === splitNode[0]) return child;
                })
                .children.push({
                  value: tempValue + "/" + tempLabel + "/" + math,
                  label: splitNode[1],
                });
            }
          } else {
            const math = Math.floor(Math.random() * 100000);
            treeData
              .find((test) => {
                if (test.label === "select all") return test;
              })
              .children.push({
                value: tempLabel + "//" + math,
                label: splitNode[0],
                children: [
                  {
                    value: tempValue + "/" + tempLabel + "/" + math,
                    label: splitNode[1],
                  },
                ],
              });
          }
        } else {
          const math = Math.floor(Math.random() * 100000);
          treeData.push({
            value: "select all",
            label: "select all",
            children: [
              {
                value: tempLabel + "//" + math,
                label: splitNode[0],
                children: [
                  {
                    value: tempValue + "/" + tempLabel + "/" + math,
                    label: splitNode[1],
                  },
                ],
              },
            ],
          });
        }
      });
      return treeData;
    } catch (err) {
      console.log("Error in property set ", err);
    }
  }

  findModel = (urn) => {
    let model = null;
    if (this.props.modelList) {
      model = this.props.modelList.find((item) => item.modelURN === urn);
    }

    return model;
  };

  findFolder = (model) => {
    let index = -1;
    if (model) {
      if (this.props.folderModelList) {
        index = this.props.folderModelList.findIndex((item) =>
          item.includes(model.uniqueId)
        );
      }
    }
    return index;
  };

  checkChildren = (id, urn) => {
    let recursiveChildren = (ob) => {
      for (let i in ob) {
        if (ob[i].dbId === id) {
          foundName = ob[i].name + "/" + ob[i].externalId;
          break;
        }
        if (ob[i].children) {
          recursiveChildren(ob[i].children);
        }
      }
    };
    let foundName = "";
    let mh = modelHierarchy;
    if (mh[urn] && mh[urn].children) recursiveChildren(mh[urn].children);
    else if (Array.isArray(mh[urn])) {
      for (let j in mh[urn]) {
        recursiveChildren(mh[urn][j].children);
      }
    }
    return foundName;
  };

  handleChange = (selected, targetNode) => {
    //start outside click of select drowpdown
    // const concernedElement = document.querySelector(".selectWrapper");
    // const concernedElement2 = document.querySelector(".multiSelecTreetWrapper");
    // document.addEventListener("mousedown", (e) => {
    //   if (
    //     !concernedElement.contains(e.target) &&
    //     !concernedElement2.contains(e.target)
    //   ) {
    //     this.setState({
    //       drowpDown: false,
    //     });
    //   }
    // });
    //end outside click of select drowpdown
    let columns = [];
    let tempTable = [];
    let propertyParent = [];
    let columnNames = [];
    let parentName;
    let counter = 0;
    if (selected && selected.length) {
      columnNames.push("Element Name");
      columnNames.push("Element ID");
    }

    // value selected on drowpdown

    selected.map((item, i) => {
      let splitNode = item.split("/");

      columns.push(splitNode[0]);
      propertyParent.push(splitNode[1]);
      parentName = splitNode[1];
      if (
        splitNode[1] !== "custom_properties" &&
        splitNode[1] !== "custom_properties_child" &&
        splitNode[1] !== "custom_properties_multi_child" &&
        splitNode[1] !== "custom_properties_multi"
      )
        columnNames.push(splitNode[0] + "(" + parentName + ")");
      else columnNames.push(splitNode[0]);
    });

    //Entities
    Object.keys(entitiesPropertiesList).forEach(
      (key, entitiesPropertiesListId) => {
        if (entitiesPropertiesList[key] === undefined) {
          return;
        }

        Object.keys(entitiesPropertiesList[key]).forEach((entity, keyId) => {
          let tempObj = {};
          if (entitiesPropertiesList[key][entity]) {
            for (let [childKey, value] of Object.entries(
              entitiesPropertiesList[key][entity]
            )) {
              columns.map((item, i) => {
                if (item === childKey) {
                  let columnKey = columnNames[i + 2];

                  if (value === "") {
                    tempObj[columnKey] = " ";
                  } else {
                    tempObj[columnKey] = value;
                    tempObj["row_id"] = entity;
                  }

                  if (
                    entitiesPropertiesList[key][entity] &&
                    entitiesPropertiesList[key][entity]["UniqueName"]
                  ) {
                    if (
                      !isNaN(entitiesPropertiesList[key][entity]["ExternalId"])
                    ) {
                      const str =
                        entitiesPropertiesList[key][entity]["UniqueName"];
                      tempObj["Element Name"] =
                        entitiesPropertiesList[key][entity]["UniqueName"];
                      tempObj["Element ID"] = str.substring(
                        str.indexOf("[") + 1,
                        str.indexOf("]")
                      )
                        ? str.substring(str.indexOf("[") + 1, str.indexOf("]"))
                        : entitiesPropertiesList[key][entity]["ExternalId"];
                    } else {
                      tempObj["Element Name"] =
                        entitiesPropertiesList[key][entity]["UniqueName"];
                      tempObj["Element ID"] = "";
                    }
                  }
                }
              });
            }
          }
          if (!(Object.keys(tempObj).length === 0)) {
            if (
              (this.state.validUser &&
                this.props.SubscribedPlanId[0] ===
                  constants.Development.subscriptionTrialPlanID) ||
              this.props.SubscribedPlanId[0] ===
                constants.Development.subscriptionLitePlanID
            ) {
              counter++;
              if (counter < 51) {
                tempTable.push(tempObj);
              }
            } else {
              tempTable.push(tempObj);
            }
          }
        });
      }
    );

    //Custom property Single model

    if (
      propertyParent.find((element, index) => element === "custom_properties_child")
    ) {
      this.props.templateProperties.forEach((template, tindex) => {
        let tempObj = {};

        const m = this.props.propertiesValuesList;
        if (this.props.templateId[tindex] && m[this.props.templateId[tindex]]) {
          m[this.props.templateId[tindex]].map((entry) => {
            let createNew = false;
            const index = tempTable.findIndex((row) => {
              return row.row_id === entry.id;
            });

            template.map((customProps, ind) => {
              // TODO: Need to inverigate why this null check is needed.
              if (!entry.values[ind]) return;
              if (index !== -1) {
                if (
                  entry.values[ind] &&
                  entry.values[ind].value !== undefined &&
                  entry.values[ind].value === ""
                ) {
                  tempTable[index][
                    customProps.propertyName +
                      "(" +
                      this.props.templateName[tindex] +
                      ")"
                  ] = ""; // TODO: Check why this was " " instead of ""
                } else {
                  tempTable[index][
                    customProps.propertyName +
                      "(" +
                      this.props.templateName[tindex] +
                      ")"
                  ] =
                    entry.values[ind] && entry.values[ind].value
                      ? entry.values[ind].value
                      : "";
                }
              } else {
                createNew = true
                if (
                  entry.values[ind] &&
                  entry.values[ind].value !== undefined &&
                  entry.values[ind].value === ""
                ) {
                  tempObj[
                    customProps.propertyName +
                      "(" +
                      this.props.templateName[tindex] +
                      ")"
                  ] = ""; // TODO: Check why this was " " instead of ""
                } else {
                  tempObj[
                    customProps.propertyName +
                      "(" +
                      this.props.templateName[tindex] +
                      ")"
                  ] =
                    entry.values[ind] && entry.values[ind].value
                      ? entry.values[ind].value
                      : "";
                }
              }
            });
            if (createNew) {
              tempObj["row_id"] = entry.id;
              let foundName = this.checkChildren(
                parseInt(entry.id),
                Object.keys(entitiesPropertiesList)[0]
              ).split("/");
              tempObj["Element Name"] = foundName[0];
              let str = foundName[0];

              if (foundName[1] && foundName[1] !== undefined)
                tempObj["Element ID"] = str.substring(
                  str.indexOf("[") + 1,
                  str.indexOf("]")
                )
                  ? str.substring(str.indexOf("[") + 1, str.indexOf("]"))
                  : foundName[1];
              else tempObj["Element ID"] = "";

              let pushToTable = false
              selected.map((template) => {
                const key = template.split("/")[0]
                if (key && tempObj[key] && tempObj[key] !== "")
                {
                  pushToTable = true
                }
              })

              if (pushToTable) {
              
              if (!(Object.keys(tempObj).length === 0)) {
                if (
                  (this.state.validUser &&
                    this.props.SubscribedPlanId[0] ===
                      constants.Development.subscriptionTrialPlanID) ||
                  this.props.SubscribedPlanId[0] ===
                    constants.Development.subscriptionLitePlanID
                ) {
                  counter++;
                  if (counter < 51) {
                    tempTable.push(tempObj);
                  }
                } else {
                  tempTable.push(tempObj);
                }
              }
            }
              
              tempObj = {};
            }
          });
        }
        tempObj = {};
      });
    }
    if (
      propertyParent.find(
        (element, index) => element === "custom_properties_multi_child"
      )
    ) {
      this.props.multipleTemplateProperties.forEach((template, tindex) => {
        let tempObj = {};

        const m = this.props.propertiesValuesList;
        if (
          this.props.multipleTemplateId[tindex] &&
          m[this.props.multipleTemplateId[tindex]]
        ) {
          m[this.props.multipleTemplateId[tindex]].map((entry) => {
            let createNew = false;
            const index = tempTable.findIndex((row) => {
              return row.row_id === entry.id;
            });

            template.map((customProps, ind) => {
              if (index !== -1) {
                if (
                  entry.values[ind] &&
                  entry.values[ind].value !== undefined &&
                  entry.values[ind].value === ""
                ) {
                  tempTable[index][
                    customProps.propertyName +
                      "(" +
                      this.props.multipleTemplateName[tindex] +
                      ")"
                  ] = ""; // TODO: Check why this was " " instead of ""
                } else {
                  tempTable[index][
                    customProps.propertyName +
                      "(" +
                      this.props.multipleTemplateName[tindex] +
                      ")"
                  ] =
                    entry.values[ind] && entry.values[ind].value
                      ? entry.values[ind].value
                      : "";
                }
              } else {
                createNew = true;
                if (
                  entry.values[ind] &&
                  entry.values[ind].value !== undefined &&
                  entry.values[ind].value === ""
                ) {
                  tempObj[
                    customProps.propertyName +
                      "(" +
                      this.props.multipleTemplateName[tindex] +
                      ")"
                  ] = ""; // TODO: Check why this was " " instead of ""
                } else {
                  tempObj[
                    customProps.propertyName +
                      "(" +
                      this.props.multipleTemplateName[tindex] +
                      ")"
                  ] =
                    entry.values[ind] && entry.values[ind].value
                      ? entry.values[ind].value
                      : "";
                }
              }
            });
            if (createNew) {
              tempObj["row_id"] = entry.id;

              let foundName = this.checkChildren(
                parseInt(entry.id),
                Object.keys(entitiesPropertiesList)[0]
              ).split("/");

              
              tempObj["Element Name"] = foundName[0];

              let str = foundName[0];
              if (foundName[1] && foundName[1] !== undefined) {
                tempObj["Element ID"] = str.substring(
                  str.indexOf("[") + 1,
                  str.indexOf("]")
                )
                  ? str.substring(str.indexOf("[") + 1, str.indexOf("]"))
                  : foundName[1];
              } else tempObj["Element ID"] = "";
              let pushToTable = false
              selected.map((template) => {
                const key = template.split("/")[0]
                if (key && tempObj[key] && tempObj[key] !== "")
                {
                  pushToTable = true
                }
              })

              if (pushToTable) {
              
              if (!(Object.keys(tempObj).length === 0)) {
                if (
                  (this.state.validUser &&
                    this.props.SubscribedPlanId[0] ===
                      constants.Development.subscriptionTrialPlanID) ||
                  this.props.SubscribedPlanId[0] ===
                    constants.Development.subscriptionLitePlanID
                ) {
                  counter++;
                  if (counter < 51) {
                    tempTable.push(tempObj);
                  }
                } else {
                  tempTable.push(tempObj);
                }
              }

            }
              tempObj = {};
            }
          });
          // if (index === -1) {
          //   tempTable.push(tempObj);
          //   tempObj = {};
          // }
        }

        tempObj = {};
      });
    }

    let holder2 = [];
    Object.keys(tempTable).forEach((entity) => {
      for (const [key, value] of Object.entries(tempTable[entity])) {
        columnNames.map((element) => {
          if (element === key) {
            if (value !== " " && value !== "" ) { // TODO: Check why this was " " instead of "" && " "
              if (!holder2.includes(element)) holder2.push(element);
              return;
            }
          }
        });
      }
    });
    let holder = columnNames.filter((x) => !holder2.includes(x));

    this.setState({
      checked: selected,
      data: tempTable,
      columns: columnNames,
      checkBoxColumn: columnNames,
      holder: holder,
      loader: true,
    });
  };
  selectDrowpDown() {
    this.setState({
      drowpDown: !this.state.drowpDown,
    });
  }
  componentDidUpdate(prevState, prevProps) {
    if (this.state.loader === true) {
      setTimeout(() => {
        this.setState({ loader: false });
      }, 10);
    }
  }
  handleClose() {
    const buttonExelExportState = document.getElementById("show-form-button");
    if (document.getElementById("maindashcontainer") && buttonExelExportState) {
      document.getElementById("modelListShowDivIcon2").style.display = "block";
      var dashboardDiv = document.getElementById("maindashcontainer");
      ReactDOM.unmountComponentAtNode(dashboardDiv);
      dashboardDiv.remove();
      document.getElementsByClassName("adsk-viewing-viewer")[0].style.width =
        "100%";
      buttonExelExportState.classList.remove("active");
      viewer.resize();
      if (document.getElementById("colourToggleDiv"))
        document.getElementById("colourToggleDiv").style.display = "";
    }
  }
  checkBoxClick(event) {
    let holder = this.state.holder;
    let columnNames = this.state.checkBoxColumn;
    columnNames = columnNames.filter((el) => !holder.includes(el));

    this.setState({
      checkBoxColumn: columnNames,
      rowPerPage: this.state.rowPerPage,
      checkBoxChecked: !this.state.checkBoxChecked,
    });
  }
  closeModal() {
    this.setState({
      modal: false,
    });
  }
  render() {
    // const classes = useStyles();
    return (
      <div>
        {!this.state.modal || !this.state.validUser ? (
          <div className="tableWrapper">
            <div
              className="dashboardClose"
              onClick={this.handleClose.bind(this)}
            >
              Close
            </div>
            <div className="checkBox">
              <input
                type="checkbox"
                name="columnBlank"
                value="columnBlank"
                className="checkBoxBlank"
                onClick={this.checkBoxClick.bind(this)}
              />
              <label htmlFor="columnBlank">Hide blank Columns</label>
            </div>
            <div className="titleWrapper">Property Name</div>
            {this.state.dataLoadedFlag ? (
              <div
                className="selectWrapper"
                onClick={this.selectDrowpDown.bind(this)}
              >
                Select Property{" "}
                <i className="fas fa-caret-down iconSelectDrowp"></i>{" "}
              </div>
            ) : (
              <div className="selectWrapper" style={{ color: "darkorange" }}>
                Please wait while data is loading...
              </div>
            )}

            <div
              className="multiSelecTreetWrapper "
              style={{ display: this.state.drowpDown ? "block" : "none" }}
            >
              <CheckboxTree
                nodes={this.state.nodes}
                checked={this.state.checked}
                expanded={this.state.expanded}
                onCheck={this.handleChange.bind(this)}
                onExpand={(expanded) => this.setState({ expanded })}
                icons={{
                  parentClose: <span className="rct-icon rct-icon-leaf" />,
                  parentOpen: <span className="rct-icon rct-icon-leaf" />,
                }}
              />
            </div>

            {/* <MultiSelect
                options={this.state.options}
                value={this.state.optionSelected}
                onChange={this.handleChange.bind(this)}
                onSelect={this.onSelect}
                onRemove={this.onRemove}
                labelledBy={"Select"}
                placeholder ="Select property type"
                className="multiSelectWrapper"  
                
            /> */}
            <ThemeProvider theme={theme}>
              {this.state.loader ? (
                <div className="loaderWrapper">
                  <div className="loaderCenetr">
                    <span>Please Wait Table Data is Loading </span>
                    <Oval color="#00BFFF" height={20} width={20} />
                  </div>
                </div>
              ) : (
                <MUIDataTable
                  title={"Property Table"}
                  data={this.state.data}
                  columns={
                    this.state.checkBoxChecked
                      ? this.state.checkBoxColumn
                      : this.state.columns
                  }
                  options={{
                    selectableRows: "none",
                    print: false,
                    viewColumns: false,
                    sort: false,
                    filter: false,
                    rowsPerPage: this.state.rowPerPage,
                    rowsPerPageOptions: [20, 50, 100, 200],
                    onChangeRowsPerPage: (num) =>
                      this.setState({ rowPerPage: num }),
                    draggableColumns: {
                      enabled: true,
                      transitionTime: 100,
                    },
                    downloadOptions: {
                      filename:
                        viewer.model &&
                        viewer.model
                          .getData()
                          .loadOptions.bubbleNode.getRootNode()
                          .children[0].name()
                          ? viewer.model
                              .getData()
                              .loadOptions.bubbleNode.getRootNode()
                              .children[0].name() + ".csv"
                          : "tableDownload.csv",
                      separator: ",",
                    },

                    //Loader Part
                    textLabels: {
                      body: {
                        noMatch: "No matching data to display",
                      },
                    },

                    // Covert CVS to Excel

                    //     ,onDownload: (buildHead, buildBody, columns, values) => {
                    //     const fileType =
                    //             "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                    //         const fileExtension = ".xlsx";

                    //         console.log(values.forEach(val => console.log(val)));

                    //         const json = values.reduce((result, val) => {
                    //             const temp = {};
                    //             val.data.forEach((v, idx) => {
                    //             temp[columns[idx].name] = v;
                    //             });
                    //             result.push(temp);
                    //             return result;
                    //         }, []);

                    //         const fileName = `Hotkeys`;
                    //         const ws = utils.json_to_sheet(json);
                    //         const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                    //         const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
                    //         const data = new Blob([excelBuffer], { type: fileType });
                    //         saveAs(data, fileName + fileExtension);
                    //         // cancel default  CSV download from table
                    //         return false;
                    //  },
                    //  textLabels: {
                    //    toolbar: {
                    //      downloadCsv: "Download XLSX"
                    //    }
                    //  }
                  }}
                  components={{
                    TableResize: true,
                  }}
                />
              )}
            </ThemeProvider>
          </div>
        ) : (
          <div className="modalExportData">
            <Modal.Dialog>
              <Modal.Body>
                <p>
                  You have currently subscribed to{" "}
                  {this.props.SubscribedPlanId[0] ===
                  constants.Development.subscriptionTrialPlanID
                    ? "Trial plan"
                    : "Lite Plan"}
                  . It supports excel export of upto 50 entities. To export all
                  the entities, upgrade your plan now.
                </p>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={this.closeModal.bind(this)}
                >
                  ok
                </Button>
                <Button variant="primary" href="/subscription">
                  Upgrade Now
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )}
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    //notify: data => dispatch(Actions.notify(data)),
    getSubscription: (UserId) => dispatch(getSubscription(UserId)),
    getModelList: (userId) => dispatch(getModelList(userId)),
    getTemplate: (uniqueId, multiModel) =>
      dispatch(getTemplate(uniqueId, multiModel)),
    getCustomProperties: (uniqueId) => dispatch(getCustomProperties(uniqueId)),
    getFolders: (userId) => dispatch(getFolders(userId)),
    setCurrentFolder: (uniqueId) => dispatch(setCurrentFolder(uniqueId)),
  };
}

const mapStateToProps = (state) => {
  const { modelList } = state.models;
  const {
    tempModelId,
    templateId,
    templateName,
    templateProperties,
    multipleTempModelId,
    multipleTemplateId,
    multipleTemplateName,
    multipleTemplateProperties,
    propertiesValuesList,
    propertyValuesFetched,
    templatesFetched,
  } = state.customProperties;
  const {
    SubscribedPlanId,
    SubscriptionStartDate,
    SubscriptionEndDate,
    UserCredits,
    ActivePlan,
    SubscriptionId,
    SubscriptionDetailsFetched,
  } = state.subscription;
  const { folderId, selectedFolder, fetchFoldersStatus, folderModelList } =
    state.folders;
  return {
    modelList,
    templateName,
    templateProperties,
    SubscriptionDetailsFetched,
    templateId,
    tempModelId,
    multipleTempModelId,
    multipleTemplateId,
    multipleTemplateName,
    multipleTemplateProperties,
    propertiesValuesList,
    propertyValuesFetched,
    templatesFetched,
    folderId,
    selectedFolder,
    fetchFoldersStatus,
    folderModelList,
    SubscriptionStartDate,
    SubscriptionEndDate,
    SubscribedPlanId,
    UserCredits,
    ActivePlan,
    SubscriptionId,
  };
};

const Table = connect(mapStateToProps, mapDispatchToProps)(PTable);

export default Table;
