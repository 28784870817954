import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
import { Environment, constants } from "../components/Subscription/config";
export const SIGN_UP = "@account/sign-up";
export const SIGN_UP_SUCCESS = "@account/sign-up-success";
export const SIGN_UP_FAILURE = "@account/sign-up-failure";
export const SIGN_IN = "@account/sign-in";
export const SIGN_IN_SUCCESS = "@account/sign-in-success";
export const SIGN_IN_FAILURE = "@account/sign-in-failure";
export const SIGN_OUT = "@account/sign-out";
export const SIGN_OUT_SUCCESS = "@account/sign-out-success";
export const SIGN_OUT_FAILURE = "@account/sign-out-failure";
export const PASSWORD_RECOVERY = "@account/password-recovery";
export const PASSWORD_RECOVERY_SUCCESS = "@account/password-recovery-success";
export const PASSWORD_RECOVERY_FAILURE = "@account/password-recovery-failure";
export const CHANGE_PASSWORD = "@account/change-password";
export const CHANGE_PASSWORD_SUCCESS = "@account/change-password-success";
export const CHANGE_PASSWORD_FAILURE = "@account/change-password-failure";
export const GET_SESSION = "@account/get-session";
export const GET_SESSION_SUCCESS = "@account/get-session-success";
export const GET_SESSION_FAILURE = "@account/get-session-failure";

const poolData = {
  UserPoolId: "eu-west-2_Lp7lR3Yvy",
  ClientId: "6n97f0elsb6se1hdakgifcrrsa",
};

const UserPool = new CognitoUserPool(poolData);

export function signUp(email, password, attributes) {
  return async (dispatch) => {
    try {
      dispatch({ type: SIGN_UP });

      await UserPool.signUp(
        email,
        password,
        attributes,
        null,
        async (err, data) => {
          if (err) {
            dispatch({
              type: SIGN_IN_FAILURE,
              payload: err.message,
            });
            await console.error(err);
          }
          if (data) {
            dispatch({
              type: SIGN_UP_SUCCESS,
              payload: {
                data,
              },
            });
          }
        }
      );
    } catch (error) {
      dispatch({
        type: SIGN_UP_FAILURE,
      });
      throw error;
    }
  };
}

export function signIn(username, password, history) {
  return async (dispatch) => {
    try {
      dispatch({ type: SIGN_IN });

      const user = new CognitoUser({
        Username: username,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          dispatch({
            type: SIGN_IN_SUCCESS,
            payload: {
              data,
            },
          });

          localStorage.setItem("accessToken", data.accessToken.jwtToken);
          localStorage.setItem("idToken", data.idToken.jwtToken);
          localStorage.setItem("email", data.idToken.payload.email);
          localStorage.setItem("subId", data.idToken.payload.sub);
          localStorage.setItem(
            "username",
            `${data.idToken.payload.given_name} ${data.idToken.payload.family_name}`
          );
          if (
            !(data.idToken.payload.email === constants.Testing.TestingEmail )
          ) {
            var tag = document.createElement("script");
            tag.src =
              "https://www.paypal.com/sdk/js?client-id=ATGO5eTJpG4nb7Vr32KsKBn0BUOVFXbqJ0OLdMKA6B6TeuJ9s8h6fTkkRw-KXMA7_s3fyDGvt7LueXhL&vault=true&intent=subscription";
            document.getElementsByTagName("head")[0].appendChild(tag);
          } else {
            var tag = document.createElement("script");
            tag.src =
              "https://www.paypal.com/sdk/js?client-id=AWtOe-PosoJBafovUUXsBcaSJuCUsZ3CH6ZrUGg-1I2qyeFPt7-8T1SIR5p-Fu6qXfjbiK7Az5WQVbIF&vault=true&intent=subscription";
            document.getElementsByTagName("head")[0].appendChild(tag);
          }
          history.push("/folders");
        },
        onFailure: (err) => {
          dispatch({
            type: SIGN_IN_FAILURE,
            payload: err.message,
          });
        },
      });
    } catch (error) {
      dispatch({
        type: SIGN_IN_FAILURE,
      });
      throw error;
    }
  };
}

export function signOut() {
  return async (dispatch) => {
    try {
      dispatch({ type: SIGN_OUT });
      localStorage.clear();
      dispatch({
        type: SIGN_OUT_SUCCESS,
      });
      window.location.href = "/login";
    } catch (error) {
      dispatch({
        type: SIGN_OUT_FAILURE,
      });
      throw error;
    }
  };
}

export function passwordRecovery(email) {
  return async (dispatch) => {
    try {
      dispatch({ type: PASSWORD_RECOVERY });

      const user = new CognitoUser({
        Username: email.toLowerCase(),
        Pool: UserPool,
      });

      user.forgotPassword({
        onSuccess: (data) => {
          dispatch({
            type: PASSWORD_RECOVERY_SUCCESS,
            payload: {
              data,
            },
          });
        },
        onFailure: (err) => {
          dispatch({
            type: PASSWORD_RECOVERY_FAILURE,
            payload: err.message,
          });
        },
      });
    } catch (error) {
      dispatch({
        type: PASSWORD_RECOVERY_FAILURE,
      });
      throw error;
    }
  };
}

export function verificateNewPassword(email, verificationCode, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANGE_PASSWORD });

      const user = new CognitoUser({
        Username: email.toLowerCase(),
        Pool: UserPool,
      });

      user.confirmPassword(verificationCode, password, {
        onSuccess: (data) => {
          dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: {
              data,
            },
          });
        },
        onFailure: (err) => {
          dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            payload: err.message,
          });
        },
      });
    } catch (error) {
      dispatch({
        type: PASSWORD_RECOVERY_FAILURE,
      });
      throw error;
    }
  };
}
