import axios from "axios";
import FormData from "form-data";
import { urlConfig } from "../constants/urlConstants";
import Client from "../components/Client";
import { v4 as uuidv4 } from "uuid";
import { json } from "body-parser";

export const GET_MODEL_LIST = "@viewer/get-model-list";
export const GET_MODEL_LIST_SUCCESS = "@account/get-model-list-success";
export const GET_MODEL_LIST_FAILURE = "@account/get-model-list-failure";
export const UPLOAD_MODEL = "@account/upload-model";
export const UPLOAD_MODEL_SUCCESS = "@account/upload-model-success";
export const UPLOAD_MODEL_FAILURE = "@account/upload-model-failure";
export const DELETE_MODEL = "@account/delete-model";
export const DELETE_MODEL_SUCCESS = "@account/delete-model-success";
export const DELETE_MODEL_FAILURE = "@account/delete-model-failure";
export const RENAME_MODEL = "@account/rename";
export const RENAME_MODEL_SUCCESS = "@account/rename-success";
export const RENAME_MODEL_FAILURE = "@account/rename-failure";
export const SET_DEFAULT = "@account/set-default";
export const SET_UPLOADED_FILE_INFO = "@account/set-uploaded-file-info";
export const SET_SELECTED_MODEL = "SET_SELECTED_MODEL";
export const SET_CURRENT_MODELS = "SET_CURRENT_MODELS";
export const UPDATE_SPLIT_VALUE = "UPDATE_SPLIT_VALUE";
export const UPDATE_TRANSLATION_STATUS = "UPDATE_TRANSLATION_STATUS";
export const SET_APPLY_REF_POINT = "SET_APPLY_REF_POINT";

let hookId = "";

export function getModelList(userId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MODEL_LIST });
      await axios
        .get(urlConfig.url.API_GET_MODEL_LIST + userId)
        .then(async (response) => {
          console.log("Get models response ", response);
          await dispatch({
            type: GET_MODEL_LIST_SUCCESS,
            payload: response.data,
          });
        });
    } catch (error) {
      dispatch({
        type: GET_MODEL_LIST_FAILURE,
      });
      throw error;
    }
  };
}

function createWebHook(generatedModelId) {
  return new Promise((resolve, reject) => {
    axios.get("/token").then(async (response) => {
      const token = JSON.parse(response.data);
      if (!token) return;
      const data = {
        callbackUrl: urlConfig.url.WEBHOOK_CALLBACK_URL,
        scope: {
          workflow: String(generatedModelId),
        },
        hookAttribute: {
          userId: String(localStorage.getItem("subId")),
          modelId: generatedModelId,
        },
      };
      const config = {
        method: "post",
        url: "https://developer.api.autodesk.com/webhooks/v1/systems/derivative/events/extraction.finished/hooks?region=EMEA",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.access_token,
        },
        data: data,
      };
      await axios(config)
        .then((res) => {
          if (res.status === 201 || res.status === 409) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          console.log("error creating webhook", err);
        });
    });
  });
}

async function deleteWebHook(hookId) {
  axios.get("/token").then(async (response) => {
    const token = JSON.parse(response.data);
    if (!token) return;
    let deleteUrl =
      "https://developer.api.autodesk.com/webhooks/v1/systems/derivative/events/extraction.finished/hooks/" +
      hookId +
      "?region=EMEA";

    let deleteOptions = {
      method: "DELETE",
      url: deleteUrl,
      headers: {
        Authorization: "Bearer " + token.access_token,
      },
    };
    await axios(deleteOptions).then((res) => {
      if (res.status === 200) {
      }
    });
  });
}

export function uploadNewModel(
  file,
  generatedModelId,
  folderId,
  folderName,
  mList
) {
  return async (dispatch) => {
    //let generatedModelId = uuidv4();
    // let updatedModelList = [...mList, generatedModelId];
    let updatedModelList = mList;
    //createWebHook(generatedModelId).then(async () => {
    dispatch({ type: UPLOAD_MODEL });
    let data = new FormData();
    data.append("file", file);
    data.append("userId", localStorage.getItem("subId"));
    data.append("userEmail", localStorage.getItem("email"));
    data.append("modelId", generatedModelId);
    data.append("folderId", folderId);
    data.append("folderName", folderName);
    data.append("modelList", JSON.stringify(updatedModelList));
    try {
      let response = await axios.post(urlConfig.url.API_UPLOAD_MODEL, data);
      //console.log("Upload successful!", response);
      await dispatch({
        type: UPLOAD_MODEL_SUCCESS,
        payload: {},
        loading: false,
      });

      //window.location.reload();
      // await axios
      //   .post(urlConfig.url.API_UPLOAD_MODEL, data)
      //   .then(async (response) => {
      //     await dispatch({
      //       type: UPLOAD_MODEL_SUCCESS,
      //       payload: response.data,
      //       loading: false,
      //     });
      //   })
      //   .catch((error) => {
      //     console.log("Error uploading model ", error);
      //     dispatch({
      //       type: UPLOAD_MODEL_FAILURE,
      //     });
      //     throw error;
      //   });
    } catch (e) {
      console.log("Exception occured while uploading", e);
      await dispatch({
        type: UPLOAD_MODEL_SUCCESS,
        payload: {},
        loading: false,
      });
    }
  };
}

export function deleteModel(uniqueId, id, index) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_MODEL,
        payload: index,
      });
      const data = JSON.stringify({
        userId: localStorage.getItem("subId"),
        uniqueId: uniqueId,
        deleteId: id,
      });

      const config = {
        method: "delete",
        url: urlConfig.url.API_DELETE_MODEL,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config).then(async (response) => {
        await dispatch({
          type: DELETE_MODEL_SUCCESS,
          payload: {
            index,
            uniqueId,
          },
        });
      });
    } catch (error) {
      dispatch({
        type: DELETE_MODEL_FAILURE,
      });
      throw error;
    }
  };
}

export function setDefault() {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_DEFAULT });
    } catch (error) {
      throw error;
    }
  };
}

export function setViewerModel(model) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_SELECTED_MODEL,
        payload: model,
      });
    } catch (error) {
      throw error;
    }
  };
}
export function setApplyRefPointChecked(applyRefPoint) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_APPLY_REF_POINT,
        payload: {
          applyRefPoint: applyRefPoint,
        },
      });
    } catch (error) {
      throw error;
    }
  };
}

export function setCurrentModels(curModels) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_CURRENT_MODELS,
        payload: curModels,
      });
    } catch (error) {
      console.log("Error setting current models ", error);
      throw error;
    }
  };
}

export function setUploadedFileInfo(model, index) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_UPLOADED_FILE_INFO,
        payload: model,
        index: index,
      });
    } catch (error) {
      throw error;
    }
  };
}

export function changeModelName(model, title, index) {
  return async (dispatch) => {
    try {
      dispatch({
        type: RENAME_MODEL,
      });

      const data = {
        userId: model.userId,
        uniqueId: model.uniqueId,
        displayName: title,
      };

      const config = {
        method: "post",
        url: urlConfig.url.API_RENAME_MODEL,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config)
        .then(async (response) => {
          await dispatch({
            type: RENAME_MODEL_SUCCESS,
            updatingIndex: index,
            payload: title,
          });
        })
        .catch(function (error) {
          dispatch({
            type: RENAME_MODEL_FAILURE,
          });
        });
    } catch (error) {
      dispatch({
        type: RENAME_MODEL_FAILURE,
      });
      throw error;
    }
  };
}

export function updateSplitValue(userId, uniqueId, splitValue) {
  return async (dispatch) => {
    try {
      const data = {
        userId: userId,
        uniqueId: uniqueId,
        splitValue: splitValue,
      };
      const config = {
        method: "post",
        url: urlConfig.url.API_SPLIT_VALUE,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_SPLIT_VALUE,
            payload: {
              uniqueId,
              splitValue,
            },
          });
        } else {
          console.log("Error updating split value in database ", res);
        }
      });
    } catch (err) {
      console.log("Error updating split value ", err);
      throw err;
    }
  };
}

export function updateTranslationStatus(userId, uniqueId, status, hookId = "") {
  return async (dispatch) => {
    try {
      if (hookId !== "") await deleteWebHook(hookId);
      const data = {
        userId: userId,
        uniqueId: uniqueId,
        status: status,
      };
      const config = {
        method: "post",
        url: urlConfig.url.API_UPDATE_TRANSLATION_STATUS,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_TRANSLATION_STATUS,
            payload: {
              uniqueId,
              status,
            },
          });
        } else {
          console.log("Error updating translation value in database ", res);
        }
      });
    } catch (err) {
      console.log("Error updating translation value ", err);
      throw err;
    }
  };
}
