import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SignUp from "./SignUp/SignUp";
import Dashboard from "./Dashboard/Dashboard";
import Login from "./Login/Login";
import Recovery from "./Recovery/Recovery";
import Viewer from "./Viewer/Viewer";
import PrivacyPolicy from "./SignUp/PrivacyPolicy";
import TermsAndConditions from "./SignUp/TermsAndConditions";
import AuthCognito from "../services/AuthSession";
import GuardAuth from "../guards/AuthGuard";
import NonAuth from "../guards/NonAuth";
import Subscription from "./Subscription/Subscription";
import Folders from "./Folders/Folders";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

import ReduxToastr from "react-redux-toastr";
// import ListOfForms from "./FormBuilder/ListOfForms";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@fortawesome/fontawesome-free/js/all.js";
function App() {
  const [token, setToken] = useState("");

  useEffect(() => {
    return setToken(localStorage.getItem("accessToken"));
  }, [localStorage.getItem("accessToken")]);

  return (
    <div className="wrapper">
      <Router>
        <AuthCognito>
          <ReduxToastr
            timeOut={5000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            getState={(state) => state.toastr} // This is the default
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            // closeOnToastrClick
          />
          <Switch>
            {/* Unprotected Routes */}
            <Route path="/" exact component={SignUp} />
            <Route path="/login" component={Login} />
            <Route path="/privacypolicy" component={PrivacyPolicy} />
            <Route path="/termsandconditions" component={TermsAndConditions} />
            {/* <Route path="/listofforms" component={ListOfForms}/> */}
            {/* <Route path="/subscription" component={Subscription}/> */}
            <Route path="/recovery" component={Recovery} />
            <NonAuth path="/shared_viewer/:documentId" component={Viewer} />

            {/* Protected Routes */}
            <GuardAuth
              path="/dashboard/:folderName"
              exact
              component={Dashboard}
              auth={token}
            />
            <GuardAuth path="/folders" exact component={Folders} auth={token} />
            <GuardAuth path="/subscription" exact component={Subscription} />
            <GuardAuth
              path="/viewer/:documentId"
              component={Viewer}
              auth={token}
            />
          </Switch>
        </AuthCognito>
      </Router>
    </div>
  );
}

export default App;
