import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useSnackbar } from 'notistack';

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { Link as Linked } from "react-router-dom";
import { signUp } from "../../actions/accountActions";


function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{
        paddingBottom: "20px",
        fontFamily: "'DM Sans Medium' , sans-serif"
      }}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://cotrinity.com/"
        target="blank"
      >
        Cotrinity
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    textAlign: "center",
    marginTop: ".5rem",
  },
}));

export default function SignUp() {
  const [given_name, setgiven_name] = useState("");
  const [family_name, setfamily_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useSelector(state => state.account);
  const { validationsMessage } = useSelector(state => state.account);
  const dispatch = useDispatch();

  const history = useHistory();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const classes = useStyles();

  const attributes = [
    new CognitoUserAttribute({ Name: "given_name", Value: given_name }),
    new CognitoUserAttribute({ Name: "family_name", Value: family_name }),
    new CognitoUserAttribute({ Name: "email", Value: email }),
  ];

  useEffect(() => {
    if (user) {
      enqueueSnackbar('User created, check your email to confirm.', {
        variant: 'success'
      });
      history.push("/login");
    }
  }, [user]);

  const onSubmit = async (event) => {
    event.preventDefault();
    await dispatch(signUp(email, password, attributes));
  };

  const handleClose = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="auth-only-wrapper">
      <Container
        component="main"
        maxWidth="xs"
        className="auth-container"
      >
        <Dialog open={modalIsOpen} onClose={handleClose}>
          <DialogContent>Succesfully Registered</DialogContent>
        </Dialog>

        <CssBaseline />
        <div className="auth-wrapper">
          <Avatar
            className={classes.avatar}
            style={{
              marginTop: "25px",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}>
            Sign up
          </Typography>
          <form className={classes.form} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={given_name}
                  onChange={(event) => setgiven_name(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={family_name}
                  onChange={(event) => setfamily_name(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <input
                  type="checkbox"
                  name="privacyPolicy"
                  id="privacypolicy"
                  required="required"
                />
                <Link
                  component={Linked}
                  to="/privacypolicy"
                  variant="body2"
                  target="_blank"
                  style={{ paddingLeft: "3px", fontFamily: "'DM Sans Medium' , sans-serif" }}>
                  Privacy Policy
                </Link>
              </Grid>
              <Grid item xs={12} sm={10}>
                <input
                  type="checkbox"
                  name="terms"
                  id="termsandconditions"
                  required="required"
                />
                <Link
                  component={Linked}
                  to="/termsandconditions"
                  variant="body2"
                  target="_blank"
                  style={{ paddingLeft: "3px", fontFamily: "'DM Sans Medium' , sans-serif" }}>
                  Terms and Conditions
                </Link>
              </Grid>
            </Grid>
            <Typography
              className={classes.errorMessage}
              variant="subtitle2"
              color="error"
            >
              {validationsMessage}
            </Typography>
            
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{
                 backgroundColor: "primary",
                 color: "#F19505",
                 fontFamily: "'DM Sans Medium'"
                }}                
              > 
                 Sign Up 
             </Button>              

            <Grid container justify="center">
              <Grid item>
                <Link component={Linked} to="/login" variant="body2" style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
