import axios from "axios";
import Client from "../components/Client";
import { urlConfig } from "../constants/urlConstants";
import { entitiesPropertiesList } from "../components/Viewer/Viewer-helpers";
import { parse } from "path";
export const GET_VIEWS_LIST = "GET_VIEWS_LIST";
export const GET_VIEWS_LIST_SUCCESS = "GET_VIEWS_LIST_SUCCESS";
export const GET_VIEWS_LIST_FAILED = "GET_VIEWS_LIST_FAILED";
export const SAVE_VIEW = "SAVE_VIEW";
export const SAVE_VIEW_SUCCESS = "SAVE_VIEW_SUCCESS";
export const SAVE_VIEW_FAILED = "SAVE_VIEW_FAILED";
export const DELETE_VIEW = "DELETE_VIEW";
export const DELETE_VIEW_SUCCESS = "DELETE_VIEW_SUCCESS";
export const DELETE_VIEW_FAILED = "DELETE_VIEW_FAILED";
export const UPDATE_VIEW = "UPDATE_VIEW";
export const UPDATE_VIEW_SUCCESS = "UPDATE_VIEW_SUCCESS";
export const UPDATE_VIEW_FAILED = "UPDATE_VIEW_FAILED";
export const START_RENAME = "START_RENAME";
export const FINISH_RENAME = "FINISH_RENAME";
export const EXPORT_VIEW = "EXPORT_VIEW";

export function getSavedViewList(modelId, viewId = "") {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VIEWS_LIST });
      const config = {
        method: "get",
        url:
          urlConfig.url.API_GET_SAVED_VIEW_LIST + modelId + `&viewId=${viewId}`,
      };
      await axios(config).then(async (res) => {
        if (res.status === 200) {
          await dispatch({
            type: GET_VIEWS_LIST_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: GET_VIEWS_LIST_FAILED,
          });
        }
      });
    } catch (error) {
      dispatch({
        type: GET_VIEWS_LIST_FAILED,
      });
      throw error;
    }
  };
}

export function saveView(position, modelId, name) {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVE_VIEW });

      const data = JSON.stringify({
        modelId: JSON.stringify(modelId),
        camera: position,
        viewName: name,
      });

      const config = {
        method: "post",
        url: urlConfig.url.API_SAVE_VIEW,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config).then(async (res) => {
        if (res.status === 200) {
          await dispatch({
            type: SAVE_VIEW_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: SAVE_VIEW_FAILED,
          });
        }
      });
    } catch (error) {
      console.log("error saving view ", error);
      dispatch({
        type: SAVE_VIEW_FAILED,
      });
      throw error;
    }
  };
}

export function deleteView(index, view) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_VIEW,
        payload: index,
      });

      const data = JSON.stringify({
        modelId: view.modelId,
        uniqueId: view.uniqueId,
      });

      const config = {
        method: "post",
        url: urlConfig.url.API_DELETE_SAVED_VIEW,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config).then(async (res) => {
        if (res.status === 200) {
          await dispatch({
            type: DELETE_VIEW_SUCCESS,
            payload: index,
          });
        } else {
          dispatch({
            type: DELETE_VIEW_FAILED,
          });
        }
      });
    } catch (error) {
      dispatch({
        type: DELETE_VIEW_FAILED,
      });
      throw error;
    }
  };
}

export function startUpdating() {
  return async (dispatch) => {
    try {
      dispatch({ type: START_RENAME });
    } catch (error) {
      throw error;
    }
  };
}

export function finishUpdating() {
  return async (dispatch) => {
    try {
      dispatch({ type: FINISH_RENAME });
    } catch (error) {
      throw error;
    }
  };
}

export function updateView(index, view, name, axis, updatingType) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_VIEW,
        payload: index,
        updatingType,
      });

      const data = JSON.stringify({
        modelId: view.modelId,
        uniqueId: view.uniqueId,
        camera: axis,
        viewName: name,
      });

      const config = {
        method: "post",
        url: urlConfig.url.API_UPDATE_SAVED_VIEW_NAME,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config).then(async (res) => {
        if (res.status === 200) {
          await dispatch({
            type: UPDATE_VIEW_SUCCESS,
            index: index,
            payload: res.data,
          });
        } else {
          dispatch({
            type: UPDATE_VIEW_FAILED,
          });
        }
      });
    } catch (error) {
      dispatch({
        type: UPDATE_VIEW_FAILED,
      });
      throw error;
    }
  };
}
export function exportToOBJ(view, modelUrn, objIds) {
  // var urn = JSON.parse(view.camera).seedURN;
  var urn = modelUrn;
  const fileDownload = require("js-file-download");
  return () => {
    axios.get("/token").then(async (response) => {
      const token = JSON.parse(response.data)
      if (!token) return
      try {
        let finalObjs = null;
        let viewObject = JSON.parse(view.camera);
        viewObject.objectSet.forEach((viewObj) => {
          if (viewObj.seedUrn) {
            if (viewObj.seedUrn === urn) {
              if (viewObj.isolated && viewObj.isolated.length > 0) {
                finalObjs = viewObj.isolated;
              } else {
                finalObjs = objIds;
                if (viewObj.hidden && viewObj.hidden.length > 0) {
                  viewObj.hidden.forEach((hiddenId) => {
                    let tempIndex = objIds.indexOf(hiddenId);
                    if (tempIndex >= 0) {
                      finalObjs.splice(tempIndex, 1);
                    }
                  });
                }
              }
            }
          } else {
            if (viewObj.isolated && viewObj.isolated.length > 0) {
              finalObjs = viewObj.isolated;
            } else {
              finalObjs = objIds;
              if (viewObj.hidden && viewObj.hidden.length > 0) {
                viewObj.hidden.forEach((hiddenId) => {
                  let tempIndex = objIds.indexOf(hiddenId);
                  if (tempIndex >= 0) {
                    finalObjs.splice(tempIndex, 1);
                  }
                });
              }
            }
          }
        });

        getMetaData(token.access_token, urn, function (modelguid) {
          var x = document.getElementById("snackbar");
          x.innerText =
            "Extracting obj is in progress, it might take few moments to download";
          x.className = "show";
          startExtraction(
            token.access_token,
            urn,
            modelguid,
            finalObjs,
            function (res) {
              startExtractionProgress(
                token.access_token,
                urn,
                finalObjs,
                function (res) {
                  var objURNFileName = res.urn.split("/").pop();
                  var objURN = encodeURIComponent(res.urn);
                  var mtlURNFileName = objURNFileName.replace(".obj", ".mtl");
                  var mtlURN = objURN.replace(".obj", ".mtl");
                  x.innerText =
                    "Downloading obj is in progress, please wait...";
                  x.className = "show";
                  downloadMTLExtracted(
                    token.access_token,
                    urn,
                    mtlURN,
                    mtlURNFileName,
                    finalObjs,
                    function (res) {
                      
                    }
                  );
                  downloadOBJExtracted(
                    token.access_token,
                    urn,
                    objURN,
                    finalObjs,
                    view,
                    function (res) {
                      x.className = x.className.replace("show", "");
                      
                    }
                  );
                  x.innerText = "Downloading obj completed";
                  x.className = "show";
                  setTimeout(function () {
                    x.className = x.className.replace("show", "");
                  }, 3000);
                }
              );
            }
          );
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    });
  };
}
export function CompareArraysByValue(a1, a2) {
  return JSON.stringify(a1.sort()) == JSON.stringify(a2.sort());
}

const getMetaData = (access_token, urn, callback) => {
  const configMetadata = {
    method: "get",
    url: urlConfig.url.API_EXPORT_VIEW + "/" + urn + "/metadata",
    headers: {
      Authorization: "Bearer " + access_token,
    },
  };
  axios(configMetadata).then(function (res) {
    callback(res.data.data.metadata[0].guid);
  });
};
const startExtraction = (access_token, urn, guid, insideIds, callback) => {
  const data = JSON.stringify({
    input: {
      urn: urn,
    },
    output: {
      destination: {
        region: "emea",
      },
      formats: [
        {
          type: "obj",
          advanced: {
            modelGuid: guid,
            objectIds: insideIds,
          },
        },
      ],
    },
  });
  const configTranslate = {
    method: "post",
    url: urlConfig.url.API_EXPORT_VIEW + "/job",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "x-ads-force": "true",
    },
    dataType: "json",
    data: data,
  };
  axios(configTranslate)
    .then(function (res) {
      callback(res);
    })
    .catch((error) => {
      console.log("Translate Error: ", error);
    });
};
const startExtractionProgress = (access_token, urn, insidedbids, callback) => {
  const configStatus = {
    method: "get",
    url: urlConfig.url.API_EXPORT_VIEW + "/" + urn + "/manifest",
    headers: {
      Authorization: "Bearer " + access_token,
    },
  };
  axios(configStatus)
    .then(function (res) {
      res.data.derivatives.forEach((item) => {
        if (item.outputType == "obj") {
          if (item.progress == "complete" && item.status == "success") {
            for (let i = 0; i < item.children.length; i++) {
              if (
                CompareArraysByValue(item.children[i].objectIds, insidedbids)
              ) {
                callback({
                  urn: item.children[i].urn,
                  name: res.data.derivatives[0].name,
                });
                return;
              } else if (insidedbids.includes(-1)) {
                if (item.children[i].objectIds.includes(-1)) {
                  callback({
                    urn: item.children[i].urn,
                    name: res.data.derivatives[0].name,
                  });
                  return;
                }
              }
            }
          } else {
            startExtractionProgress(access_token, urn, insidedbids, callback);
          }
        }
      });
    })
    .catch((error) => {
      console.log("Status Error: ", error);
    });
};
const downloadOBJExtracted = (
  access_token,
  urn,
  objurn,
  insidedbids,
  view,
  callback
) => {
  const fileDownload = require("js-file-download");
  const configObjDownload = {
    method: "get",
    url: urlConfig.url.API_EXPORT_VIEW + "/" + urn + "/manifest/" + objurn,
    headers: {
      Authorization: "Bearer " + access_token,
    },
  };
  axios(configObjDownload)
    .then(function (res) {
      var filename = view.viewName + ".obj";
      fileDownload(res.data, filename);
    })
    .catch((error) => {
      console.log("Download Error: ", error);
    });
};
const downloadMTLExtracted = (
  access_token,
  urn,
  mtlurn,
  mtlURNFileName,
  insidedbids,
  callback
) => {
  const fileDownload = require("js-file-download");
  const configObjDownload = {
    method: "get",
    url: urlConfig.url.API_EXPORT_VIEW + "/" + urn + "/manifest/" + mtlurn,
    headers: {
      Authorization: "Bearer " + access_token,
    },
  };
  axios(configObjDownload)
    .then(function (res) {
      fileDownload(res.data, mtlURNFileName);
    })
    .catch((error) => {
      console.log("Download Error: ", error);
    });
};
