import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link as Linked } from "react-router-dom";
import { signIn } from "../../actions/accountActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

function Copyright() {
  return (
    <Typography
      variant= "body2"
      color="textSecondary"
      align="center"
      style={{
        paddingBottom: "20px",
        fontFamily: "'DM Sans Medium' , sans-serif", 
      }}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://cotrinity.com/"
        target="blank"
      >
        Cotrinity
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    textAlign: "center",
    marginTop: ".5rem",
  },
}));

export function LogIn(props) {

  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { validationsMessage } = useSelector((state) => state.account);

  const dispatch = useDispatch();

  const history = useHistory();

  // useEffect(() => {
  //   dispatch(signOut());
  // }, []);

  const onSubmit = (event) => {
    event.preventDefault();   
    dispatch(signIn(username, password, history));
    
  };

  return (
    <div className="auth-only-wrapper">
      <Container
        component="main"
        maxWidth="xs"
        className="auth-container"
      >
        <CssBaseline />
        <div className="auth-wrapper">
          <Avatar
            className={classes.avatar}
            style={{
              marginTop: "25px",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}>
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={onSubmit} style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />

            <Typography
              className={classes.errorMessage}
              variant="subtitle2"
              color="error"
            >
              {validationsMessage}
            </Typography>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{
                fontFamily: "'DM Sans Medium' , sans-serif",
                backgroundColor: "primary",
                color: "#F19505"                
              }}              
            >
              Sign In
            </Button>
            
            <Grid container>
              <Grid item xs>
                <Link component={Linked} to="/recovery" variant="body2" style={{ fontFamily: "'DM Sans Medium'" }} >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link component={Linked} to="/" variant="body2" style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}>
                  {"Don't have an account?"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box style={{fontFamily: "'DM Sans Mendium' , sans-serif"}} mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { SubscribedPlanId} = state.subscription;
  return { SubscribedPlanId};
};
export default withRouter(connect(mapStateToProps,{})(LogIn));