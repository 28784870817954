import axios from "axios";
import { urlConfig } from "../constants/urlConstants";

export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_PLAN = "GET_PLAN";
export const SET_CREDITS = "SET_CREDITS";

export function setSubscription(
  UserId,
  PlanId,
  SubscriptionStartDate,
  SubscriptionEndDate,
  UserCredits,
  ActivePlan,
  SubscriptionId,
  objExportCount
) {
  return async (dispatch) => {
    try {
      const data = {
        UserId: UserId,
        PlanId: PlanId,
        SubscriptionStartDate: SubscriptionStartDate,
        SubscriptionEndDate: SubscriptionEndDate,
        UserCredits: UserCredits,
        ActivePlan: ActivePlan,
        SubscriptionId: SubscriptionId,
        ObjExportCount: objExportCount
      };

      const config = {
        method: "post",
        url: urlConfig.url.API_ADD_SUBSCRIPTION,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: ADD_SUBSCRIPTION,
            payload: {
              PlanId,
              SubscriptionStartDate,
              SubscriptionEndDate,
              UserCredits,
              ActivePlan,
              SubscriptionId
            },
          });
        } else {
          console.log(
            "Error occured while adding subscription to database ",
            res
          );
        }
      });
    } catch (err) {
      console.log("Error in adding subscription ", err);
    }
  };
}

export function updateSubscription(
  index,
  UserId,
  PlanId,
  SubscriptionStartDate,
  SubscriptionEndDate,
  UserCredits,
  ActivePlan,
  SubscriptionId
) {
  return async (dispatch) => {
    try {
      const data = {
        UserId: UserId,
        PlanId: PlanId,
        SubscriptionStartDate: SubscriptionStartDate,
        SubscriptionEndDate: SubscriptionEndDate,
        UserCredits: UserCredits,
        ActivePlan: ActivePlan,
        SubscriptionId: SubscriptionId,
      };

      const config = {
        method: "post",
        url: urlConfig.url.API_UPDATE_SUBSCRIPTION,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_SUBSCRIPTION,
            payload: {
              subscriptionIndex: index,
              PlanId,
              SubscriptionStartDate,
              SubscriptionEndDate,
              UserCredits,
              ActivePlan,
              SubscriptionId,
            },
          });
        } else {
          console.log(
            "Error occured while updating subscription to database ",
            res
          );
        }
      });
    } catch (err) {
      console.log("Error in updating subscription ", err);
    }
  };
}

export function deleteSubscription(index, UserId, PlanId) {
  return async (dispatch) => {
    try {
      const data = {
        UserId: UserId,
        PlanId: PlanId,
      };
      const config = {
        method: "post",
        url: urlConfig.url.API_DELETE_SUBSCRIPTION,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: DELETE_SUBSCRIPTION,
            payload: {
              subscriptionIndex: index,
            },
          });
        } else {
          console.log("Error in deleting subscription from database");
        }
      });
    } catch (err) {
      console.log("Error in deleting subscription from database", err);
    }
  };
}

export function getSubscription(UserId) {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: urlConfig.url.API_GET_SUBSCRIPTION + UserId,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_SUBSCRIPTION,
            payload: {
              data: res.data,
            },
          });
        } else {
          console.log("Error fetching subscriptions from database ", res);
        }
      });
    } catch (err) {
      console.log("Catch Error in fetching subscriptions from database ", err);
      throw err;
    }
  };
}
export function setCredits(UserCredits) {
  return async (dispatch) => {
    dispatch({
      type: SET_CREDITS,
      payload: {
        UserCredits,
      },
    });
  };
}
