import React from "react";
import { Route } from "react-router-dom";
import NonAuthTopBar from "../components/TopBar/NonAuthTopBar";

const NonAuth = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
        <>
        <NonAuthTopBar />
        <Component {...props} />
      </>
    }
  />
);

export default NonAuth;
