import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { entitiesPropertiesList, modelHierarchy } from "../Viewer-helpers";
import { updateSplitValue } from "../../../actions/modelsActions";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Divider,
} from "@material-ui/core";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import Close from "@material-ui/icons/Close";
import FloorPlanSettings from "./FloorPlanSettings";
import { makeStyles } from "@material-ui/core/styles";
import { setListDisplayed } from "../toolBarExtension";
import Check from "@material-ui/icons/Check";

const Autodesk = window.Autodesk;

const useStyles = makeStyles((theme) => ({
  listContainer: {
    "& .MuiListItemText-root": {
      margin: "0px",
      padding: "0px",
    },
  },
}));
const FloorPlan = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [filteredList, setFilteredList] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [filterProperty, setFilterProperty] = useState([]);
  const [updateIndex, setUpdateIndex] = useState(-1);
  const [toggleEntities, setToggleEntities] = useState([]);

  let levelButtonState = document.getElementById("show-level-button");

  useEffect(() => {
    if (props.selectedModel) {
      let temp = [];
      for (let i in props.selectedModel) {
        if (props.selectedModel[i].splitValue === null) {
          temp.push("Base Constraint");
        } else {
          temp.push(props.selectedModel[i].splitValue);
        }
      }
      setFilterProperty(temp);
      readEntitiesProperties(entitiesPropertiesList, temp);
    }
  }, []);

  useEffect(() => {
    if (updateIndex.length) {
      updateEntitiesProperties(entitiesPropertiesList, updateIndex);
      setUpdateIndex([]);
    }
  }, [updateIndex, filterProperty]);

  useEffect(() => {
    setOpen(props.listDisplayed);
  }, [props.listDisplayed]);

  const readEntitiesProperties = (epl, fProperty) => {
    return new Promise((resolve, reject) => {
      let temp = [...filteredList];

      props.selectedModel.forEach((model, index) => {
        let object1 = {};
        if (epl[model.modelURN]) {
          Object.keys(epl[model.modelURN]).forEach((key) => {
            for (const [propertyName, propertyValue] of Object.entries(
              epl[model.modelURN][key]
            )) {
              if (propertyName === fProperty[index]) {
                if (
                  object1[propertyValue] === null ||
                  object1[propertyValue] === undefined
                ) {
                  object1[[propertyValue]] = [parseInt(key, 10)];
                } else {
                  object1[[propertyValue]].push(parseInt(key, 10));
                }
              }
            }
          });
        }
        temp[index] = object1;
      });
      setFilteredList(temp);
    });
  };

  const updateEntitiesProperties = (epl, updateIndex) => {
    return new Promise((resolve, reject) => {
      let temp = [...filteredList];
      for (let i in updateIndex) {
        let updateId = props.selectedModel[updateIndex[i]].modelURN;
        let object1 = {};
        Object.keys(epl[updateId]).forEach((key) => {
          for (const [propertyName, propertyValue] of Object.entries(
            epl[updateId][key]
          )) {
            if (propertyName === filterProperty[updateIndex[i]]) {
              if (
                object1[propertyValue] === null ||
                object1[propertyValue] === undefined
              ) {
                object1[[propertyValue]] = [parseInt(key, 10)];
              } else {
                object1[[propertyValue]].push(parseInt(key, 10));
              }
            }
          }
        });
        temp[updateIndex[i]] = object1;
        dispatch(
          updateSplitValue(
            localStorage.getItem("subId"),
            props.selectedModel[updateIndex[i]].uniqueId,
            filterProperty[updateIndex[i]]
          )
        );
      }
      setFilteredList(temp);
    });
  };

  const updateFilterProperty = (propertyName, index) => {
    let temp = [...filterProperty];
    for (let i in index) {
      temp[index[i]] = propertyName[index[i]];
    }
    setFilterProperty(temp);
    setUpdateIndex(index);
  };

  const toggleSelectedEntities = (modelIndex, filterIndex, urn) => {
    let temp = [];
    if (!temp[modelIndex]) temp[modelIndex] = [];
    if (temp[modelIndex][filterIndex] === undefined)
      temp[modelIndex][filterIndex] = true;
    else temp[modelIndex][filterIndex] = !temp[modelIndex][filterIndex];
    if (temp[modelIndex][filterIndex]) {
      for (let modelurn in modelHierarchy) {
        if (modelurn === urn && modelHierarchy[urn]) {
          let toggeledModel = props.viewer.impl
            .modelQueue()
            .findModel(modelHierarchy[urn].modelId);
          if (toggeledModel)
            toggeledModel.visibilityManager.isolate(
              Object.values(filteredList[modelIndex])[filterIndex],
              toggeledModel
            );
        } else {
          if (modelHierarchy[urn]) {
            let toggeledModel = props.viewer.impl
              .modelQueue()
              .findModel(modelHierarchy[modelurn].modelId);
            if (toggeledModel)
              toggeledModel.visibilityManager.hide(
                modelHierarchy[modelurn].dbId,
                toggeledModel
              );
          }
        }
      }
    }
    setToggleEntities(temp);
  };

  return (
    <Fragment>
      {open ? (
        <div>
          <div
            id="sampleId"
            style={{
              display: "block",
              height: "70%",
              width: "27%",
              backgroundColor: "white",
              position: "absolute",
              left: "1%",
              top: "15%",
              zIndex: "2",
              borderRadius: "5%",
              overflowY: "scroll",
            }}
          >
            <Box>
              <div>
                <IconButton
                  aria-label="Collapse"
                  color="primary"
                  onClick={() => setSettingsOpen(true)}
                >
                  <SettingsApplications
                    style={{ fontSize: 30, color: "#2b455f" }}
                  />
                </IconButton>
                <IconButton
                  aria-label="Collapse"
                  color="primary"
                  style={{ float: "right" }}
                  onClick={() => {
                    setOpen(false);
                    levelButtonState.classList.remove("active");
                    setListDisplayed(false);
                  }}
                >
                  <Close style={{ fontSize: 30, color: "#2b455f" }} />
                </IconButton>
              </div>
              {props.selectedModel.map((model, index) => {
                return (
                  <Fragment>
                    <List className={classes.listContainer}>
                      <Button style={{ fontSize: "large" }}>
                        <ListItem>
                          <ListItemIcon>
                            <i
                              className="fa fa-building"
                              aria-hidden="true"
                            ></i>
                          </ListItemIcon>
                          <ListItemText
                            style={{
                              overflowWrap: "anywhere",
                            }}
                          >
                            {model.displayName}
                          </ListItemText>
                        </ListItem>
                      </Button>
                    </List>
                    {filteredList[index] !== undefined &&
                    typeof filteredList[index] === "object" ? (
                      <List className={classes.listContainer}>
                        {Object.keys(filteredList[index]).map(
                          (item, filterIndex) => {
                            return (
                              <div
                                style={{ paddingLeft: "15%", width: "100%" }}
                              >
                                <Button
                                  style={
                                    toggleEntities &&
                                    toggleEntities[index] &&
                                    toggleEntities[index][filterIndex]
                                      ? {
                                          fontSize: 10,
                                          width: "100%",
                                          backgroundColor:
                                            "rgba(0, 0, 0, 0.04)",
                                        }
                                      : { fontSize: 10, width: "100%" }
                                  }
                                >
                                  <ListItem
                                    onClick={() =>
                                      toggleSelectedEntities(
                                        index,
                                        filterIndex,
                                        model.modelURN
                                      )
                                    }
                                  >
                                    <ListItemIcon>
                                      <i
                                        className="fa fa-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </ListItemIcon>
                                    <ListItemText
                                      style={{
                                        fontFamily: "system-ui",
                                        fontSize: "0.9rem",
                                      }}
                                    >
                                      {item === "" ? "<Unnamed>" : item}
                                    </ListItemText>
                                  </ListItem>
                                  {toggleEntities &&
                                    toggleEntities[index] &&
                                    toggleEntities[index][filterIndex] && (
                                      <Check style={{ color: "#2b455f" }} />
                                    )}
                                </Button>
                                <br />
                              </div>
                            );
                          }
                        )}
                      </List>
                    ) : (
                      <div></div>
                    )}
                    <Divider />
                  </Fragment>
                );
              })}
            </Box>
          </div>
          {settingsOpen && (
            <FloorPlanSettings
              open={settingsOpen}
              setOpen={setSettingsOpen}
              updateFilterProperty={updateFilterProperty}
              filterProperty={filterProperty}
            />
          )}
        </div>
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const { modelList, selectedModel, currentModels } = state.models;
  return { modelList, selectedModel, currentModels };
};

export default connect(mapStateToProps, { updateSplitValue })(FloorPlan);
