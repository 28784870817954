import { produce } from "immer";
import {
  ADD_TEMPLATE,
  DELETE_TEMPLATE,
  GET_TEMPLATE,
  UPDATE_TEMPLATE,
  ADD_PROPERTIES,
  UPDATE_PROPERTIES,
  GET_PROPERTIES,
  TEMPLATES_LOADING,
  PROPERTIES_LOADING,
  LOAD_CUSTOM_PROPERTIES,
  REFRESH_CUSTOM_PROPERTIES,
} from "../actions/customParameterActions";

const initialState = {
  tempModelId: [],
  templateId: [],
  templateName: [],
  templateProperties: [],
  multipleTempModelId: [],
  multipleTemplateId: [],
  multipleTemplateName: [],
  multipleTemplateProperties: [],
  propertiesValuesList: {},
  propertyValuesFetched: -1,
  templatesFetched: -1,
  fetchCustomProperties: true,
};

const customPropertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEMPLATE: {
      return produce(state, (draft) => {
        if (!action.payload.multiModel) {
          draft.tempModelId.push(action.payload.modelId);
          draft.templateId.push(action.payload.templateId);
          draft.templateName.push(action.payload.templateName);
          draft.templateProperties.push(action.payload.properties);
        } else {
          draft.multipleTempModelId.push(action.payload.modelId);
          draft.multipleTemplateId.push(action.payload.templateId);
          draft.multipleTemplateName.push(action.payload.templateName);
          draft.multipleTemplateProperties.push(action.payload.properties);
        }
      });
    }
    case UPDATE_TEMPLATE: {
      return produce(state, (draft) => {
        if (!action.payload.multiModel) {
          draft.templateName[action.payload.templateIndex] =
            action.payload.templateName;
          draft.templateProperties[action.payload.templateIndex] =
            action.payload.templateProperties;
        } else {
          draft.multipleTemplateName[action.payload.templateIndex] =
            action.payload.templateName;
          draft.multipleTemplateProperties[action.payload.templateIndex] =
            action.payload.templateProperties;
        }
      });
    }
    case DELETE_TEMPLATE: {
      return produce(state, (draft) => {
        delete draft.propertiesValuesList[
          draft.templateId[action.payload.templateIndex]
        ];
        if (!action.payload.multiModel) {
          draft.templateId.splice(action.payload.templateIndex, 1);
          draft.templateName.splice(action.payload.templateIndex, 1);
          draft.templateProperties.splice(action.payload.templateIndex, 1);
          draft.tempModelId.splice(action.payload.templateIndex, 1);
        } else {
          draft.multipleTemplateId.splice(action.payload.templateIndex, 1);
          draft.multipleTemplateName.splice(action.payload.templateIndex, 1);
          draft.multipleTemplateProperties.splice(
            action.payload.templateIndex,
            1
          );
          draft.multipleTempModelId.splice(action.payload.templateIndex, 1);
        }
      });
    }
    case GET_TEMPLATE: {
      return produce(state, (draft) => {
        if (!action.payload.multiModel) {
          for (let i in action.payload.data) {
            if (!draft.templateId.includes(action.payload.data[i].templateId)) {
              draft.tempModelId.push(action.payload.data[i].modelId);
              draft.templateId.push(action.payload.data[i].templateId);
              draft.templateName.push(action.payload.data[i].templateName);
              draft.templateProperties.push(
                JSON.parse(action.payload.data[i].templateProperties)
              );
            }
          }
        } else {
          for (let i in action.payload.data) {
            if (
              !draft.multipleTemplateId.includes(
                action.payload.data[i].templateId
              )
            ) {
              draft.multipleTempModelId.push(action.payload.data[i].modelId);
              draft.multipleTemplateId.push(action.payload.data[i].templateId);
              draft.multipleTemplateName.push(
                action.payload.data[i].templateName
              );
              draft.multipleTemplateProperties.push(
                JSON.parse(action.payload.data[i].templateProperties)
              );
            }
          }
        }
        draft.templatesFetched = 1;
      });
    }
    case ADD_PROPERTIES: {
      return produce(state, (draft) => {
        let propertiesList = action.payload.properties;
        let uniqueIdsList = action.payload.uniqueId;
        let templateId = action.payload.templateId;
        let entityIdsList = action.payload.entities;
        for (let i in entityIdsList) {
          if (!draft.propertiesValuesList[templateId]) {
            draft.propertiesValuesList[templateId] = [];
            draft.propertiesValuesList[templateId].push({
              modelId: action.payload.modelId,
              uniqueId: uniqueIdsList[i],
              id: entityIdsList[i],
              values: JSON.parse(propertiesList[i]),
            });
          } else {
            let j = draft.propertiesValuesList[templateId].findIndex(
              (temp) =>
                temp.id === entityIdsList[i] &&
                temp.modelId === action.payload.modelId
            );
            if (j > -1) {
              draft.propertiesValuesList[templateId][j].values = JSON.parse(
                propertiesList[i]
              );
            } else {
              draft.propertiesValuesList[templateId].push({
                modelId: action.payload.modelId,
                uniqueId: uniqueIdsList[i],
                id: entityIdsList[i],
                values: JSON.parse(propertiesList[i]),
              });
            }
          }
        }
      });
    }
    case GET_PROPERTIES: {
      return produce(state, (draft) => {
        let propertiesList = action.payload.data;
        for (let i in propertiesList) {
          if (!draft.propertiesValuesList[propertiesList[i].templateId]) {
            draft.propertiesValuesList[propertiesList[i].templateId] = [];
            draft.propertiesValuesList[propertiesList[i].templateId].push({
              modelId: propertiesList[i].modelId,
              uniqueId: propertiesList[i].uniqueId,
              id: propertiesList[i].entityId,
              values: JSON.parse(propertiesList[i].propertyValues),
            });
          } else {
            draft.propertiesValuesList[propertiesList[i].templateId].push({
              modelId: propertiesList[i].modelId,
              uniqueId: propertiesList[i].uniqueId,
              id: propertiesList[i].entityId,
              values: JSON.parse(propertiesList[i].propertyValues),
            });
          }
        }
        draft.propertyValuesFetched = 1;
      });
    }
    case UPDATE_PROPERTIES: {
      return produce(state, (draft) => {
        let uniqueId = action.payload.uniqueId;
        let properties = action.payload.properties;
        let tempId = action.payload.templateId;
        if (draft.propertiesValuesList[tempId]) {
          draft.propertiesValuesList[tempId].map((item, id) => {
            if (uniqueId.includes(item.uniqueId)) {
              let index = uniqueId.indexOf(item.uniqueId);
              if (index > -1)
                draft.propertiesValuesList[tempId][id].values = JSON.parse(
                  properties[index]
                );
            }
          });
        }
      });
    }
    case TEMPLATES_LOADING: {
      return produce(state, (draft) => {
        draft.templatesFetched = 0;
      });
    }
    case PROPERTIES_LOADING: {
      return produce(state, (draft) => {
        draft.propertyValuesFetched = 0;
      });
    }
    case LOAD_CUSTOM_PROPERTIES: {
      return produce(state, (draft) => {
        draft.fetchCustomProperties = action.payload;
      });
    }
    case REFRESH_CUSTOM_PROPERTIES: {
      return produce(state, (draft) => {
        draft.tempModelId = [];
        draft.templateId = [];
        draft.templateName = [];
        draft.templateProperties = [];
        draft.multipleTempModelId = [];
        draft.multipleTemplateId = [];
        draft.multipleTemplateName = [];
        draft.multipleTemplateProperties = [];
        draft.propertiesValuesList = {};
      });
    }
    default: {
      return state;
    }
  }
};
export default customPropertiesReducer;
