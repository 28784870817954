import { produce } from "immer";
import {
  ADD_FOLDER,
  GET_FOLDERS,
  SET_CURRENT_FOLDER,
  UPDATE_FOLDER,
  DELETE_FOLDER,
} from "../actions/folderActions";

const initialState = {
  folderId: [],
  folderName: [],
  folderModelList: [],
  selectedFolder: null,
  fetchFoldersStatus: -1,
};

const foldersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FOLDER: {
      return produce(state, (draft) => {
        draft.folderId.push(action.payload.folderId);
        draft.folderName.push(action.payload.folderName);
        draft.folderModelList.push(JSON.stringify(action.payload.modelList));
      });
    }
    case GET_FOLDERS: {
      return produce(state, (draft) => {
        draft.folderId = [];
        draft.folderName = [];
        draft.folderModelList = [];
        for (let i in action.payload.data) {
          if (!draft.folderId.includes(action.payload.data[i].folderId)) {
            draft.folderId.push(action.payload.data[i].folderId);
            draft.folderName.push(action.payload.data[i].folderName);
            draft.folderModelList.push(action.payload.data[i].modelList);
          }
        }
        draft.fetchFoldersStatus = 1;
      });
    }
    case SET_CURRENT_FOLDER: {
      return produce(state, (draft) => {
        draft.selectedFolder = action.payload;
      });
    }
    case UPDATE_FOLDER: {
      return produce(state, (draft) => {
        if (draft.selectedFolder) {
          draft.folderName[draft.selectedFolder] = action.payload.folderName;
          draft.folderModelList[draft.selectedFolder] =
            action.payload.modelList;
        } else {
          let index = draft.folderId.indexOf(action.payload.folderId);
          draft.folderName[index] = action.payload.folderName;
          draft.folderModelList[index] = action.payload.modelList;
        }
      });
    }
    case DELETE_FOLDER: {
      return produce(state, (draft) => {
        if (draft.folderId[action.payload.index] === action.payload.folderId) {
          draft.folderId.splice(action.payload.index, 1);
          draft.folderName.splice(action.payload.index, 1);
          draft.folderModelList.splice(action.payload.index, 1);
        }
      });
    }
    default: {
      return state;
    }
  }
};
export default foldersReducer;
