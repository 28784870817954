import { produce } from "immer";
import {
  ADD_PLAN,
  DELETE_PLAN,
  GET_PLAN,
  UPDATE_PLAN,
  GET_PLAN_DETAILS
} from "../actions/planActions";

const initialState = {
  PlanId: [],
  PlanName: [],
  PlanPricing: [],
};

const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PLAN: {
      return produce(state, (draft) => {
        draft.PlanId.push(action.payload.PlanId);
        draft.PlanName.push(action.payload.PlanName);
        draft.PlanPricing.push(action.payload.PlanPricing);
      });
    }
    case UPDATE_PLAN: {
      return produce(state, (draft) => {
        draft.PlanId[action.payload.planIndex] =
          action.payload.PlanId;
        draft.PlanName[action.payload.planIndex] =
          action.payload.PlanName;
        draft.PlanPricing[action.payload.planIndex] =
          action.payload.PlanPricing;
      });
    }
    case DELETE_PLAN: {
      return produce(state, (draft) => {
        draft.PlanId.splice(action.payload.planIndex);
        draft.PlanName.splice(action.payload.planIndex);
        draft.PlanPricing.splice(action.payload.planIndex);
      });
    }
    case GET_PLAN: {
      return produce(state, (draft) => {
        for (let i in action.payload.data) {
          draft.PlanId.push(action.payload.data[i].planId);
          draft.PlanName.push(action.payload.data[i].planName);
          draft.PlanPricing.push(action.payload.data[i].planPricing)          
        }
      });
    }
    case GET_PLAN_DETAILS: {
      return produce(state, (draft) => {
        for (let i in action.payload.data) {
          draft.PlanId.push(action.payload.data[i].planId);
          draft.PlanName.push(action.payload.data[i].planName);
          draft.PlanPricing.push(action.payload.data[i].planPricing)          
        }
      });
    }
    default: {
      return state;
    }
  }
};
export default planReducer;
