// const dev_origin_path = "http://18.135.103.83:8081/api/";
// const dev_origin_path = "https://serverapiprod.cotrinity.com/api/";

const dev_origin_path = "https://serverapidev.cotrinity.com/api/";
const prod_origin_path = "https://serverapidev.cotrinity.com/api/";

const dev_local = {
  url: {
    API_GET_MODEL_LIST: `${dev_origin_path}Model/GetModels?userId=`,
    API_UPLOAD_MODEL: `${dev_origin_path}Files/upload`,
    API_DELETE_MODEL: `${dev_origin_path}Files/delete`,
    API_RENAME_MODEL: `${dev_origin_path}Model/RenameModel`,
    API_PUT_TEMPID: `${dev_origin_path}Link/CreateLink`,
    API_MODEL_CHECK_LINK: `${dev_origin_path}Link/CheckLink?tempId=`,
    API_GET_SAVED_VIEW_LIST: `${dev_origin_path}View/GetViews?modelId=`,
    API_UPDATE_SAVED_VIEW_NAME: `${dev_origin_path}View/UpdateView`,
    API_SAVE_VIEW: `${dev_origin_path}View/AddView`,
    API_DELETE_SAVED_VIEW: `${dev_origin_path}View/DeleteView`,
    API_ADD_TEMPLATE: `${dev_origin_path}Template/AddTemplate`,
    API_UPDATE_TEMPLATE: `${dev_origin_path}Template/UpdateTemplate`,
    API_GET_TEMPLATE: `${dev_origin_path}Template/GetTemplate?modelId=`,
    API_DELETE_TEMPLATE: `${dev_origin_path}Template/DeleteTemplate`,
    API_ADD_CUSTOM_PROPERTIES: `${dev_origin_path}Properties/AddProperties`,
    API_UPDATE_CUSTOM_PROPERTIES: `${dev_origin_path}Properties/UpdateProperties`,
    API_GET_CUSTOM_PROPERTIES: `${dev_origin_path}Properties/GetProperties?modelId=`,
    API_GET_SUBSCRIPTION: `${dev_origin_path}Subscription/GetSubscription?userId=`,
    API_DELETE_SUBSCRIPTION: `${dev_origin_path}Subscription/DeleteSubscription`,
    API_UPDATE_SUBSCRIPTION: `${dev_origin_path}Subscription/UpdateSubscription`,
    API_ADD_SUBSCRIPTION: `${dev_origin_path}Subscription/AddSubscription`,
    API_GET_PLAN: `${dev_origin_path}Plan/GetPlan?planId=`,
    API_DELETE_PLAN: `${dev_origin_path}Plan/DeletePlan`,
    API_UPDATE_PLAN: `${dev_origin_path}Plan/UpdatePlan`,
    API_ADD_PLAN: `${dev_origin_path}Plan/AddPlan`,
    API_GET_PLAN_Details: `${dev_origin_path}Plan/GetPlanDetails?email=`,
    API_EXPORT_VIEW: `https://developer.api.autodesk.com/modelderivative/v2/designdata/job`,
    API_ADD_FOLDER: `${dev_origin_path}Folder/AddFolder`,
    API_GET_FOLDERS: `${dev_origin_path}Folder/GetFolders?userId=`,
    API_UPDATE_FOLDER: `${dev_origin_path}Folder/UpdateFolder`,
    API_EXPORT_VIEW: `https://developer.api.autodesk.com/modelderivative/v2/regions/eu/designdata`,
    API_DELETE_FOLDER: `${dev_origin_path}Folder/DeleteFolder`,
    API_SPLIT_VALUE: `${dev_origin_path}Model/UpdateSplitValue`,
    API_UPDATE_TRANSLATION_STATUS: `${dev_origin_path}Model/UpdateTranslationStatus`,
    WEBHOOK_CALLBACK_URL: `https://viewerdev.cotrinity.com/uploadCallback`,
    SSE_URL: `https://viewerdev.cotrinity.com/translationCompleted`,
    API_GET_MODELPROPERTIES: `${dev_origin_path}ModelProperties/GetModelProperties?modelId=`,
    API_DELETE_MODELPROPERTIES: `${dev_origin_path}ModelProperties/DeleteModelProperties`,
    API_UPDATE_MODELPROPERTIES: `${dev_origin_path}ModelProperties/UpdateModelProperties`,
    API_ADD_MODELPROPERTIES: `${dev_origin_path}ModelProperties/AddModelProperties`,
    API_UPDATE_EXPORT_COUNT: `${dev_origin_path}Subscription/UpdateObjExportCount`,
  },
};

const dev_server = {
  url: {
    API_GET_MODEL_LIST: `${dev_origin_path}Model/GetModels?userId=`,
    API_UPLOAD_MODEL: `${dev_origin_path}Files/upload`,
    API_DELETE_MODEL: `${dev_origin_path}Files/delete`,
    API_RENAME_MODEL: `${dev_origin_path}Model/RenameModel`,
    API_PUT_TEMPID: `${dev_origin_path}Link/CreateLink`,
    API_MODEL_CHECK_LINK: `${dev_origin_path}Link/CheckLink?tempId=`,
    API_GET_SAVED_VIEW_LIST: `${dev_origin_path}View/GetViews?modelId=`,
    API_UPDATE_SAVED_VIEW_NAME: `${dev_origin_path}View/UpdateView`,
    API_SAVE_VIEW: `${dev_origin_path}View/AddView`,
    API_DELETE_SAVED_VIEW: `${dev_origin_path}View/DeleteView`,
    API_ADD_TEMPLATE: `${dev_origin_path}Template/AddTemplate`,
    API_UPDATE_TEMPLATE: `${dev_origin_path}Template/UpdateTemplate`,
    API_GET_TEMPLATE: `${dev_origin_path}Template/GetTemplate?modelId=`,
    API_DELETE_TEMPLATE: `${dev_origin_path}Template/DeleteTemplate`,
    API_ADD_CUSTOM_PROPERTIES: `${dev_origin_path}Properties/AddProperties`,
    API_UPDATE_CUSTOM_PROPERTIES: `${dev_origin_path}Properties/UpdateProperties`,
    API_GET_CUSTOM_PROPERTIES: `${dev_origin_path}Properties/GetProperties?modelId=`,
    API_GET_SUBSCRIPTION: `${dev_origin_path}Subscription/GetSubscription?userId=`,
    API_DELETE_SUBSCRIPTION: `${dev_origin_path}Subscription/DeleteSubscription`,
    API_UPDATE_SUBSCRIPTION: `${dev_origin_path}Subscription/UpdateSubscription`,
    API_ADD_SUBSCRIPTION: `${dev_origin_path}Subscription/AddSubscription`,
    API_GET_PLAN: `${dev_origin_path}Plan/GetPlan?planId=`,
    API_DELETE_PLAN: `${dev_origin_path}Plan/DeletePlan`,
    API_UPDATE_PLAN: `${dev_origin_path}Plan/UpdatePlan`,
    API_ADD_PLAN: `${dev_origin_path}Plan/AddPlan`,
    API_GET_PLAN_Details: `${dev_origin_path}Plan/GetPlanDetails?email=`,
    API_EXPORT_VIEW: `https://developer.api.autodesk.com/modelderivative/v2/designdata/job`,
    API_ADD_FOLDER: `${dev_origin_path}Folder/AddFolder`,
    API_GET_FOLDERS: `${dev_origin_path}Folder/GetFolders?userId=`,
    API_UPDATE_FOLDER: `${dev_origin_path}Folder/UpdateFolder`,
    API_DELETE_FOLDER: `${dev_origin_path}Folder/DeleteFolder`,
    API_SPLIT_VALUE: `${dev_origin_path}Model/UpdateSplitValue`,
    API_UPDATE_TRANSLATION_STATUS: `${dev_origin_path}Model/UpdateTranslationStatus`,
    WEBHOOK_CALLBACK_URL: `https://viewerdev.cotrinity.com/uploadCallback`,
    SSE_URL: `https://viewerdev.cotrinity.com/translationCompleted`,
    API_GET_MODELPROPERTIES: `${dev_origin_path}ModelProperties/GetModelProperties?modelId=`,
    API_DELETE_MODELPROPERTIES: `${dev_origin_path}ModelProperties/DeleteModelProperties`,
    API_UPDATE_MODELPROPERTIES: `${dev_origin_path}ModelProperties/UpdateModelProperties`,
    API_ADD_MODELPROPERTIES: `${dev_origin_path}ModelProperties/AddModelProperties`,
    API_UPDATE_EXPORT_COUNT: `${dev_origin_path}Subscription/UpdateObjExportCount`,
  },
};

const prod = {
  url: {
    API_GET_MODEL_LIST: `${prod_origin_path}Model/GetModels?userId=`,
    API_UPLOAD_MODEL: `${prod_origin_path}Files/upload`,
    API_DELETE_MODEL: `${prod_origin_path}Files/delete`,
    API_RENAME_MODEL: `${prod_origin_path}Model/RenameModel`,
    API_PUT_TEMPID: `${prod_origin_path}Link/CreateLink`,
    API_MODEL_CHECK_LINK: `${prod_origin_path}Link/CheckLink?tempId=`,
    API_GET_SAVED_VIEW_LIST: `${prod_origin_path}View/GetViews?modelId=`,
    API_UPDATE_SAVED_VIEW_NAME: `${prod_origin_path}View/UpdateView`,
    API_SAVE_VIEW: `${prod_origin_path}View/AddView`,
    API_DELETE_SAVED_VIEW: `${prod_origin_path}View/DeleteView`,
    API_ADD_TEMPLATE: `${prod_origin_path}Template/AddTemplate`,
    API_UPDATE_TEMPLATE: `${prod_origin_path}Template/UpdateTemplate`,
    API_GET_TEMPLATE: `${prod_origin_path}Template/GetTemplate?modelId=`,
    API_DELETE_TEMPLATE: `${prod_origin_path}Template/DeleteTemplate`,
    API_ADD_CUSTOM_PROPERTIES: `${prod_origin_path}Properties/AddProperties`,
    API_UPDATE_CUSTOM_PROPERTIES: `${prod_origin_path}Properties/UpdateProperties`,
    API_GET_CUSTOM_PROPERTIES: `${prod_origin_path}Properties/GetProperties?modelId=`,
    API_GET_SUBSCRIPTION: `${prod_origin_path}Subscription/GetSubscription?userId=`,
    API_DELETE_SUBSCRIPTION: `${prod_origin_path}Subscription/DeleteSubscription`,
    API_UPDATE_SUBSCRIPTION: `${prod_origin_path}Subscription/UpdateSubscription`,
    API_ADD_SUBSCRIPTION: `${prod_origin_path}Subscription/AddSubscription`,
    API_GET_PLAN: `${prod_origin_path}Plan/GetPlan?planId=`,
    API_DELETE_PLAN: `${prod_origin_path}Plan/DeletePlan`,
    API_UPDATE_PLAN: `${prod_origin_path}Plan/UpdatePlan`,
    API_ADD_PLAN: `${prod_origin_path}Plan/AddPlan`,
    API_GET_PLAN_Details: `${prod_origin_path}Plan/GetPlanDetails?email=`,
    API_EXPORT_VIEW: `https://developer.api.autodesk.com/modelderivative/v2/designdata/job`,
    API_ADD_FOLDER: `${prod_origin_path}Folder/AddFolder`,
    API_GET_FOLDERS: `${prod_origin_path}Folder/GetFolders?userId=`,
    API_UPDATE_FOLDER: `${prod_origin_path}Folder/UpdateFolder`,
    API_EXPORT_VIEW: `https://developer.api.autodesk.com/modelderivative/v2/regions/eu/designdata`,
    API_DELETE_FOLDER: `${prod_origin_path}Folder/DeleteFolder`,
    API_SPLIT_VALUE: `${prod_origin_path}Model/UpdateSplitValue`,
    API_UPDATE_TRANSLATION_STATUS: `${prod_origin_path}Model/UpdateTranslationStatus`,
    WEBHOOK_CALLBACK_URL: `https://viewerdev.cotrinity.com/uploadCallback`,
    SSE_URL: `https://viewerdev.cotrinity.com/translationCompleted`,
    API_GET_MODELPROPERTIES: `${prod_origin_path}ModelProperties/GetModelProperties?modelId=`,
    API_DELETE_MODELPROPERTIES: `${prod_origin_path}ModelProperties/DeleteModelProperties`,
    API_UPDATE_MODELPROPERTIES: `${prod_origin_path}ModelProperties/UpdateModelProperties`,
    API_ADD_MODELPROPERTIES: `${prod_origin_path}ModelProperties/AddModelProperties`,
    API_UPDATE_EXPORT_COUNT: `${prod_origin_path}Subscription/UpdateObjExportCount`,
  },
};

const config_env = () => {
  const env = process.env.NODE_ENV;
  const react_env = process.env.REACT_APP_ENV;
  if (env !== "test") {
    if (env === "production") {
      if (react_env === "dev_server") {
        return dev_server;
      } else {
        return prod;
      }
    } else {
      return dev_local;
    }
  }
};

export const urlConfig = config_env();
