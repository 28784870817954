/* global Autodesk, THREE */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Rotate an object around an arbitrary axis in object space
let rotObjectMatrix;
function rotateAroundObjectAxis(object, axis, radians) {
    rotObjectMatrix = new THREE.Matrix4();
    rotObjectMatrix.makeRotationAxis(axis.normalize(), radians);
    object.matrix.multiply(rotObjectMatrix);
    object.rotation.setFromRotationMatrix(object.matrix);
}

const mapContent = (props) => {

    const quad = (lat, lng) => {
        const mapType = props.mapType === 0 ? 'ckql1oxxa1ql317p471gdeh8c' : 'ckps7z91b0lit17o8ljiedpl2';
        // const mesh = new THREE.Mesh(
        //     new THREE.BoxGeometry( 1280, 1280, 5 ),
        //     new THREE.MeshLambertMaterial({ color:0xe6e6e6, depthWrite:false, 
        //         map: (THREE.ImageUtils.loadTexture(`https://api.mapbox.com/styles/v1/dheroying/${mapType}/static/${lng},${lat},17,0/1280x1280@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`)) })
        // );

        const token = "pk.eyJ1Ijoid2FycmVuYmV0dHMiLCJhIjoiY2tyY2d6b2J0MDFkMjJ2bGo4cWlpcXlueiJ9.45yJbOromoLtWX9ZQ7l5mA"
        const mesh = new THREE.Mesh(
            new THREE.BoxGeometry( 1280, 1280, 5 ),
            new THREE.MeshLambertMaterial({ color:0xe6e6e6, depthWrite:false, 
                map: (THREE.ImageUtils.loadTexture(`https://api.mapbox.com/styles/v1/dheroying/${mapType}/static/${lng},${lat},17,0/1280x1280@2x?access_token=${token}`)) })
        );
        mesh.position.set (0, 0, 0);
        let zAxis = new THREE.Vector3(0,0,1);
        // rotateAroundWorldAxis(mesh, zAxis, props.rotation * Math.PI / 180);
        rotateAroundObjectAxis(mesh, zAxis, props.rotation * Math.PI / 180);
        // mesh.rotation.setFromVector3(new THREE.Vector3( 0, 0, props.rotation * Math.PI / 180));
        mesh.scale.set(props.scale, props.scale, props.scale);
        return mesh;
    }

    useEffect(() => {
        if(props.hasBackgroundMap) {
            props.viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, ()=>{
                changeMapBackground();
            });
        }
    }, [])

    useEffect(() => {
        if(props.isLocationChanged && props.hasBackgroundMap) {
            props.viewer.overlays.removeScene('map');
            changeMapBackground();
        }
        if(props.isLocationChanged && !props.hasBackgroundMap) {
            props.viewer.overlays.removeScene('map');
        }
    }, [props.mapLocation, props.hasBackgroundMap, props.mapType, props.scale, props.rotation])

    const changeMapBackground = () => {
        const boundingBoxHeight = props.viewer.model.getData().modelSpaceBBox.min.z;
        if (!props.viewer.overlays.hasScene('map')) {
            props.viewer.overlays.addScene('map');
        }
        const tiles = new THREE.Object3D();
        tiles.position.set(0, 0, (boundingBoxHeight - 5));
        tiles.scale.set(2.034,2.034,1);
        tiles.add(quad(props.mapLocation.lat, props.mapLocation.lng))
        window.tiles=tiles;
        props.viewer.overlays.addMesh(tiles, 'map');
    }
    

    return (
        <></>
    )

}

const mapStateToProps = state => {
    const { hasBackgroundMap, mapType, mapLocation, isLocationChanged, scale, rotation } = state.viewer;
    return { hasBackgroundMap, mapType, mapLocation, isLocationChanged, scale, rotation };
}
  
export default connect(mapStateToProps, {})(mapContent);
