import React from "react";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { addFolder } from "../../actions/folderActions";
import { connect, useDispatch } from "react-redux";
import "./style.scss";

const CreateFolder = ({ open, setOpen, ...props }) => {
  const dispatch = useDispatch();
  const [newFolderName, setNewFolderName] = useState("");
  const [nameExist, setNameExistOpen] = useState(false);
  const [nameEmpty, setNameEmpty] = useState(false);

  const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  const addNewFolder = () => {
    if (newFolderName !== "") {
      setNameEmpty(false);
      setOpen(false);
      if (
        props.folderName.find((element) => {
          return element === newFolderName;
        })
      ) {
        setNameExistOpen(true);
      } else {
        dispatch(
          addFolder(uuidv4(), localStorage.getItem("subId"), newFolderName, [])
        );
      }
    } else {
      setNameEmpty(true);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle><span style={{ fontFamily: "'DM Sans Medium' , sans-serif"}}>Create Folder</span></DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Folder Name"
            type="email"
            fullWidth
            variant="standard"
            onChange={(event) => setNewFolderName(event.target.value)}
          />
          {nameEmpty && (
            <div style={{ fontSize: "small", color: "red" }}>
              Folder name cannot be empty.
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button style={{ fontFamily: "'DM Sans Medium' , sans-serif"}} onClick={() => setOpen(false)}>Cancel</Button>
          <Button style={{ fontFamily: "'DM Sans Medium' , sans-serif"}} onClick={() => addNewFolder()}>Add</Button>
        </DialogActions>
      </Dialog>
      {nameExist ? (
        <Dialog
          open={nameExist}
          onClose={() => {
            setNameExistOpen(false);
          }}
        >
          <DialogTitle>Folder is already exist</DialogTitle>
          <DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setNameExistOpen(false);
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { folderId, folderName, folderModelList } = state.folders;
  return { folderId, folderName, folderModelList };
};

export default connect(mapStateToProps, { addFolder })(CreateFolder);
