import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => {
    return {
        grow: {
            flexGrow: 1,
        },

        dataTile: {
            display: 'flex',
            justifyContent: 'space-around',
            overflow: 'hidden',
            minWidth: 200,
            width: 200,
        },

        dataTileTitleBack: {
            height: 45,
            width: 200,
            position: 'relative',
            background: '#d5dbe1',
        },

        dataTileTitleFront: {
            color: "#000",
        },

        dataTileImage: {
            width: 200,
            height: 200,
        },

        paginationFront: {
            color: "#000",
        },

        chip: {
            height:25,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },

        link: {
            textDecoration: 'none',
            color: 'rgb(0,0,0)',
            '&hover': {
                textDecoration: 'none',
                fontWeight: 'bold',
                cursor: 'pointer',
            },
        },

        iconButtonLarge: {
            fontSize: 'large',
        },

        lr_p: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },

        tb_p: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },

        tb_m: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },

        lr_m: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
        }
    }
};

export default withStyles(styles);