import { useEffect } from "react";
import { useHistory } from "react-router";
import {
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { useDispatch } from "react-redux";


const poolData = {
  UserPoolId: "eu-west-2_Lp7lR3Yvy",
  ClientId: "6n97f0elsb6se1hdakgifcrrsa",
};

const UserPool = new CognitoUserPool(poolData);

const AuthCognito = ({ children }) => {
  const history = useHistory();
  const user = UserPool.getCurrentUser();

  const dispatch = useDispatch();

  useEffect(() => {
    const initAuth = async () => {
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            history.push("/login");
          } else {
            user.getUserAttributes((err, attributes) => {
              if (err) {
                history.push("/login");
              } else {
                const results = {};

                for (let attribute of attributes) {
                  const { Name, Value } = attribute;
                  results[Name] = Value;
                }
                return children;
              }
            });
          }
        });
      } else {
        const location = window.location.href;
        if(!location.includes('/shared_viewer') && !location.includes('/privacypolicy') && !location.includes('/termsandconditions')) {
          history.push("/login"); 
        }
      }
    };

    initAuth();
  }, [dispatch]);

  return children;
};

export default AuthCognito;
