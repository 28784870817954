import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, useParams } from "react-router-dom";
import {
  uploadNewModel,
  updateTranslationStatus,
  getModelList,
} from "../../actions/modelsActions";
import Modal from "../Modal/Modal";
import AboutModal from "../Modal/aboutModal";
import logo from "../../images/Headerlogo.png";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LogOut from "@material-ui/icons/ExitToApp";
import { signOut } from "../../actions/accountActions";
import "./style.scss";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { uploadToS3Bucket } from "../../services/apiHelper";
import { toastr } from "react-redux-toastr";
import { setCredits, getSubscription } from "../../actions/subscriptionActions";
import { Environment, constants } from "../Subscription/config";
import CreateFolder from "../Folders/CreateFolder";
import {
  updateFolder,
  getFolders,
  setCurrentFolder,
} from "../../actions/folderActions";
import { urlConfig } from "../../constants/urlConstants";
import getPlanDetails from "../../actions/planActions";
import * as dotenv from "dotenv"; // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
import { v4 as uuidv4 } from "uuid";
dotenv.config();
const useStyles = makeStyles((theme) => ({
  topBarContainer: {
    background: theme.palette.common.white,
    width: "100vw",
    height: "5rem",
    display: "flex",
    alignItems: "center",
    boxShadow: "10px 10px 20px #dbdbdb, -10px -10px 20px #ffffff",
  },
  menuText: {
    textAlign: "right",
  },
  textDisabled: {
    background: "#9DB4B4",
    color: theme.palette.text.disabled,
    fontWeight: "bold",
    // color: "#363636",
    fontSize: "1.25rem",

    // fontWeight: "500",
    lineHeight: "1.6",
    letterSpacing: "0.0075em",
    "&:hover": {
      textDecoration: "unset",
    },
  },
  button: {
    fontWeight: "bold",
    color: "#F19505",
  },
  avatarContainer: {
    right: "2rem",
    position: "absolute",
    top: ".8rem",
  },
  avatar: {
    boxShadow: "none",
    fontWeight: "bold",
    borderRadius: "50%",
    height: "3.5rem",
    width: "3.5rem !important",
    color: "#F19505",
    backgroundColor: "#062D35",
    fontFamily: "'DM Sans Medium' , sans-serif",
  },
  logoStyle: {
    width: "12rem !important",
  },
}));
const StyledMenu = withStyles({
  paper: {
    boxShadow: "1px 1px 25px #dbdbdb",
    width: "200px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const TopBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fName = useParams();
  const userId = localStorage.getItem("subId");
  const email = localStorage.getItem("email");
  const [openModal, setOpenModal] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = React.useState(true);
  const [translationSnackBarOpen, setTranslationSnackBarOpen] =
    React.useState(false);
  const [name, setName] = useState("");
  const [aboutModalShow, setAboutModalShow] = useState(false);
  const [newFolderOpen, setNewFolderOpen] = useState(false);
  const [uploadSeverity, setUploadSeverity] = useState("");
  const [uploadSnackbar, setUploadSnackbar] = useState(false);
  const [subscribedUser, setSubscribedUser] = useState(false);
  const [isDevelopment, setDevelopment] = useState(false);
  const url = window.location.pathname;

  useEffect(() => {
    const userName = localStorage.getItem("username");

    if (userName) {
      const splittedName = userName.split(" ");
      const firstInitial = splittedName[0][0];
      const lastInitial = splittedName[1][0];
      setName(firstInitial.toUpperCase() + lastInitial.toUpperCase());
    }
    if (!props.SubscribedPlanId.length) {
      dispatch(getSubscription(userId));
    }

    if (!props.fetchFoldersStatus) {
      dispatch(getFolders(localStorage.getItem("subId")));
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_COTRINITY_ENV === "development")
      setDevelopment(true);
    else setDevelopment(false);

    const email = localStorage.getItem("email");
    if (
      !(
        email === "warren.betts@digitalflowplanning.com" ||
        email === "warren.betts@cotrinity.com"
      ) &&
      (new Date() > new Date(props.SubscriptionEndDate[0]) ||
        props.SubscriptionEndDate[0] === undefined) &&
      new Date().getDate() !=
        new Date(props.SubscriptionEndDate[0]).getDate() &&
      props.SubscriptionDetailsFetched > 0
    ) {
      setSubscribedUser(true);
    }
  }, [props.SubscriptionDetailsFetched, props.SubscribedPlanId]);

  useEffect(() => {
    if (
      subscribedUser &&
      (props.ActivePlan[0] === undefined || props.ActivePlan.length > 0)
    ) {
      props.history.push("/subscription");
    }
  }, [subscribedUser]);

  useEffect(() => {
    if (props.uploadingResult === 1) {
      setSnackBarOpen(true);
      // let fName = useParams().folderName;
      let fIndex = parseInt(props.selectedFolder);
      if (fIndex < 0) {
        for (let i in props.folderName) {
          if (props.folderName[i] === fName.folderName) {
            fIndex = parseInt(i);
          }
        }
        setCurrentFolder(fIndex);
      }
      dispatch(getModelList(localStorage.getItem("subId")));
      //dispatch(getFolders(localStorage.getItem("subId")));
      let newModelId = localStorage.getItem("newModelId");
      if (
        newModelId !== "" &&
        !JSON.parse(props.folderModelList[fIndex]).includes(newModelId)
      ) {
        let mList = [...JSON.parse(props.folderModelList[fIndex]), newModelId];
        dispatch(
          updateFolder(
            props.folderId[fIndex],
            localStorage.getItem("subId"),
            props.folderName[fIndex],
            JSON.stringify(mList)
          )
        );
      }
      // if (
      //   !JSON.parse(props.folderModelList[fIndex]).includes(
      //     props.modelList[props.modelList.length - 1].uniqueId
      //   )
      // ) {
      //   let mList = [
      //     ...JSON.parse(props.folderModelList[fIndex]),
      //     props.modelList[props.modelList.length - 1].uniqueId,
      //   ];
      //   dispatch(
      //     updateFolder(
      //       props.folderId[fIndex],
      //       localStorage.getItem("subId"),
      //       props.folderName[fIndex],
      //       JSON.stringify(mList)
      //     )
      //   );
      // }
      let sse = new EventSource(urlConfig.url.SSE_URL);
      sse.onmessage = (e) => {
        let data = JSON.parse(e.data);
        if (
          data.hookId &&
          data.modelId &&
          data.translationStatus &&
          data.userId === localStorage.getItem("subId")
        ) {
          let model = findModel(data.modelId);
          if (
            model &&
            model.loadingStatus !== "Loaded" &&
            model.loadingStatus !== "Translation Failed"
          ) {
            dispatch(
              updateTranslationStatus(
                data.userId,
                data.modelId,
                data.translationStatus,
                data.hookId
              )
            );
            if (data.translationStatus === "Loaded") {
              setUploadSeverity("success");
            } else {
              setUploadSeverity("error");
            }
            setTranslationSnackBarOpen(true);
          }
        }
      };

      sse.onerror = (e) => {
        console.log("sse onerror ", e);
      };

      return () => {
        sse.close();
      };
    }
  }, [props.uploadingResult]);

  const findModel = (id) => {
    let model = null;
    if (props.modelList) {
      model = props.modelList.find((item) => item.uniqueId === id);
    }
    return model;
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCreateFolder = () => {
    setNewFolderOpen(true);
  };

  const handleSelectFile = async () => {
    let input = document.createElement("input");
    input.type = "file";
    input.click();
    input.onchange = async (event) => {
      const file = event.target.files[0];
      const fileName = event.target.files[0].name;
      const bucket = "digitalflowplanning/modelproperties";
      let selectedFileDetails = {
        Body: file,
        Key: fileName,
        Bucket: bucket,
      };
      await uploadToS3Bucket(selectedFileDetails);

      setUploadSnackbar(true);
    };
  };

  const handleUploadFile = async (event) => {
    let folderIndex = parseInt(props.selectedFolder);
    let generatedModelId = uuidv4();
    localStorage.setItem("newModelId", generatedModelId);
    let mList = JSON.parse(props.folderModelList[folderIndex]);
    mList = [...mList, generatedModelId];
    if (event) {
      if (
        email === "warren.betts@digitalflowplanning.com" ||
        email === "warren.betts@cotrinity.com"
      ) {
        await dispatch(
          uploadNewModel(
            event,
            generatedModelId,
            props.folderId[folderIndex],
            props.folderName[folderIndex],
            mList
          )
        );
        return;
      }

      if (
        props.SubscribedPlanId.length === 0 ||
        (new Date() > new Date(props.SubscriptionEndDate[0]) &&
          new Date().getDate() !=
            new Date(props.SubscriptionEndDate[0]).getDate())
      ) {
        const msg = "Couldn't find subscription details";
        errorNotification(msg);
        props.history.push("/subscription");
        return false;
      }
      const i = 0;
      if (!(email === constants.Testing.TestingEmail)) {
        switch (props.SubscribedPlanId[i]) {
          case constants.Development.subscriptionTrialPlanID:
            if (event.size / 1000000 > 300) {
              setOpenModal(false);
              const msg =
                "The file cannot be uploaded because it exceeds 300MB";
              errorNotification(msg);
              return false;
            }
            if (
              parseInt(props.UserCredits) < constants.Development.perModelPrice
            ) {
              setOpenModal(false);
              const msg = "Credits are low, the file cannot be uploaded";
              errorNotification(msg);
              return false;
            } else {
              await dispatch(
                uploadNewModel(
                  event,
                  generatedModelId,
                  props.folderId[folderIndex],
                  props.folderName[folderIndex],
                  mList
                )
              ).then((response) => {
                dispatch(setCredits(parseInt(props.UserCredits) - 6));
              });
            }
            break;
          case constants.Development.subscriptionLitePlanID:
            if (event.size / 1000000 > 500) {
              setOpenModal(false);
              const msg =
                "The file cannot be uploaded because it exceeds 500MB";
              errorNotification(msg);
              return false;
            }

            if (
              parseInt(props.UserCredits) < constants.Development.perModelPrice
            ) {
              setOpenModal(false);

              const msg = "Credits are low, the file cannot be uploaded";
              errorNotification(msg);
              return false;
            } else {
              await dispatch(
                uploadNewModel(
                  event,
                  generatedModelId,
                  props.folderId[folderIndex],
                  props.folderName[folderIndex],
                  mList
                )
              ).then((response) => {
                dispatch(setCredits(parseInt(props.UserCredits) - 6));
              });
            }
            break;
          case constants.Development.subscriptionBasicPlanID:
            if (event.size / 1000000 > 1000) {
              setOpenModal(false);
              const msg = "The file cannot be uploaded because it exceeds 1 GB";
              errorNotification(msg);
              return false;
            }
            if (
              parseInt(props.UserCredits) < constants.Development.perModelPrice
            ) {
              setOpenModal(false);

              const msg = "Credits are low, the file cannot be uploaded";
              errorNotification(msg);
              return false;
            } else {
              await dispatch(
                uploadNewModel(
                  event,
                  generatedModelId,
                  props.folderId[folderIndex],
                  props.folderName[folderIndex],
                  mList
                )
              ).then((response) => {
                dispatch(setCredits(parseInt(props.UserCredits) - 6));
              });
            }
            break;
          case constants.Development.subscriptionProPlanID:
            if (event.size / 1000000 > 1500) {
              setOpenModal(false);
              const msg =
                "The file cannot be uploaded because it exceeds 1.5 GB";
              errorNotification(msg);
              return false;
            }
            if (
              parseInt(props.UserCredits) < constants.Development.perModelPrice
            ) {
              setOpenModal(false);

              const msg = "Credits are low, the file cannot be uploaded";
              errorNotification(msg);
              return false;
            } else {
              await dispatch(
                uploadNewModel(
                  event,
                  generatedModelId,
                  props.folderId[folderIndex],
                  props.folderName[folderIndex],
                  mList
                )
              ).then((response) => {
                dispatch(setCredits(parseInt(props.UserCredits) - 6));
              });
            }
            break;
          default:
            break;
        }
      } else {
        switch (props.SubscribedPlanId[i]) {
          case constants.Testing.subscriptionTrialPlanID:
            if (event.size / 1000000 > 300) {
              setOpenModal(false);
              const msg =
                "The file cannot be uploaded because it exceeds 300MB";
              errorNotification(msg);
              return false;
            }
            if (parseInt(props.UserCredits) < constants.Testing.perModelPrice) {
              setOpenModal(false);

              const msg = "Credits are low, the file cannot be uploaded";
              errorNotification(msg);
              return false;
            } else {
              await dispatch(
                uploadNewModel(
                  event,
                  generatedModelId,
                  props.folderId[folderIndex],
                  props.folderName[folderIndex],
                  mList
                )
              ).then((response) => {
                dispatch(setCredits(parseInt(props.UserCredits) - 6));
              });
            }

            break;
          case constants.Testing.subscriptionLitePlanID:
            if (event.size / 1000000 > 500) {
              setOpenModal(false);
              const msg =
                "The file cannot be uploaded because it exceeds 500MB";
              errorNotification(msg);
              return false;
            }

            if (parseInt(props.UserCredits) < constants.Testing.perModelPrice) {
              setOpenModal(false);

              const msg = "Credits are low, the file cannot be uploaded";
              errorNotification(msg);
              return false;
            } else {
              await dispatch(
                uploadNewModel(
                  event,
                  generatedModelId,
                  props.folderId[folderIndex],
                  props.folderName[folderIndex],
                  mList
                )
              ).then((response) => {
                dispatch(setCredits(parseInt(props.UserCredits) - 6));
              });
            }

            break;
          case constants.Testing.subscriptionBasicPlanID:
            if (event.size / 1000000 > 1000) {
              setOpenModal(false);
              const msg = "The file cannot be uploaded because it exceeds 1 GB";
              errorNotification(msg);
              return false;
            }
            if (parseInt(props.UserCredits) < constants.Testing.perModelPrice) {
              setOpenModal(false);

              const msg = "Credits are low, the file cannot be uploaded";
              errorNotification(msg);
              return false;
            } else {
              await dispatch(
                uploadNewModel(
                  event,
                  generatedModelId,
                  props.folderId[folderIndex],
                  props.folderName[folderIndex],
                  mList
                )
              ).then((response) => {
                dispatch(setCredits(parseInt(props.UserCredits) - 6));
              });
            }
            break;
          case constants.Testing.subscriptionProPlanID:
            if (event.size / 1000000 > 1500) {
              setOpenModal(false);
              const msg =
                "The file cannot be uploaded because it exceeds 1.5 GB";
              errorNotification(msg);
              return false;
            }
            if (parseInt(props.UserCredits) < constants.Testing.perModelPrice) {
              setOpenModal(false);

              const msg = "Credits are low, the file cannot be uploaded";
              errorNotification(msg);
              return false;
            } else {
              await dispatch(
                uploadNewModel(
                  event,
                  generatedModelId,
                  props.folderId[folderIndex],
                  props.folderName[folderIndex],
                  mList
                )
              ).then((response) => {
                dispatch(setCredits(parseInt(props.UserCredits) - 6));
              });
            }
            break;
          default:
            break;
        }
      }
    }
  };

  const errorNotification = (msg) => {
    toastr.error(msg);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
    setTranslationSnackBarOpen(false);
  };

  const handleAboutInfo = () => {
    setAboutModalShow(true);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Navbar
        collapseOnSelect
        expand="md"
        bg="black"
        className="nav-back-shadow"
      >
        <Navbar.Brand>
          <Box display="flex" flexDirection="row" ml={3}>
            <a href="https://cotrinity.com/education/" target="blank">
              <img src={logo} alt="Logo" className={classes.logoStyle}></img>
            </a>
          </Box>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto center-items">
            {subscribedUser && props.ActivePlan[0] === undefined ? (
              <Nav.Link
                href="/subscription"
                className={classes.textDisabled}
                color="primary"
              >
                Models
              </Nav.Link>
            ) : (
              <Nav.Link
                href="/folders"
                className={classes.textDisabled}
                color="primary"
              >
                Models
              </Nav.Link>
            )}

            {/* <Nav.Link
              href="#"
              className={classes.textDisabled}
              color="primary"
            >
              About
            </Nav.Link> */}
            {url.includes("/dashboard") ? (
              <Nav.Link className={classes.textDisabled} color="secondary">
                <Button
                  className={classes.button}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleOpenModal}
                  // disabled={props.translatingStatus ? false : true}
                >
                  Upload
                </Button>
              </Nav.Link>
            ) : url.includes("/folders") ? (
              <Nav.Link className={classes.textDisabled} color="secondary">
                {subscribedUser && props.ActivePlan[0] === undefined ? (
                  <Button
                    disabled
                    className={classes.button}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleCreateFolder}
                    style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}
                  >
                    Create Folder
                  </Button>
                ) : (
                  <Button
                    className={classes.button}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleCreateFolder}
                    style={{ fontFamily: "'DM Sans Medium', sans-serif" }}
                  >
                    Create Folder
                  </Button>
                )}
              </Nav.Link>
            ) : (
              <div></div>
            )}
          </Nav>

          {/*Subscribe Button */}

          <Nav>
            {/* <Nav.Link> */}
            <Box flexDirection="row-reverse" className="avatar-button">
              {!isDevelopment ? (
                <Nav.Link
                  href="/subscription"
                  className={classes.textDisabled}
                  color="primary"
                >
                  Subscribe
                </Nav.Link>
              ) : (
                <Nav.Link>
                  <></>
                </Nav.Link>
              )}
            </Box>
            {/* </Nav.Link> */}
          </Nav>

          {/*User Account */}
          <Nav>
            {/* <Nav.Link> */}
            <Box flexDirection="row-reverse" className="avatar-button">
              <Button
                className={classes.avatar}
                variant="contained"
                onClick={handleClick}
              >
                {name}
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem>
                  <ListItemText
                    className="logout-text"
                    primary="About"
                    onClick={() => {
                      handleAboutInfo();
                    }}
                  />
                  {/* <ListItemIcon>
                    <AboutIcon fontSize="medium" />
                  </ListItemIcon> */}
                </StyledMenuItem>
                <StyledMenuItem>
                  <ListItemText
                    className="logout-text"
                    primary="Log out"
                    onClick={handleSignOut}
                  />
                  <ListItemIcon>
                    <LogOut fontSize="default" />
                  </ListItemIcon>
                </StyledMenuItem>
              </StyledMenu>
            </Box>
            {/* </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        modalTitle={"New Model"}
        status={props.uploadingResult}
        uploading={props.uploading}
        handleAcceptAction={(e) => handleUploadFile(e)}
      />
      <AboutModal
        show={aboutModalShow}
        onHide={() => {
          setAboutModalShow(false);
        }}
      />
      <CreateFolder open={newFolderOpen} setOpen={setNewFolderOpen} />
      {props.uploadingResult === 1 && (
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={5000}
          onClose={handleSnackBarClose}
        >
          <Alert variant="filled" severity="success">
            File uploaded successfully! Please wait for translation to finish.
          </Alert>
        </Snackbar>
      )}
      {uploadSeverity === "success" ? (
        <Snackbar
          open={translationSnackBarOpen}
          autoHideDuration={5000}
          onClose={handleSnackBarClose}
        >
          <Alert variant="filled" severity="success">
            Model translated successfully. File is ready to view!
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={translationSnackBarOpen}
          autoHideDuration={5000}
          onClose={handleSnackBarClose}
        >
          <Alert variant="filled" severity="error">
            Model translation failed! Please try uploading the file again.
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { uploading, uploadingResult, modelList, translatingStatus } =
    state.models;
  const {
    SubscribedPlanId,
    SubscriptionStartDate,
    SubscriptionEndDate,
    UserCredits,
    SubscriptionDetailsFetched,
    ActivePlan,
  } = state.subscription;
  const { folderId, folderName, folderModelList, selectedFolder } =
    state.folders;
  return {
    uploading,
    uploadingResult,
    modelList,
    translatingStatus,
    SubscribedPlanId,
    SubscriptionStartDate,
    SubscriptionEndDate,
    UserCredits,
    SubscriptionDetailsFetched,
    ActivePlan,
    folderId,
    folderName,
    folderModelList,
    selectedFolder,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    updateFolder,
    getFolders,
    setCredits,
    setCurrentFolder,
    updateTranslationStatus,
  })(TopBar)
);
