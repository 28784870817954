import axios from "axios";
import { urlConfig } from "../constants/urlConstants";

export const ADD_FOLDER = "ADD_FOLDER";
export const GET_FOLDERS = "GET_FOLDERS";
export const SET_CURRENT_FOLDER = "SET_CURRENT_FOLDER";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";

export function addFolder(folderId, userId, folderName, modelList) {
  return async (dispatch) => {
    try {
      const data = {
        folderId: folderId,
        userId: userId,
        folderName: folderName,
        modelList: JSON.stringify(modelList),
      };
      const config = {
        method: "post",
        url: urlConfig.url.API_ADD_FOLDER,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: ADD_FOLDER,
            payload: {
              folderId,
              folderName,
              modelList,
            },
          });
        } else {
          console.log("Error adding folder to database ", res);
        }
      });
    } catch (err) {
      console.log("Error in adding folder ", err);
      throw err;
    }
  };
}

export function getFolders(userId) {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: urlConfig.url.API_GET_FOLDERS + userId,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_FOLDERS,
            payload: {
              data: res.data,
            },
          });
        } else {
          console.log("Error fetching folders from database ", res);
        }
      });
    } catch (err) {
      console.log("Error in fetching folders ", err);
      throw err;
    }
  };
}

export function setCurrentFolder(index) {
  return (dispatch) => {
    try {
      dispatch({
        type: SET_CURRENT_FOLDER,
        payload: parseInt(index),
      });
    } catch (err) {
      console.log("Error setting current folder ", err);
      throw err;
    }
  };
}

export function updateFolder(folderId, userId, folderName, modelList) {
  return (dispatch) => {
    try {
      const data = {
        folderId: folderId,
        userId: userId,
        folderName: folderName,
        modelList: modelList,
      };
      const config = {
        method: "post",
        url: urlConfig.url.API_UPDATE_FOLDER,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_FOLDER,
            payload: {
              folderId,
              folderName,
              modelList,
            },
          });
        } else {
          console.log("Error updating folder in database ", res);
        }
      });
    } catch (err) {
      console.log("Error updating folder ", err);
      throw err;
    }
  };
}

export function deleteFolder(folderId, userId, index) {
  return (dispatch) => {
    try {
      const data = {
        folderId: folderId,
        userId: userId,
      };
      const config = {
        method: "post",
        url: urlConfig.url.API_DELETE_FOLDER,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: DELETE_FOLDER,
            payload: {
              folderId,
              index,
            },
          });
        } else {
          console.log("Error deleting folder from database ", res);
        }
      });
    } catch (err) {
      console.log("Error in deleting folder ", err);
      throw err;
    }
  };
}
