import React, { Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import * as clipboard from "clipboard-polyfill/text";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
  modalBody: {
    padding: "2.5rem 1rem",
    wordBreak: "break-all",
    //   textAlign: 'center'
  },
}));

const viewShareModal = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const clear = () => {
    props.onHide();
  };

  const handleSharedLinkCopy = async () => {
    let queryRefPoint = window.location.search.includes("true") ? true : false;
    let text = queryRefPoint
      ? `${window.location.origin}/shared_viewer/${props.tempid}?applyRefPoint=true`
      : `${window.location.origin}/shared_viewer/${props.tempid}`;

    await clipboard.writeText(text).then(
      function () {
        props.onHide();
        enqueueSnackbar("Shared link copied to your clipboard", {
          variant: "success",
        });
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  return (
    <Fragment>
      <Modal {...props} size="md" aria-labelledby="share-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title id="share-modal">Share Saved View</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modalBody}>
          {props.tempid && window.location.search.includes("true")
            ? `${window.location.origin}/shared_viewer/${props.tempid}` +
              `?applyRefPoint=true`
            : `${window.location.origin}/shared_viewer/${props.tempid}`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color={"primary"}
            type="submit"
            variant="contained"
            autoFocus
            onClick={() => handleSharedLinkCopy()}
          >
            Copy Link
          </Button>
          <Button type="button" onClick={() => clear()} color="secondary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default viewShareModal;
