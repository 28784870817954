/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getToken } from "../../actions/viewerActions";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Box } from "@material-ui/core";

import Model from "../Model/Model";
import { getModelList } from "../../actions/modelsActions";
import { getFolders } from "../../actions/folderActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  dashboardContainer: {
    marginLeft: "5rem",
    marginRight: "5rem",
    ["@media (max-width:600px)"]: {
      marginRight: "1rem",
      marginLeft: "1rem",
    },
    ["@media (max-width:960px)"]: {
      marginRight: "3rem",
      marginLeft: "3rem",
    },
  },
  noContentText: {
    color: theme.palette.text.disabled,
    fontWeight: "bold",
    height: "20rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.1rem",
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { viewerAuth } = useSelector((state) => state.viewer);
  const { modelList } = useSelector((state) => state.models);

  const dispatch = useDispatch();

  const [snackBarOpen, setSnackBarOpen] = React.useState(true);

  useEffect(() => {
    if (!viewerAuth) {
      dispatch(getToken());
    }
  }, [viewerAuth]);

  useEffect(() => {
    if (!modelList) {
      dispatch(getModelList(localStorage.getItem("subId")));
      dispatch(getFolders(localStorage.getItem("subId")));
    }
  }, [modelList]);

  useEffect(() => {
    if (props.deletingStatus === 1) {
      setSnackBarOpen(true);
    }
  }, [props.deletingStatus]);

  if (!viewerAuth) {
    return null;
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  return (
    <div
      style={{
        overflowY: "scroll",
        // position: "absolute",
        width: "100%",
        height: "85vh",
        // paddingBottom: "23%",
      }}
    >
      <Box mt={3} className={classes.dashboardContainer}>
        <Model deleting={props.deleting} delIndex={props.deletingIndex} />
        <Grid container spacing={3} className={classes.gridViewerContainer}>
          {modelList && modelList.length < 1 && (
            <Grid item xs={12}>
              <div className={classes.noContentText}>No models uploaded</div>
            </Grid>
          )}
        </Grid>
        {props.deletingStatus === 1 && (
          <Snackbar
            open={snackBarOpen}
            autoHideDuration={3000}
            onClose={handleSnackBarClose}
          >
            <Alert variant="filled" severity="success">
              Model has been deleted successfully!
            </Alert>
          </Snackbar>
        )}
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { SubscribedPlanId, SubscriptionEndDate } = state.subscription;
  const { deleting, deletingStatus, deletingIndex, modelList } = state.models;
  return {
    deleting,
    deletingStatus,
    deletingIndex,
    modelList,
    SubscribedPlanId,
    SubscriptionEndDate,
  };
};

export default withRouter(connect(mapStateToProps, {})(Dashboard));
