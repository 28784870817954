import React, { useRef, useState, useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router";
import ReNameModal from "../Modal/renameModel";
import ShareModal from "../Modal/shareModal";
import {
  Box,
  Typography,
  Checkbox,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import {
  deleteModel,
  setUploadedFileInfo,
  setViewerModel,
  getModelList,
} from "../../actions/modelsActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getURN, getTempId, getManifest } from "../../services/apiHelper";
import Tooltip from "@material-ui/core/Tooltip";
import { toastr } from "react-redux-toastr";
import { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {
  getFolders,
  setCurrentFolder,
  updateFolder,
} from "../../actions/folderActions";
import {
  withOrientationChange,
  isBrowser,
  isMobile,
  isIOS,
  isTablet,
} from "react-device-detect";
import { setApplyRefPointChecked } from "../../actions/modelsActions";
import {
  setLoadCustomProperties,
  refreshCustomProperties,
} from "../../actions/customParameterActions";

const useStyles = makeStyles((theme) => ({
  modelContainer: {
    background: theme.palette.primary.main,
    width: "100%",
    height: "10rem",
    alignItems: "center",
    borderRadius: "1rem",
    boxShadow: "10px 10px 20px #dbdbdb, -10px -10px 20px #ffffff",
    "&:hover": {
      background: theme.palette.primary.main,
      boxShadow: "5px 5px 10px #6c6868, -5px -5px 10px #6c6868",
    },
  },
  dotsContainer: {
    color: theme.palette.dots.main,
    height: "2rem",
  },
  nameContainer: {
    height: `calc(100% - 4rem)`,
    cursor: "pointer",
  },
  nameText: {
    fontWeight: "bold",
    fontSize: "1.3rem",
    color: "#ffffff",
    fontFamily : " 'DM Sans Medium' , sans-serif"
  },
  delThreeDots: {
    cursor: "pointer",
  },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#2b455f",
  },
  tooltip: {
    backgroundColor: "#2b455f",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const Model = ({ deleting, delIndex, ...props }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState([]);
  const anchorRef = React.useRef([]);
  const widthRef = useRef(null);
  const fName = useParams();
  const email = localStorage.getItem("email");

  const [width, setWidth] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [shareModalShow, setShareModalShow] = useState(false);
  const [refPointCoordinates, setRefPointCoordinates] = useState(false);
  const [selectedModels, setSelectedModels] = useState([]);
  const [renameModelIndex, setRenameModelIndex] = useState(-1);
  const [shareModelIndex, setShareModelIndex] = useState(-1);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [fileCount, setFileCount] = useState(true);
  const [tempIdFetched, setTempIdsFetched] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [selectedDeleteOption, setSelectedDeleteOption] = useState(0);
  const [deleteModelIndex, setDeleteModelIndex] = useState(-1);
  const [deleteModelId, setDeleteModelId] = useState(-1);
  const [deleteModelUniqueId, setDeleteModelUniqueId] = useState(-1);

  let movement_timer = null;
  const RESET_TIMEOUT = 100;

  useEffect(() => {
    if (!props.fetchFoldersStatus) {
      dispatch(getFolders(localStorage.getItem("subId")));
    } else {
      for (let i in props.folderName) {
        if (props.folderName[i] === fName.folderName) {
          dispatch(setCurrentFolder(i));
        }
      }
    }
    if (!props.modelList) {
      dispatch(getModelList(localStorage.getItem("subId")));
    }
  }, []);

  useEffect(() => {
    for (let i in props.folderName) {
      if (props.folderName[i] === fName.folderName) {
        dispatch(setCurrentFolder(i));
      }
    }
  }, [props.folderName]);

  const setDimension = () => {
    if (widthRef.current) {
      setWidth(widthRef.current.offsetWidth);
    }
  };

  useLayoutEffect(() => {
    setDimension();
  }, []);

  window.addEventListener("resize", () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(setDimension, RESET_TIMEOUT);
  });

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const handleToggle = (index) => {
    let temp = [...open];
    temp[index] = !temp[index];
    setOpen(temp);
  };

  const handleClose = (event, index) => {
    if (
      anchorRef.current[index] &&
      anchorRef.current[index].contains(event.target)
    ) {
      return;
    }

    let temp = [...open];
    temp[index] = false;
    setOpen(temp);
  };

  const checkManifest = (urn) => {
    return new Promise((res, rej) => {
      getManifest(urn)
        .then((value) => {
          if (value === true) res(true);
          else if (value === false) res("failed");
        })
        .catch((value) => {
          res(false);
        });
    });
  };

  const lauchViewer = async () => {
    if (selectedModels.find((element) => element === true)) {
      let historyUrl = "/viewer";
      let viewerModels = [];
      for (let i in selectedModels) {
        if (selectedModels[i]) {
          historyUrl += "/";
          let docId = props.modelList[i].modelURN;
          if (!docId) {
            getURN(props.modelList[i].displayName)
              .then((res) => {
                if (
                  res.data.length === 1 &&
                  res.data[0].loadingStatus === "Loaded"
                ) {
                  // dispatch(setViewerModel(res.data[0]));
                  viewerModels.push(res.data[0]);
                  historyUrl = historyUrl.concat(res.data[0].modelURN);
                } else {
                  stillLoading();
                }
              })
              .catch((err) => {
                error();
              });
          } else {
            let modelTranslated = await checkManifest(docId);
            if (modelTranslated === false) {
              toastr.error(
                props.modelList[i].displayName + " is still being translated"
              );
              toggleSelectedModel(i);
              return;
            } else if (modelTranslated === "failed") {
              toastr.error(
                props.modelList[i].displayName + " failed to translate. Please try reuploading the model"
              );
              toggleSelectedModel(i);
              return;
            }
            viewerModels.push(props.modelList[i]);
            historyUrl = historyUrl.concat(docId);
          }
        }
      }

      if (viewerModels.length > 1) {
        historyUrl = historyUrl.concat("?applyRefPoint=" + refPointCoordinates);
      }
      dispatch(refreshCustomProperties());
      dispatch(setLoadCustomProperties(true));
      dispatch(setViewerModel(viewerModels));
      history.push(historyUrl);
    }
  };

  const stillLoading = () => {
    toastr.error("File is still being uploaded");
  };

  const error = () => {
    toastr.error("Something went wrong. Please try again");
  };

  const updateFolderModels = (uniqueId) => {
    let folderIndex = parseInt(props.selectedFolder);
    if (folderIndex > -1) {
      let mList = JSON.parse(props.folderModelList[folderIndex]);
      let delIndex = mList.indexOf(uniqueId);
      mList.splice(delIndex, 1);
      dispatch(
        updateFolder(
          props.folderId[folderIndex],
          localStorage.getItem("subId"),
          props.folderName[folderIndex],
          JSON.stringify(mList)
        )
      );
    }
  };

  useEffect(() => {
    if (
      deleteModelIndex > -1 &&
      deleteModelUniqueId &&
      selectedDeleteOption > 0
    ) {
      if (deleteModelId.toLowerCase() === "null") {
        getURN(props.modelList[deleteModelIndex].displayName)
          .then((res) => {
            if (res.data && res.data.LoadingStatus === "Loaded") {
              dispatch(
                deleteModel(
                  deleteModelUniqueId,
                  res.data.DeleteId,
                  deleteModelIndex
                )
              );
            } else {
              setModalShow(false);
              stillLoading();
            }
          })
          .catch((err) => {
            error();
          });
      } else {
        dispatch(
          deleteModel(deleteModelUniqueId, deleteModelId, deleteModelIndex)
        );
      }
      updateFolderModels(deleteModelUniqueId);
      setDeleteModelIndex(-1);
      setSelectedDeleteOption(-1);
    }
  }, [selectedDeleteOption, deleteModelIndex]);
  
  const handleDeleteModel = (uniqueId, delId, index) => {
    setDeleteModelId(delId);
    setDeleteModelUniqueId(uniqueId);
    setDeleteModelIndex(index);
    setDeleteModelOpen(true);

    setOpen([]);
  };

  const handleRename = (index) => {
    setModalShow(true);
    setRenameModelIndex(index);
    // setOpen(false);
  };

  const handleShare = async (index) => {
    setTempIdsFetched(false);
    setShareModelIndex(index);
    showShareModal();
    if (!props.modelList[index].modelURN || !props.modelList[index].tempId) {
      await getURN(props.modelList[index].displayName)
        .then(async (res) => {
          let mIndex = 0;
          if (res.data.length > 1) {
            mIndex = res.data.findIndex(
              (model) => model.uniqueId === props.modelList[index].uniqueId
            );
          }
          if (
            res.data.length > 0 &&
            res.data[mIndex].loadingStatus === "Loaded"
          ) {
            if (!res.data[mIndex].modelURN) {
              toastr.error(
                "This model has not been uploaded correctly. Please delete this and upload it again."
              );
              setShareModalShow(false);
              return false;
            }
            await checkTempIdAndShare(res.data[mIndex], index);
          } else {
            setShareModalShow(false);
            stillLoading();
          }
        })
        .catch((err) => {
          error();
        });
    } else {
      await checkTempIdAndShare(props.modelList[index], index);
    }
  };

  const checkTempIdAndShare = async (data, index) => {
    await getTempId(props.modelList[index].uniqueId, "")
      .then(async (res) => {
        if (res.data && res.data.result === 1) {
          data.tempId = res.data.value;
          dispatch(setUploadedFileInfo(data, index));
          setTempIdsFetched(true);
        } else {
          error();
        }
      })
      .catch((err) => {
        error();
      });
  };

  const showShareModal = () => {
    setShareModalShow(true);
    // setOpen(false);
  };

  const toggleSelectedModel = (index) => {
    let tempChecked = [...selectedModels];
    if (!tempChecked[index] || tempChecked[index] === undefined) {
      tempChecked[index] = true;
    } else tempChecked[index] = false;
    setSelectedModels(tempChecked);

    let count = 0;
    for (let i in tempChecked) {
      if (tempChecked[i]) {
        ++count;
      }
    }

    if (count > 5 && email !== "warren.betts@digitalflowplanning.com" && email !== "warren.betts@cotrinity.com") {
      setSnackBarOpen(true);
      setFileCount(true);
    } else {
      setFileCount(false);
    }
    if (count === 0) {
      setFileCount(true);
    }
  };

  const onModelClick = (index) => {
    let temp = selectedModels;
    if (!temp[index] || temp[index] === undefined) temp[index] = true;
    setSelectedModels(temp);
    lauchViewer();
  };

  const handleApplyRefChange = (event) => {
    setRefPointCoordinates(event.target.checked);
    dispatch(setApplyRefPointChecked(event.target.checked));
  };

  return (
    <React.Fragment>
      {props.fetchFoldersStatus &&
      props.folderModelList &&
      props.folderModelList[props.selectedFolder] !== "[]" ? (
        <div
          style={
            isMobile
              ? {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  marginTop: "2%",
                  marginBottom: "2%",
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "sticky",
                  top: "0%",
                  background: "#fff",
                  zIndex: "1",
                  marginLeft: "-50px",
                  marginRight: "-50px",
                  paddingRight: "50px",
                }
              : {
                  marginTop: "2%",
                  marginBottom: "2%",
                  display: "flex",
                  justifyContent: "flex-end",
                }
          }
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleApplyRefChange}
                  color="primary"
                  checked={props.applyRefPointBool}
                />
              }
              label="Shared Coordinates"
            />
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            onClick={() => lauchViewer()}
            disabled={fileCount}
            style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}
          >
            Launch Viewer
          </Button>
        </div>
      ) : (
        <div></div>
      )}
      <Grid container spacing={3} className={classes.gridViewerContainer}>
        {props.modelList &&
          props.modelList.map((model, index) => (
            <>
              {props.fetchFoldersStatus &&
              props.folderModelList[props.selectedFolder] &&
              props.folderModelList[props.selectedFolder] !== "[]" &&
              props.folderModelList[props.selectedFolder].includes(
                model.uniqueId
              ) ? (
                <Grid key={index} item xs={12} sm={6} md={4} lg={2}>
                  <Box className={classes.modelContainer} ref={widthRef}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mr={3}
                      pt={2}
                      className={classes.dotsContainer}
                    >
                      {/* <FormGroup style={{ marginLeft: "8%" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => toggleSelectedModel(index)}
                              checked={selectedModels[index]}
                              color="primary"
                            />
                          }
                        />
                      </FormGroup> */}
                      <Checkbox
                        onChange={() => toggleSelectedModel(index)}
                        checked={
                          selectedModels[index] !== undefined
                            ? selectedModels[index]
                            : false
                        }
                        // inputProps={{ "aria-label": "controlled" }}
                        style={{color:"#F19505"}}
                      />
                      <MoreHorizIcon
                        className={classes.delThreeDots}
                        onClick={() => handleToggle(index)}
                        ref={(element) => (anchorRef.current[index] = element)}
                      />
                      <Popper
                        className="card-shadow"
                        open={open[index] ? true : false}
                        anchorEl={anchorRef.current[index]}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener
                                onClickAway={(event) =>
                                  handleClose(event, index)
                                }
                              >
                                <MenuList
                                  autoFocusItem={open[index] ? true : false}
                                  id="menu-list-grow"
                                >
                                  <MenuItem onClick={() => handleRename(index)}>
                                    <span style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}>Rename</span>
                                  </MenuItem>
                                  <MenuItem onClick={() => handleShare(index)}>
                                  <span style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}>Share</span>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      handleDeleteModel(
                                        model.uniqueId,
                                        model.deleteId,
                                        index
                                      )
                                    }
                                  >
                                    <span style={{ fontFamily: "'DM Sans Medium' , sans-serif" }}>Delete</span>
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Box>
                    {deleting && index === delIndex ? (
                      <Box className="text-center">
                        <CircularProgress color="secondary" />
                        <Typography>Deleting...</Typography>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        className={
                          classes.nameContainer + " " + "fileLabelWrapper"
                        }
                        onClick={() => {
                          onModelClick(index);
                        }}
                      >
                        <BootstrapTooltip
                          title={model.displayName}
                          enterDelay={1000}
                          leaveDelay={300}
                          placement="top"
                          arrow
                        >
                          <Typography
                            className={classes.nameText + " " + "fileNameLable"}
                            color="primary"
                          >
                            {model.displayName.length > 20
                              ? model.displayName.substr(0, 10) +
                                "..." +
                                model.displayName.substr(
                                  model.displayName.length - 7,
                                  model.displayName.length
                                )
                              : model.displayName}
                          </Typography>
                        </BootstrapTooltip>
                      </Box>
                    )}
                  </Box>
                </Grid>
              ) : (
                <div></div>
              )}
            </>
          ))}
        {modalShow && (
          <ReNameModal
            show={modalShow}
            model={props.modelList[renameModelIndex]}
            position={renameModelIndex}
            onHide={() => {
              setModalShow(false);
            }}
          />
        )}
        {shareModalShow && (
          <ShareModal
            show={shareModalShow}
            model={props.modelList[shareModelIndex]}
            tempIdsFetched={tempIdFetched}
            onHide={() => {
              setShareModalShow(false);
            }}
          />
        )}
      </Grid>
      <Dialog
        open={deleteModelOpen}
        onClose={() => {
          setDeleteModelOpen(false);
          setSelectedDeleteOption(0);
        }}
      >
        <DialogTitle>Delete Model?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this model?
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={() => {
                setSelectedDeleteOption(1);
                setDeleteModelOpen(false);
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setSelectedDeleteOption(0);
                setDeleteModelOpen(false);
              }}
            >
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      >
        <Alert variant="filled" severity="warning">
          Cannot select more than 5 files!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    folderId,
    folderName,
    folderModelList,
    selectedFolder,
    fetchFoldersStatus,
  } = state.folders;
  const { modelList, applyRefPointBool } = state.models;
  return {
    folderId,
    folderName,
    folderModelList,
    selectedFolder,
    fetchFoldersStatus,
    modelList,
    applyRefPointBool,
  };
};

export default connect(mapStateToProps, {
  getFolders,
  getModelList,
  setCurrentFolder,
  updateFolder,
})(Model);

// test