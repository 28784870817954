import axios from "axios";
import { urlConfig } from "../constants/urlConstants";
import { uploadToS3Bucket } from "../services/apiHelper";

export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const ADD_PROPERTIES = "ADD_PROPERTIES";
export const UPDATE_PROPERTIES = "UPDATE_PROPERTIES";
export const GET_PROPERTIES = "GET_PROPERTIES";
export const TEMPLATES_LOADING = "TEMPLATES_LOADING";
export const PROPERTIES_LOADING = "PROPERTIES_LOADING";
export const LOAD_CUSTOM_PROPERTIES = "LOAD_CUSTOM_PROPERTIES";
export const REFRESH_CUSTOM_PROPERTIES = "REFRESH_CUSTOM_PROPERTIES";

export function setTemplate(
  modelId,
  templateId,
  templateName,
  properties,
  multiModel
) {
  return async (dispatch) => {
    try {
      const data = {
        modelId: modelId,
        templateId: templateId,
        templateName: templateName,
        templateProperties: JSON.stringify(properties),
      };

      const config = {
        method: "post",
        url: urlConfig.url.API_ADD_TEMPLATE,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: ADD_TEMPLATE,
            payload: {
              modelId,
              templateId,
              templateName,
              properties,
              multiModel,
            },
          });
        } else {
          console.log("Error occured while adding template to database ", res);
        }
      });
    } catch (err) {
      console.log("Error in adding template ", err);
    }
  };
}

export function updateTemplate(
  index,
  modelId,
  templateId,
  templateName,
  templateProperties,
  multiModel
) {
  return async (dispatch) => {
    try {
      const data = {
        modelId: modelId,
        templateId: templateId,
        templateName: templateName,
        templateProperties: JSON.stringify(templateProperties),
      };

      const config = {
        method: "post",
        url: urlConfig.url.API_UPDATE_TEMPLATE,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_TEMPLATE,
            payload: {
              templateIndex: index,
              templateName,
              templateProperties,
              multiModel,
            },
          });
        } else {
          console.log(
            "Error occured while updating template to database ",
            res
          );
        }
      });
    } catch (err) {
      console.log("Error in updating template ", err);
    }
  };
}

export function deleteTemplate(
  index,
  modelId,
  templateId,
  uniqueId,
  multiModel
) {
  return async (dispatch) => {
    try {
      const data = {
        modelId: modelId,
        templateId: templateId,
        uniqueId: uniqueId,
      };
      const config = {
        method: "post",
        url: urlConfig.url.API_DELETE_TEMPLATE,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      var x = document.getElementById("snackbar");
      x.innerText = "Please wait while the template is being deleted..";
      x.className = "show";

      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: DELETE_TEMPLATE,
            payload: {
              templateIndex: index,
              multiModel: multiModel,
            },
          });
          var x = document.getElementById("snackbar");
          x.innerText = "Template deleted successfully!";
          x.className = "show";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
          }, 3000);
        } else {
          console.log("Error in deleting template from database");
        }
      });
    } catch (err) {
      console.log("Error in deleting template from database", err);
    }
  };
}

export function getTemplate(modelId, multiModel = false) {
  return async (dispatch) => {
    dispatch({
      type: TEMPLATES_LOADING,
    });
    try {
      const config = {
        method: "get",
        url: urlConfig.url.API_GET_TEMPLATE + modelId,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_TEMPLATE,
            payload: {
              data: res.data,
              multiModel: multiModel,
            },
          });
        } else {
          console.log("Error fetching templates from database ", res);
        }
      });
    } catch (err) {
      console.log("Error in fetching templates from database ", err);
      throw err;
    }
  };
}

export function addCustomProperty(
  uniqueId,
  modelId,
  templateId,
  entities,
  properties
) {
  return async (dispatch) => {
    try {
      const data = {
        uniqueId: uniqueId,
        modelId: modelId,
        templateId: templateId,
        entities: entities,
        properties: properties,
      };
      const config = {
        method: "post",
        url: urlConfig.url.API_ADD_CUSTOM_PROPERTIES,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: ADD_PROPERTIES,
            payload: {
              modelId: modelId,
              uniqueId: uniqueId,
              templateId: templateId,
              entities: entities,
              properties: properties,
            },
          });
          var x = document.getElementById("snackbar");
          x.innerText = "Custom Properties updated successfully!";
          x.className = "show";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
          }, 3000);
          let blob = new Blob([JSON.stringify(data)], {
            type: "text/plain",
          });
          // LOGGER for custom properties changes
          
          // const email = localStorage.getItem("email");
          // let date = new Date();
          // let timeStamp = date.toISOString();
          // var json = new File(
          //   [blob],
          //   modelId + "_" + email + "_" + timeStamp + ".json"
          // );
          // let params = {
          //   Bucket: "digitalflowplanning/custompropertieslog/" + email,
          //   Key: modelId + "_" + timeStamp + ".json",
          //   Body: json,
          // };
          
          // uploadToS3Bucket(params);
        } else {
          console.log("Error adding custom properties ", res);
          var x = document.getElementById("snackbar");
          x.className = x.className.replace("show", "");
        }
      });
    } catch (err) {
      console.log("Error adding custom properties to database ", err);
      var x = document.getElementById("snackbar");
      x.className = x.className.replace("show", "");
    }
  };
}

export function getCustomProperties(modelId) {
  return async (dispatch) => {
    dispatch({
      type: PROPERTIES_LOADING,
    });
    try {
      const config = {
        method: "get",
        url: urlConfig.url.API_GET_CUSTOM_PROPERTIES + modelId,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_PROPERTIES,
            payload: {
              data: res.data,
            },
          });
        } else {
          console.log("Error fetching Properties from database ", res);
        }
      });
    } catch (err) {
      console.log("Error in fetching Properties from database ", err);
      throw err;
    }
  };
}

export function updateCustomProperties(uniqueId, properties, templateId) {
  return async (dispatch) => {
    try {
      const data = {
        uniqueId: uniqueId,
        properties: properties,
      };
      const config = {
        method: "post",
        url: urlConfig.url.API_UPDATE_CUSTOM_PROPERTIES,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_PROPERTIES,
            payload: {
              uniqueId,
              properties,
              templateId,
            },
          });
        } else {
          console.log("Error updating custom properties ", res);
        }
      });
    } catch (err) {
      console.log("Error updating custom properties ", err);
    }
  };
}

export function setLoadCustomProperties(loadProps) {
  return async (dispatch) => {
    dispatch({
      type: LOAD_CUSTOM_PROPERTIES,
      payload: loadProps,
    });
  };
}

export function refreshCustomProperties() {
  return async (dispatch) => {
    dispatch({
      type: REFRESH_CUSTOM_PROPERTIES,
    });
  };
}
