import axios from "axios";
import { urlConfig } from "../constants/urlConstants";

export const ADD_PLAN = "ADD_PLAN";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const DELETE_PLAN = "DELETE_PLAN";
export const GET_PLAN = "GET_PLAN";
export const GET_PLAN_DETAILS = "GET_PLAN_DETAILS";

export function setPlan(PlanId, PlanName, PlanPricing) {
  return async (dispatch) => {
    try {
      const data = {
        PlanId: PlanId,
        PlanName: PlanName,
        PlanPricing: PlanPricing,
      };

      const config = {
        method: "post",
        url: urlConfig.url.API_ADD_PLAN,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: ADD_PLAN,
            payload: {
              PlanId,
              PlanName,
              PlanPricing,
            },
          });
        } else {
          console.log("Error occured while adding plan to database ", res);
        }
      });
    } catch (err) {
      console.log("Error in adding plan ", err);
    }
  };
}

export function updatePlan(index, PlanId, PlanName, PlanPricing) {
  return async (dispatch) => {
    try {
      const data = {
        PlanId: PlanId,
        PlanName: PlanName,
        PlanPricing: PlanPricing,
      };

      const config = {
        method: "post",
        url: urlConfig.url.API_UPDATE_PLAN,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_PLAN,
            payload: {
              planIndex: index,
              PlanId,
              PlanName,
              PlanPricing,
            },
          });
        } else {
          console.log("Error occured while updating plan to database ", res);
        }
      });
    } catch (err) {
      console.log("Error in updating plan ", err);
    }
  };
}

export function deletePlan(index, PlanId) {
  return async (dispatch) => {
    try {
      const data = {
        PlanId: PlanId,
      };
      const config = {
        method: "post",
        url: urlConfig.url.API_DELETE_PLAN,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: DELETE_PLAN,
            payload: {
              planIndex: index,
            },
          });
        } else {
          console.log("Error in deleting plan from database");
        }
      });
    } catch (err) {
      console.log("Error in deleting plan from database", err);
    }
  };
}

export function getPlan(PlanId) {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: urlConfig.url.API_GET_PLAN + PlanId,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_PLAN,
            payload: {
              data: res.data,
            },
          });
        } else {
          console.log("Error fetching plans from database ", res);
        }
      });
    } catch (err) {
      console.log("Catch Error in fetching plans from database ", err);
      throw err;
    }
  };
}

export default function getPlanDetails(email) {
  return async (dispatch) => {
    try {
      const config = {
        method: "get",
        url: urlConfig.url.API_GET_PLAN_Details + email,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_PLAN_DETAILS,
            payload: {
              data: res.data,
            },
          });
        } else {
          console.log("Error fetching plans from database ", res);
        }
      });
    } catch (err) {
      console.log("Catch Error in fetching plans from database ", err);
      throw err;
    }
  };
}
